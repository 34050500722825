@charset "UTF-8";

// Default Variables

@slick-font-path: "/fonts/";
@slick-font-family: "slick";
@slick-loader-path: "/img/";
@slick-arrow-color: white;
@slick-dot-color: black;
@slick-dot-color-active: @slick-dot-color;
@slick-prev-character: "←";
@slick-next-character: "→";
@slick-dot-character: "•";
@slick-dot-size: 6px;
@slick-opacity-default: 0.75;
@slick-opacity-on-hover: 1;
@slick-opacity-not-active: 0.25;

/* Slider */
.slick-loading .slick-list {
  background: #fff url('@{slick-loader-path}ajax-loader.gif') center center no-repeat;
}


.slick-slider{
  button.slick-arrow{
    opacity: 0.4;
    transition: all 0.2s linear;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover{
      button.slick-arrow{
        opacity: 1;
      }
    }
  }
}

// slick tools


.btn-slider-style{
  display: flex;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: rgba(@color-primary, 1);
  border-radius: 100%;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  border: none;
  outline: none;

  &::before{
    display: none;
  }

  svg{
    width: 24px;
    height: 24px;
    fill: @color-w;
  }

  @media (hover: hover) and (pointer: fine) {
    &:not(.slide-disabled):hover{
      background: rgba(@color-hover, 1);
      box-shadow: @primary-btn-hover;
    }
  }
}

button.slick-arrow {
  .btn-slider-style;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

button.slick-prev.slick-arrow {
  left: 5px;
}

button.slick-next.slick-arrow {
  right: 5px;
}

button.slick-lightbox-close {
  .btn-slider-style;
  right: 10px;
  top: 10px;

  svg{
    width: 24px;
    height: 24px;
    fill: @color-w;
  }
}

.slick-lightbox {
  .slick-lightbox-slick-item-inner {
    width: 100%;

    img {
      width: auto;
      max-width: 980px;

      @media only screen and (max-width: 1024px),
      only screen and (max-device-width: 1024px) {
        max-width: 100% !important;
      }
    }
  }
}

.slick-dots{
  display: inline-flex;
  position: absolute;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  z-index: 3;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  gap: @gap;

  > li{
    display: flex;
    width: 10px;
    height: 10px;
    border: 1px solid rgba(@color-w, 0.8);
    border-radius: 100%;
    background: rgba(@color-b5, 0.6);
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    &:before{
      content:'';
    }

    &.slick-active{

      &::before{
        content: '';
        width: 6px;
        height: 6px;
        display: flex;
        border-radius: 100%;
        background: rgba(@color-primary, 1);

      }
    }

    button{
      display: none;
    }
  }

}





