// BUTTONS global styles
// ======================================
.btn-param {
  .anim_on(@anim);
  text-decoration: none;
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  text-align: center;
  outline: none;
  white-space: nowrap;

  &:active {
    transform: translateY(1px);
  }
}

.btn-view {
  border-radius: @br;
  //clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0 20px, 20px 0);
}

.btn-big {
  min-height:  @elements-min-h;
  font: @fw-reg @big-btn-text/113% @font-primary;
  //text-transform: uppercase;
  min-width: 120px;
  letter-spacing: 0.09em;
  padding: 0 calc(@gap*4);
  gap: @gap;

  svg {
    width: 22px;
    height: 22px;
    .anim_on(@anim);

    @media only screen and (max-width: 840px), only screen and (max-device-width: 840px) {
      width: 18px;
      height: 18px;
    }
  }

  @media only screen and (max-width: 840px), only screen and (max-device-width: 840px) {
    font-size: 14px;
    padding: 2px calc(@gap*2) 0;

    &::before {
      transform: scale(.85) translateY(1px);
    }
  }
}

.btn-small {
  min-width: 60px;
  min-height: @small-elements-min-h;
  font: @fw-reg @small-btn-text/113% @font-primary;
  padding: 0 calc(@gap*3);
  gap: @gap;

  svg {
    width: 24px;
    height: 24px;
    fill: @color-primary;
    .anim_on(@anim);

    @media only screen and (max-width: 840px), only screen and (max-device-width: 840px) {
      width: 20px;
      height: 20px;
    }
  }
}

// BUTTONS unique styles
// ======================================
.btn__theme-1 {
  border: none;
  color: @color-w;
  background: @color-primary;
  .btn-param;
  .btn-view;

  svg{
    fill: @color-w;
  }

  @media (hover: hover) and (pointer: fine) {
    &:not([disabled]):hover {
      cursor: pointer !important;
      background: @color-hover;
      box-shadow: @primary-btn-hover;
      color: @color-w;
    }
  }

}

.btn__theme-2 {
  border: 1px solid @color-primary;
  color: @color-primary;
  background: @color-w;
  .btn-param;
  .btn-view;

  svg{
    fill: @color-primary;
  }

  &.red-btn{
    border: 1px solid @color-error;
    color: @color-error;
    svg{
      fill: @color-error;
    }
    @media (hover: hover) and (pointer: fine) {
      &:not([disabled]):hover {
        border-color: @color-error + 20% !important;
        color: @color-error + 20% !important;
        box-shadow: 0 3px 26px -4px rgba(@color-error + 20%, .3);

        svg {
          fill: @color-error + 20% !important;
        }
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:not([disabled]):hover {
      cursor: pointer !important;
      border-color: @color-hover !important;
      color: @color-hover !important;
      box-shadow: 0 3px 26px -4px rgba(@color-hover, .3);

      svg {
        fill: @color-hover !important;
      }
    }
  }
}

.btn__theme-3 {
  border: 0 solid @color-primary;
  color: @color-primary;
  background: @color-w;
  .btn-param;
  .btn-view;

  svg{
    fill: @color-primary;
  }

  @media (hover: hover) and (pointer: fine) {
    &:not([disabled]):hover {
      cursor: pointer !important;
      color: @color-hover !important;
      border-color: @color-hover !important;
      box-shadow: 0 3px 26px -4px rgba(@color-hover, .3);

      svg {
        fill: @color-hover !important;
      }
    }
  }
}

.btn__more {
  display: inline-flex;
  border: 1px solid @color-primary;
  color: @color-primary;
  background: @color-w;
  .btn-param;
  .btn-view;

  &:focus, &:active {
    //box-shadow: inset 0 0 0 1px @color-primary !important;
    color: @color-primary;
    background: @color-hover;

    &::after {
      color: @color-primary;
    }
  }

  &:active {
    transform: translateY(2px);
  }

  &::after {
    content: 'V';
    padding-top: 2px;
    position: relative;
    top: 2px;
    .font-icon;
    margin-left: 10px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:not([disabled]):hover {
      cursor: pointer;
      border-color: @color-hover;
      background: @color-hover;

      &::after {
        color: white;
        margin-left: 13px;
      }
    }
  }
}


// SCROLL UP BUTTON
// =======================================
#go-top {
  position: fixed;
  bottom: 64px;
  left: 10px;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 2;

  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    bottom: 85px;
  }

  > * {
    text-align: center;
    cursor: pointer;
    width: inherit;
    height: inherit;
    background: rgba(@color-primary, 0.4);
    border: 1px solid  rgba(@color-primary, 0.0);
    border-collapse: rgba(@color-primary, 0.0);
    border-radius: 100%;
    transform: scale(0.92);
    box-shadow: @shadow-light;
    text-decoration: none;
    opacity: 1;
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios


    &::after {
      content: '';
      width: 13px;
      height: 13px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(-45deg);
      position: relative;
      top: 3px;

    }

    &:active {
      transform: scale(0.92);
    }
  }

  @media (hover: hover) and (pointer: fine) {
      &:hover > *{
        background: @color-hover;
        border-color: @color-hover;
        box-shadow: @primary-btn-hover;
      }
  }
}





