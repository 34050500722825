.cookie-banner {
  position: fixed;
  bottom: calc(@gap*2);
  left: calc(@gap*2);
  width: calc(100% - 114px);
  max-width: 320px;
  transform: translate(-120%, 0);
  opacity: 0;
  background-color: rgba(@color-w, 0.9);
  backdrop-filter: blur(3px);
  color: @color-b1;
  padding: calc(@gap*3);
  text-align: center;
  border-radius: @br;
  z-index: 1000;
  box-shadow: @shadow-large;
  .anim_on(@anim);
  border: 1px solid @color-b5;


  @media only screen and (max-width: 480px),
  only screen and (max-device-width: 480px) {
    max-width: none;
    width: calc(100% - @gap*4);
    bottom: 114px;
  }

  @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
   // max-width: none;
  }

  &.show {
    transform: translate(0, 0);
    opacity: 1;
  }

  header {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    margin-bottom: calc(@gap*2);

    svg {
      width: 18px;
      fill: @color-primary;
      margin-right: @gap;
    }

    h4 {
      font-size: @headname-4;

      @media only screen and (max-width: 480px),
      only screen and (max-device-width: 480px) {
        font-size: @headname-5;

      }

    }

    .cookie-banner p {
      font-size: @paragraph-1;
      margin: 0;
      width: 100%;
      display: inline-block;
    }

    .cookie-banner a {
      color: @color-primary;
      text-decoration: underline;
    }

  }

  &__buttons {
    width: 100%;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    display: flex;
    gap: calc(@gap*2);
    flex-wrap: wrap;
  }
}

.close-cookie-popup-btn {
  display: none;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  outline: none;
  border: 0 !important;

  svg {
    width: 16px;
    height: 16px;
    fill: @color-primary !important;
  }
}

.cookie-banner__buttons {
  margin-top: 16px;

  button {
    width: 100%;
    min-width: 110px;
    font-size: 15px;
  }
}

