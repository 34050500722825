@import "__forms-reset";
@import "__forms-root";

// FORM STYLE (задали стилі для блока форми)
// =============================================================
form.form-theme {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  gap: calc(@gap*2);
  padding-bottom: calc(@gap*2);

  .input-item {
    position: relative;
    max-width: @width-field-limit;
  }
}


// Базові параметри для всіх input, select, textarea
// =============================================================
.fields-parameters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: @field-placeholder-color;
  border-radius: @field-br;
  .form-border-style;
  background: @field-bg-color;
  backdrop-filter: blur(0px);
  font: @field-font !important;
  line-height: @form-line-height !important;
}

.fields-source-parameters {

}


// Параметри для всіх input які мають поля (окрім checkbox, radiobutton, range, тощо)
.form-theme input {
  &[type='text'],
  &[type='search'],
  &[type='url'],
  &[type='email'],
  &[type='tel'],
  &[type='number'],
  &[type='password'],
    // date
  &[type='date'],
  &[type='datetime-local'],
  &[type='time'],
  &[type='week'],
  &[type='month'] {
    .fields-parameters;
    min-height: @elements-min-h;
    max-height: @elements-min-h;
    padding: 16px 20px 0 @field-left-padding;
    border-radius: @br;

    &:focus{
      .border-focus-style;
    }
    &:focus:placeholder-shown,
    &:not(:placeholder-shown) {
      font: @field-font;
      color: @field-text-color;
      min-height: @elements-min-h;
      max-height: @elements-min-h;


      //height: calc(@elements-min-h - calc(@gap*2));
    }

    &.error{
      .border-error-style;
    }
  }

  &[type='number']{
    padding-top: 0 !important;

  }
}

// Параметри для textarea select
.form-theme textarea,
.form-theme select {
  .fields-parameters;
  padding: @gap @field-left-padding;

  &:focus {
    .border-focus-style;
    padding-top: @gap*3 ;
  }

  &:focus:placeholder-shown,
  &:not(:placeholder-shown) {
    padding-top: @gap*3 ;
    color: @field-text-color;
  }

  &.error{
    .border-error-style;
  }

}

.form-theme textarea {
  min-height: @elements-min-h * 2;
}

.form-theme select {
  min-height: @elements-min-h;
}


// SELECT STANDARD <select>
// =============================================================
.form-theme .select-container {
  background: @field-bg-color;
  position: relative;
  .form-border-style;
  min-height: @elements-min-h;
  display: block;
  width: 100%;
  top: 0;
  border-radius: @field-br;
  z-index: 1;

  select {
    border: none !important;
    background: none;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding: 0 40px 2px @field-left-padding;
    color: @field-placeholder-color;
    font: @field-font;

    &::-ms-expand {
      display: none !important;
    }

    &:focus {
      color: @field-text-color !important;
    }

    &:not(:focus) {
      color: @field-placeholder-color !important;
    }
  }

  &::before {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    content: '\54'; //.dripicons-chevron-down
    width: @elements-min-h;
    height: @elements-min-h;
    position: absolute;
    z-index: -1;
    font-size: 20px;
    right: 3px;
    top: 50%;
    transform: translateY(-50%) scale(1);
    color: @field-icns-n;
    .font-icon;
  }

  &.active {
    &::before {
      color: @field-icns-a;
      transform: translateY(-50%) scale(-1);
    }
  }
}

.select-container option {
  height: @elements-min-h;
  padding-top: 10px;
  padding-bottom: 10px;
  background: @color-w;
  //text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;

      &::before {
        color: @field-icns-a;
      }
    }
  }
}


// FORM PLACEHOLDER and FOCUS animate
// =============================================================

.placeholder_style_inputs {
  color: @field-placeholder-color !important;
  opacity: 0;
  .anim_on(@anim);
  line-height: @form-line-height;
}

.placeholder_style_search {
  //line-height: normal !important;
}

.placeholder_style_textarea {
  color: @field-placeholder-color;
  opacity: 0;
  .anim_on(@anim);
  line-height: @elements-min-h - 3px !important;
  // .fs_16;
}

.placeholder_focus {
  opacity: 0;
  .anim_on(@anim);
  font: @field-font;
}

input::-webkit-input-placeholder {
  .placeholder_style_inputs;
}

textarea::-webkit-input-placeholder {
  .placeholder_style_textarea;
}

input::-moz-placeholder {
  .placeholder_style_inputs;
  line-height: @elements-min-h - 2;
}

textarea::-moz-placeholder {
  .placeholder_style_textarea;
  line-height: @elements-min-h - 2;

}

input:-moz-placeholder {
  .placeholder_style_inputs;
}

textarea:-moz-placeholder {
  .placeholder_style_textarea;
}

input:-ms-input-placeholder {
  .placeholder_style_inputs;
}

textarea:-ms-input-placeholder {
  .placeholder_style_textarea;
}

.search-wrap input::-webkit-input-placeholder {
  .placeholder_style_search;
}

.search-wrap input::-moz-placeholder {
  .placeholder_style_search;
}

.search-wrap input:-moz-placeholder {
  .placeholder_style_search;
}

.search-wrap input:-ms-input-placeholder {
  .placeholder_style_search;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  .placeholder_focus;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  .placeholder_focus;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  .placeholder_focus;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  .placeholder_focus;
}

// TYPE NUMBER
// =============================================================
.input-number-wrapper {
  position: relative;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield; /* Для Firefox */
    appearance: none;
  }

  .up, .down {
    position: absolute;
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    top: 50%;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: @color-w;
    transform: translateY(-50%);
    z-index: 3;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .up {
    right: 4px;
  }

  .down {
    right: calc(32px + 8px);
  }
}

// TYPE PASSWORD
// =============================================================
.input-password-wrapper {
  position: relative;

  .vis-pass {
    position: absolute;
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    top: 50%;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: @color-w;
    transform: translateY(-50%);
    z-index: 3;
    right: 4px;

    &:hover {
      cursor: pointer;
    }
  }
}


// ERROR FORM ELEMENT
// =============================================================
.form-theme .form_err input:not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]),
.form-theme .form_err textarea,
.form-theme .form_err select,
.form-theme .form_err .file_upload > div,
.form-theme .form_err .type-color-box,
.form-theme .form_err .inputfile_6 + label {
  border-color: @field-error-text;
  background: rgba(231, 76, 60, 0.05) !important;
}


// PLACEHOLDER MASK
//=============================================================================
.input-mask-wrap {
  position: relative;
  min-height: @elements-min-h;
  //max-height: @elements-min-h;
  display: flex;
  justify-content: flex-start; // align X
  align-items: flex-end; // align Y
  align-content: flex-end; // align Y ios

  .placeholder-mask {
    position: absolute !important;
    display: flex;
    align-content: center;
    align-items: center;
    z-index: 4;
    min-height: @elements-min-h;
    top: 0;
    left: 0;
    color: @field-placeholder-color;
    padding-left: @field-left-padding !important;
    opacity: 1;
    font: @fw-reg @paragraph-1/140% @font-primary;
    .anim_on(@anim);
    pointer-events: none;
  }

  &.search-wrap{
    // TYPE SEARCH
    // =============================================================
    @search_btn_w: auto;

    &::before {
      //content: '\e041'; //.dripicons-search
      position: absolute;
      top: 48%;
      transform: translateY(-50%);
      z-index: 2;
      left: 14px;
      content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.242457 14.7188C-0.0700434 15.0729 -0.0804594 15.4271 0.211207 15.7812C0.35704 15.9271 0.534124 16 0.742457 16C0.929957 16 1.11746 15.9271 1.30496 15.7812L5.49246 11.5938C6.61746 12.5104 7.96121 12.9792 9.52371 13C11.357 12.9583 12.8779 12.3229 14.0862 11.0938C15.3154 9.86458 15.9508 8.33333 15.9925 6.5C15.9508 4.66667 15.3258 3.13542 14.1175 1.90625C12.8883 0.677083 11.357 0.0416667 9.52371 0C7.69037 0.0416667 6.15912 0.677083 4.92996 1.90625C3.70079 3.13542 3.06537 4.66667 3.02371 6.5C3.04454 8.04167 3.51329 9.38542 4.42996 10.5312L0.242457 14.7188ZM14.4925 6.5C14.4508 7.91667 13.9612 9.09375 13.0237 10.0312C12.0862 10.9688 10.9091 11.4583 9.49246 11.5C8.07579 11.4583 6.89871 10.9688 5.96121 10.0312C5.02371 9.09375 4.53412 7.91667 4.49246 6.5C4.53412 5.08333 5.02371 3.90625 5.96121 2.96875C6.89871 2.03125 8.07579 1.54167 9.49246 1.5C10.9091 1.54167 12.0862 2.03125 13.0237 2.96875C13.9612 3.90625 14.4508 5.08333 14.4925 6.5Z" fill="rgb(156, 163, 174)"/></svg>');
      font-size: 18px;
      color: @color-primary;
      .font-icon;
    }

    > input[type="search"],
    > input[type="text"] {
      flex: 1 1 100%;
      padding-top: 16px;
      box-sizing: border-box;
      padding-left: calc(@gap*5) !important;
      position: relative;
      padding-right: @search_btn_w !important;
    }
    .placeholder-mask {
      padding-left: calc(@gap*5) !important;
    }
    > button,
    > input[type='button'],
    > input[type='submit'] {
      width: @search_btn_w !important;
      height: 46px !important;
      position: absolute;
      top: 50%;
      max-height: 46px;
      min-width: 90px !important;
      transform: translateY(-50%);
      right: 0;
      z-index: 2;
      background: @color-primary !important;
      color: @color-b1;
      padding: 0 !important;
      border: none !important;

    }
    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      margin-right: 0;
      cursor: pointer;
      background: url('@{img}icn_close.svg') 50% 50% no-repeat;
      background-size: 10px auto !important;
      opacity: 0.6;
    }
    input[type="search"]::-webkit-search-cancel-button:hover {
      opacity: 1.0;
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button {
      display: none;
    }
  }
}

input, textarea, select {
  &:focus:placeholder-shown,
  &:not(:placeholder-shown) {
    & ~ .placeholder-mask {
      top: 2px;
      min-height: 24px;
      font-size: @caption;
    }
  }
}

span.error {
  color: @field-error-text;
  font: @fw-reg @caption/110% @font-primary;
  position: absolute;
  left: 0;
  text-align: left;
  bottom: -20px;
  width: 100%;
  padding: 0 13px 5px 0;
  //border: var(--field-border);
  border-top: none;
  z-index: 7;
  background: none;
  //box-shadow: 0 5px 20px -4px rgba(@color-b1, 0.1);
}


// jQuery Validation Plugin
// List of rules
// required — поле обязательное для заполнения (true или false);
// maxlength — максимальное кол-во символов (число);
// minlength — минимальное кол-во символов (число);
// email — проверяет корректность e-mail адреса (true или false);
// url — проверяет корректность url адреса (true или false);
// remote — указывается файл для проверки поля (например: check.php);
// date — проверка корректности даты (true или false);
// dateISO — проверка корректности даты ISO (true или false);
// number — проверка на число (true или false);
// digits — только цифры (true или false);
// creditcard — корректность номера кредитной карты (true или false);
// equalTo — равное чему-то (например, другому полю equalTo:»#pswd»);
// accept — проверка на правильное расширение (accept: «xls|csv»);
// rangelength — диапазон кол-ва символов, минимальное и максимальное (rangelength: [2, 6]);
// range — число должно быть в диапазоне от и до (range: [13, 23]);
// max — максимальное значение числа (22);
// min — минимальное значение числа (11).


// RANGE STYLE (slider-line)
// =============================================================
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
}

input[type=range]:focus {
  outline: none;

  &::-webkit-slider-runnable-track {
    background: @color-b5;
  }

  &::-ms-fill-lower {
    background: @color-primary;
  }

  &::-ms-fill-upper {
    background: @color-hover;
  }
}

.track() {
  width: 100%;
  height: @track-h;
  cursor: pointer;
  animate: 0.2s;
}

.styl_track() {
  background: @color-b5;
  border-radius: @br;
  box-shadow: none;
  border: 0;
}

.thumb() {
  .anim_on(@anim);
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);

  border: none;
  height: calc(@track-h * 5);
  width: calc(@track-h * 5);
  border-radius: calc(@track-h / 2);
  background: @color-primary;
  cursor: pointer;

  &:hover, &:active {
    background: @color-hover;
  }

}

input[type=range]::-webkit-slider-runnable-track {
  .track();
  .styl_track();
}

input[type=range]::-webkit-slider-thumb {
  .thumb();
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type=range]::-moz-range-track {
  .track();
  .styl_track();
}

input[type=range]::-moz-range-thumb {
  .thumb();
}

input[type=range]::-ms-track {
  .track();
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower,
input[type=range]::-ms-fill-upper {
  .styl_track();
}

input[type=range]::-ms-thumb {
  .thumb();
}

// CHECKBOX & RADIO CUSTOM
// =============================================================
input[type="checkbox"],
input[type="radio"] {
  accent-color: @color-primary; /* Основний колір */
}
.checkbox-container,
.radio-container {
  display: flex;
  justify-content: flex-start; // align X
  align-items: flex-start; // align Y
  align-content: flex-start; // align Y ios
  padding-bottom: @gap;

  label {
    display: flex;
    min-height: @checkbox-w;
    cursor: pointer;
    padding-top: 2px;
    order: 2;
  }
}

.checkbox, .radio {
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  * {
    .no_select;
    cursor: pointer !important;
    width: calc(@checkbox-w + 2px);
    height: calc(@checkbox-w + 2px);
  }
}

.checkbox::selection, .radio::selection {
  background: transparent;
}

.checkbox input ~ div.mask, .radio input ~ div.mask {
  background: @color-w;
  border: 1px solid @color-b4 !important;
  pointer-events: none;
  content: "";
  display: inline-block;
  margin: 0;
  padding: 0;
  width: @checkbox-w;
  height: @checkbox-w;
  min-width: @checkbox-w;
  min-height: @checkbox-w;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.checkbox input:not(:checked) ~ div.mask::after, .radio input:not(:checked) ~ div.mask::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  .anim_on(@anim);
}

@media screen and (min-width: 768px) {
  .checkbox:hover input:not[disabled] ~ div.mask, .radio:hover input:not[disabled] ~ div.mask {
    box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
  }
}

.checkbox input:not[disabled]:active ~ div.mask, .radio:not[disabled] input:active ~ div.mask {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:focus ~ div.mask, .radio input:focus ~ div.mask {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:checked ~ div.mask::after, .radio input:checked ~ div.mask::after {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox input, .radio input {
  position: absolute;
  opacity: 0;
}

.checkbox input ~ div.mask {
  border-radius: 3px;
  pointer-events: none;
}

.checkbox input ~ div.mask::after {
  content: '\53'; //.dripicons-checkmark
  .font-icon;
  position: absolute;
  top: 50%;
  left: 50%;
  color: @color-primary;
  font-size: 16px;
  width: calc(@checkbox-w - 4px);
  height: calc(@checkbox-w - 4px);
}

.radio input ~ div.mask {
  border-radius: 100%;
}

.radio input ~ div.mask::after {
  content: '';
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(@checkbox-w / 2);
  height: calc(@checkbox-w / 2);
  transform: translate(-50%, -50%) scale(0);
}

.radio input:checked ~ div.mask::after {
  background: @field-icns-a;
}

.form-theme input[type="checkbox"] ~ .form_err_def {
  display: block;
  background: @color-b1;
  border: 1px solid @color-b2;
  color: @color-b5;
  padding: 10px 13px;
  border-radius: @br !important;
  position: absolute;
  height: auto !important;
  bottom: -35px !important;
  top: auto !important;
  left: -4px !important;

  &:before {
    content: "";
    display: block;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    position: absolute !important;
    top: -6px;
    left: 7px;
    background: @color-w;
    border-top: 1px solid @color-secondary;
    border-right: 1px solid @color-secondary;
    z-index: -1;
  }
}


// RADIO TABS
// =============================================================
.tab-radio{
  display: flex;
  .form-border-style;
  border-radius: @br;
  min-height: @elements-min-h;
  max-height: @elements-min-h;
  padding: 4px;
  justify-content: center; // align X
  align-items: stretch; // align Y
  align-content: stretch; // align Y ios
  gap: @gap;

  &__item{
    display: flex;
    justify-content: stretch; // align X
    align-items: stretch; // align Y
    align-content: stretch; // align Y ios
    flex: 1 1 50%;
    position: relative;

    input{
      pointer-events: none;
      position: absolute;
      visibility: hidden;
    }

    label{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      text-align: center;
      padding-top: 1px;
      border-radius: @br - 4px;
      .anim_on(@anim);
    }
    input:checked ~ label{
      background: @color-primary;
      color: @color-w;
    }
    input:not(:checked) ~ label{
      @media (hover: hover) and (pointer: fine) {
        &:hover{
          cursor: pointer;
          background: @color-b6;
        }
      }
    }

  }
}


// BUTTONS STYLE
// =============================================================
.form-theme {
  button:not(.btn_search):not(.icn_btn):not(.btn_search2):not(.slick-arrow),
  input[type="button"]:not(.btn_search):not(.btn_search2),
  input[type="submit"]:not(.btn_search):not(.btn_search2),
  input[type="reset"]:not(.btn_search):not(.btn_search2) {
    //.btn__theme-1;
    //.btn-big;
    //border: none;

    .anim_on(@anim);
  }
}


// =============================================================
[disabled] {
  opacity: 0.5;
}

.form-theme{
  .hide {
    display: none !important;
  }
}


.field-help{
  display: flex;
  position: absolute;
  right: @gap;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  @media (hover: hover) and (pointer: fine) {
      &:hover{
          svg{
            fill:@color-hover;
          }
      }
  }

  svg{
    fill: @color-b3;
    width: 24px;
    height: 24px;
    .anim_on(@anim);
  }
}


