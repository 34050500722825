@charset "UTF-8";

@import "/node_modules/aos/dist/aos.css";

:root {
  --app-height: 100%;
  --app-width: 100vw;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: 400;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

button,
input {
  *overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
q:before,
blockquote:after,
q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  padding: 0;
  margin: 0;
}

b,
strong,
b *,
strong * {
  font-weight: 700 !important;
}

i:not(.fas):not(.fa):not(.far):not(.fab):not(.fal):not(.fad):not(.font-icon),
em:not(.fas):not(.fa):not(.far):not(.fab):not(.fal):not(.fad):not(.font-icon) {
  font-style: italic !important;
}

hr {
  display: block;
  width: 100%;
  height: 10px;
  visibility: hidden;
  opacity: 0;
  clear: both;
  float: none;
  margin: 0 !important;
  padding: 0 !important;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Regular.woff") format("woff"), url("/fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Medium.woff") format("woff"), url("/fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Bold.woff") format("woff"), url("/fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Black.woff") format("woff"), url("/fonts/Roboto-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "dripicons-v2";
  src: url("/fonts/dripicons-v2.woff") format("woff"), url("/fonts/dripicons-v2.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  min-height: var(--app-height) !important;
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  max-width: 4200px;
  min-width: 320px;
  flex: 1 1 auto;
  width: 100%;
  margin: auto;
}

.main-container > .page-content {
  flex: 1 1 auto;
}

.main-container > .footer {
  order: 99;
}

.resolution-wrap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 860px;
  height: auto;
  min-height: 100%;
  padding-left: calc(8px*3);
  padding-right: calc(8px*3);
}

::selection {
  background: rgba(46, 56, 62, 0.8);
  color: #fff !important;
}

::-moz-selection {
  background: rgba(46, 56, 62, 0.8);
  color: #fff !important;
}

.gradient-bg__dark {
  background: #2E383E;
  background: -moz-linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  background: -webkit-linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  background: linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@{@color-b1}", endColorstr="@{@color-b2}", GradientType=1);
}

html,
body {
  font: 400 16px/140% 'Roboto', Helvetica, Arial, sans-serif;
  background-size: auto 100%  !important;
  line-height: 130%;
  color: #2E383E;
}

.ta_l {
  text-align: left !important;
}

.ta_r {
  text-align: right !important;
}

.ta_c {
  text-align: center !important;
}

.no_select,
.no_select * {
  cursor: default;
  user-select: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #9CA3AE;
}

::-webkit-scrollbar-thumb {
  background: #59616D;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #159DF1;
}

::-webkit-scrollbar-thumb:active {
  background: #159DF1;
}

select,
a,
button,
*:before,
*:after,
[type="button"],
[type="submit"],
[type="reset"] {
  transition: all 0.2s linear;
}

a {
  color: #159DF1;
}

/* Slider */

.slick-slider {
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.slick-lightbox .slick-loading .slick-list {
  background-color: transparent;
}

.slick-lightbox .slick-prev {
  left: 15px;
}

.slick-lightbox .slick-next {
  right: 15px;
}

.slick-lightbox-hide {
  opacity: 0;
}

.slick-lightbox-hide.slick-lightbox-ie {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.slick-lightbox-hide-init {
  position: absolute;
  top: -9999px;
  opacity: 0;
}

.slick-lightbox-hide-init.slick-lightbox-ie {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.slick-lightbox-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slick-lightbox-slick-item {
  text-align: center;
  overflow: hidden;
}

.slick-lightbox-slick-item:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
  display: block;
  text-align: center;
}

.slick-lightbox-slick-item-inner {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-img {
  margin: 0 auto;
  display: block;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-caption {
  margin: 10px 0 0;
  color: white;
}

.slick-lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
}

.slick-lightbox-close:focus {
  outline: none;
}

.slick-lightbox-close:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '×';
}

/* Slider */

.slick-loading .slick-list {
  background: #fff url('/img/ajax-loader.gif') center center no-repeat;
}

.slick-slider button.slick-arrow {
  opacity: 0.4;
  transition: all 0.2s linear;
}

.btn-slider-style {
  display: flex;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: #159df1;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: none;
  outline: none;
}

.btn-slider-style::before {
  display: none;
}

.btn-slider-style svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

button.slick-arrow {
  display: flex;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: #159df1;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

button.slick-arrow::before {
  display: none;
}

button.slick-arrow svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

button.slick-prev.slick-arrow {
  left: 5px;
}

button.slick-next.slick-arrow {
  right: 5px;
}

button.slick-lightbox-close {
  display: flex;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: #159df1;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: none;
  outline: none;
  right: 10px;
  top: 10px;
}

button.slick-lightbox-close::before {
  display: none;
}

button.slick-lightbox-close svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

button.slick-lightbox-close svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

.slick-lightbox .slick-lightbox-slick-item-inner {
  width: 100%;
}

.slick-lightbox .slick-lightbox-slick-item-inner img {
  width: auto;
  max-width: 980px;
}

.slick-dots {
  display: inline-flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 3;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  gap: 8px;
}

.slick-dots > li {
  display: flex;
  width: 10px;
  height: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  background: rgba(219, 225, 229, 0.6);
  justify-content: center;
  align-items: center;
  align-content: center;
}

.slick-dots > li:before {
  content: '';
}

.slick-dots > li.slick-active::before {
  content: '';
  width: 6px;
  height: 6px;
  display: flex;
  border-radius: 100%;
  background: #159df1;
}

.slick-dots > li button {
  display: none;
}

[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dripicons-"]:before,
[class*=" dripicons-"]:before,
.font-icon {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dripicons-alarm:before {
  content: "\61";
}

.dripicons-align-center:before {
  content: "\62";
}

.dripicons-align-justify:before {
  content: "\63";
}

.dripicons-align-left:before {
  content: "\64";
}

.dripicons-align-right:before {
  content: "\65";
}

.dripicons-anchor:before {
  content: "\66";
}

.dripicons-archive:before {
  content: "\67";
}

.dripicons-arrow-down:before {
  content: "\68";
}

.dripicons-arrow-left:before {
  content: "\69";
}

.dripicons-arrow-right:before {
  content: "\6a";
}

.dripicons-arrow-thin-down:before {
  content: "\6b";
}

.dripicons-arrow-thin-left:before {
  content: "\6c";
}

.dripicons-arrow-thin-right:before {
  content: "\6d";
}

.dripicons-arrow-thin-up:before {
  content: "\6e";
}

.dripicons-arrow-up:before {
  content: "\6f";
}

.dripicons-article:before {
  content: "\70";
}

.dripicons-backspace:before {
  content: "\71";
}

.dripicons-basket:before {
  content: "\72";
}

.dripicons-basketball:before {
  content: "\73";
}

.dripicons-battery-empty:before {
  content: "\74";
}

.dripicons-battery-full:before {
  content: "\75";
}

.dripicons-battery-low:before {
  content: "\76";
}

.dripicons-battery-medium:before {
  content: "\77";
}

.dripicons-bell:before {
  content: "\78";
}

.dripicons-blog:before {
  content: "\79";
}

.dripicons-bluetooth:before {
  content: "\7a";
}

.dripicons-bold:before {
  content: "\41";
}

.dripicons-bookmark:before {
  content: "\42";
}

.dripicons-bookmarks:before {
  content: "\43";
}

.dripicons-box:before {
  content: "\44";
}

.dripicons-briefcase:before {
  content: "\45";
}

.dripicons-brightness-low:before {
  content: "\46";
}

.dripicons-brightness-max:before {
  content: "\47";
}

.dripicons-brightness-medium:before {
  content: "\48";
}

.dripicons-broadcast:before {
  content: "\49";
}

.dripicons-browser:before {
  content: "\4a";
}

.dripicons-browser-upload:before {
  content: "\4b";
}

.dripicons-brush:before {
  content: "\4c";
}

.dripicons-calendar:before {
  content: "\4d";
}

.dripicons-camcorder:before {
  content: "\4e";
}

.dripicons-camera:before {
  content: "\4f";
}

.dripicons-card:before {
  content: "\50";
}

.dripicons-cart:before {
  content: "\51";
}

.dripicons-checklist:before {
  content: "\52";
}

.dripicons-checkmark:before {
  content: "\53";
}

.dripicons-chevron-down:before {
  content: "\54";
}

.dripicons-chevron-left:before {
  content: "\55";
}

.dripicons-chevron-right:before {
  content: "\56";
}

.dripicons-chevron-up:before {
  content: "\57";
}

.dripicons-clipboard:before {
  content: "\58";
}

.dripicons-clock:before {
  content: "\59";
}

.dripicons-clockwise:before {
  content: "\5a";
}

.dripicons-cloud:before {
  content: "\30";
}

.dripicons-cloud-download:before {
  content: "\31";
}

.dripicons-cloud-upload:before {
  content: "\32";
}

.dripicons-code:before {
  content: "\33";
}

.dripicons-contract:before {
  content: "\34";
}

.dripicons-contract-2:before {
  content: "\35";
}

.dripicons-conversation:before {
  content: "\36";
}

.dripicons-copy:before {
  content: "\37";
}

.dripicons-crop:before {
  content: "\38";
}

.dripicons-cross:before {
  content: "\39";
}

.dripicons-crosshair:before {
  content: "\21";
}

.dripicons-cutlery:before {
  content: "\22";
}

.dripicons-device-desktop:before {
  content: "\23";
}

.dripicons-device-mobile:before {
  content: "\24";
}

.dripicons-device-tablet:before {
  content: "\25";
}

.dripicons-direction:before {
  content: "\26";
}

.dripicons-disc:before {
  content: "\27";
}

.dripicons-document:before {
  content: "\28";
}

.dripicons-document-delete:before {
  content: "\29";
}

.dripicons-document-edit:before {
  content: "\2a";
}

.dripicons-document-new:before {
  content: "\2b";
}

.dripicons-document-remove:before {
  content: "\2c";
}

.dripicons-dot:before {
  content: "\2d";
}

.dripicons-dots-2:before {
  content: "\2e";
}

.dripicons-dots-3:before {
  content: "\2f";
}

.dripicons-download:before {
  content: "\3a";
}

.dripicons-duplicate:before {
  content: "\3b";
}

.dripicons-enter:before {
  content: "\3c";
}

.dripicons-exit:before {
  content: "\3d";
}

.dripicons-expand:before {
  content: "\3e";
}

.dripicons-expand-2:before {
  content: "\3f";
}

.dripicons-experiment:before {
  content: "\40";
}

.dripicons-export:before {
  content: "\5b";
}

.dripicons-feed:before {
  content: "\5d";
}

.dripicons-flag:before {
  content: "\5e";
}

.dripicons-flashlight:before {
  content: "\5f";
}

.dripicons-folder:before {
  content: "\60";
}

.dripicons-folder-open:before {
  content: "\7b";
}

.dripicons-forward:before {
  content: "\7c";
}

.dripicons-gaming:before {
  content: "\7d";
}

.dripicons-gear:before {
  content: "\7e";
}

.dripicons-graduation:before {
  content: "\5c";
}

.dripicons-graph-bar:before {
  content: "\e000";
}

.dripicons-graph-line:before {
  content: "\e001";
}

.dripicons-graph-pie:before {
  content: "\e002";
}

.dripicons-headset:before {
  content: "\e003";
}

.dripicons-heart:before {
  content: "\e004";
}

.dripicons-help:before {
  content: "\e005";
}

.dripicons-home:before {
  content: "\e006";
}

.dripicons-hourglass:before {
  content: "\e007";
}

.dripicons-inbox:before {
  content: "\e008";
}

.dripicons-information:before {
  content: "\e009";
}

.dripicons-italic:before {
  content: "\e00a";
}

.dripicons-jewel:before {
  content: "\e00b";
}

.dripicons-lifting:before {
  content: "\e00c";
}

.dripicons-lightbulb:before {
  content: "\e00d";
}

.dripicons-link:before {
  content: "\e00e";
}

.dripicons-link-broken:before {
  content: "\e00f";
}

.dripicons-list:before {
  content: "\e010";
}

.dripicons-loading:before {
  content: "\e011";
}

.dripicons-location:before {
  content: "\e012";
}

.dripicons-lock:before {
  content: "\e013";
}

.dripicons-lock-open:before {
  content: "\e014";
}

.dripicons-mail:before {
  content: "\e015";
}

.dripicons-map:before {
  content: "\e016";
}

.dripicons-media-loop:before {
  content: "\e017";
}

.dripicons-media-next:before {
  content: "\e018";
}

.dripicons-media-pause:before {
  content: "\e019";
}

.dripicons-media-play:before {
  content: "\e01a";
}

.dripicons-media-previous:before {
  content: "\e01b";
}

.dripicons-media-record:before {
  content: "\e01c";
}

.dripicons-media-shuffle:before {
  content: "\e01d";
}

.dripicons-media-stop:before {
  content: "\e01e";
}

.dripicons-medical:before {
  content: "\e01f";
}

.dripicons-menu:before {
  content: "\e020";
}

.dripicons-message:before {
  content: "\e021";
}

.dripicons-meter:before {
  content: "\e022";
}

.dripicons-microphone:before {
  content: "\e023";
}

.dripicons-minus:before {
  content: "\e024";
}

.dripicons-monitor:before {
  content: "\e025";
}

.dripicons-move:before {
  content: "\e026";
}

.dripicons-music:before {
  content: "\e027";
}

.dripicons-network-1:before {
  content: "\e028";
}

.dripicons-network-2:before {
  content: "\e029";
}

.dripicons-network-3:before {
  content: "\e02a";
}

.dripicons-network-4:before {
  content: "\e02b";
}

.dripicons-network-5:before {
  content: "\e02c";
}

.dripicons-pamphlet:before {
  content: "\e02d";
}

.dripicons-paperclip:before {
  content: "\e02e";
}

.dripicons-pencil:before {
  content: "\e02f";
}

.dripicons-phone:before {
  content: "\e030";
}

.dripicons-photo:before {
  content: "\e031";
}

.dripicons-photo-group:before {
  content: "\e032";
}

.dripicons-pill:before {
  content: "\e033";
}

.dripicons-pin:before {
  content: "\e034";
}

.dripicons-plus:before {
  content: "\e035";
}

.dripicons-power:before {
  content: "\e036";
}

.dripicons-preview:before {
  content: "\e037";
}

.dripicons-print:before {
  content: "\e038";
}

.dripicons-pulse:before {
  content: "\e039";
}

.dripicons-question:before {
  content: "\e03a";
}

.dripicons-reply:before {
  content: "\e03b";
}

.dripicons-reply-all:before {
  content: "\e03c";
}

.dripicons-return:before {
  content: "\e03d";
}

.dripicons-retweet:before {
  content: "\e03e";
}

.dripicons-rocket:before {
  content: "\e03f";
}

.dripicons-scale:before {
  content: "\e040";
}

.dripicons-search:before {
  content: "\e041";
}

.dripicons-shopping-bag:before {
  content: "\e042";
}

.dripicons-skip:before {
  content: "\e043";
}

.dripicons-stack:before {
  content: "\e044";
}

.dripicons-star:before {
  content: "\e045";
}

.dripicons-stopwatch:before {
  content: "\e046";
}

.dripicons-store:before {
  content: "\e047";
}

.dripicons-suitcase:before {
  content: "\e048";
}

.dripicons-swap:before {
  content: "\e049";
}

.dripicons-tag:before {
  content: "\e04a";
}

.dripicons-tag-delete:before {
  content: "\e04b";
}

.dripicons-tags:before {
  content: "\e04c";
}

.dripicons-thumbs-down:before {
  content: "\e04d";
}

.dripicons-thumbs-up:before {
  content: "\e04e";
}

.dripicons-ticket:before {
  content: "\e04f";
}

.dripicons-time-reverse:before {
  content: "\e050";
}

.dripicons-to-do:before {
  content: "\e051";
}

.dripicons-toggles:before {
  content: "\e052";
}

.dripicons-trash:before {
  content: "\e053";
}

.dripicons-trophy:before {
  content: "\e054";
}

.dripicons-upload:before {
  content: "\e055";
}

.dripicons-user:before {
  content: "\e056";
}

.dripicons-user-group:before {
  content: "\e057";
}

.dripicons-user-id:before {
  content: "\e058";
}

.dripicons-vibrate:before {
  content: "\e059";
}

.dripicons-view-apps:before {
  content: "\e05a";
}

.dripicons-view-list:before {
  content: "\e05b";
}

.dripicons-view-list-large:before {
  content: "\e05c";
}

.dripicons-view-thumb:before {
  content: "\e05d";
}

.dripicons-volume-full:before {
  content: "\e05e";
}

.dripicons-volume-low:before {
  content: "\e05f";
}

.dripicons-volume-medium:before {
  content: "\e060";
}

.dripicons-volume-off:before {
  content: "\e061";
}

.dripicons-wallet:before {
  content: "\e062";
}

.dripicons-warning:before {
  content: "\e063";
}

.dripicons-web:before {
  content: "\e064";
}

.dripicons-weight:before {
  content: "\e065";
}

.dripicons-wifi:before {
  content: "\e066";
}

.dripicons-wrong:before {
  content: "\e067";
}

.dripicons-zoom-in:before {
  content: "\e068";
}

.dripicons-zoom-out:before {
  content: "\e069";
}

.caps {
  text-transform: uppercase;
}

.text-box,
.text-wrap {
  width: 100%;
}

.text-box > br,
.text-wrap > br {
  position: relative;
  width: 100%;
  display: block;
}

.text-box .video-wrap,
.text-wrap .video-wrap {
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  align-content: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
  background: #F0F2F6;
}

.text-box .video-wrap iframe,
.text-wrap .video-wrap iframe {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.text-box h1,
.text-wrap h1 {
  font: 700 50px/98% 'Roboto', Helvetica, Arial, sans-serif;
  letter-spacing: 0.14px;
  position: relative;
  margin-bottom: 8px;
}

.text-box h2,
.text-wrap h2 {
  font: 600 38px/98% 'Roboto', Helvetica, Arial, sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.text-box h3,
.text-wrap h3 {
  font: 600 28px/105% 'Roboto', Helvetica, Arial, sans-serif !important;
  position: relative;
  margin-bottom: 10px;
}

.text-box h4,
.text-wrap h4 {
  font: 600 22px/116% 'Roboto', Helvetica, Arial, sans-serif !important;
  position: relative;
  margin-bottom: 10px;
}

.text-box h5,
.text-wrap h5 {
  font: 600 18px/116% 'Roboto', Helvetica, Arial, sans-serif !important;
  position: relative;
  margin-bottom: 10px;
}

.text-box .date,
.text-wrap .date {
  color: #59616D;
  margin-bottom: calc(8px * 2);
}

.text-box .date::before,
.text-wrap .date::before {
  content: '\4d';
  color: #9CA3AE;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-box img,
.text-wrap img,
.text-box p > img,
.text-wrap p > img {
  position: relative;
  max-width: 100% !important;
}

.text-box p,
.text-wrap p {
  font: 400 16px/140% 'Roboto', Helvetica, Arial, sans-serif;
  margin-bottom: calc(8px * 2);
}

.text-box .list-links,
.text-wrap .list-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: calc(8px * 4);
}

.text-box .big-img,
.text-wrap .big-img {
  display: block !important;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  position: relative;
  margin-bottom: calc(8px * 2);
}

.text-box .big-img img,
.text-wrap .big-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  font-family: 'object-fit: cover;';
}

.text-box .img-left,
.text-wrap .img-left,
.text-box .img-right,
.text-wrap .img-right {
  display: inline-flex;
  max-width: calc(33.33% + 8px * 2);
  margin-bottom: calc(8px * 2);
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  top: 8px;
}

.text-box .img-left,
.text-wrap .img-left {
  float: left;
  margin-right: 16px;
}

.text-box .img-right,
.text-wrap .img-right {
  float: right;
  margin-left: 16px;
}

.text-box [class*='prev-img'],
.text-wrap [class*='prev-img'] {
  width: 33.33%;
  display: flex;
}

.text-box [class*='prev-img'] > div,
.text-wrap [class*='prev-img'] > div {
  overflow: hidden;
  display: flex;
  flex: 1 0 120px;
  width: 100%;
  padding-bottom: 66.25%;
  position: relative;
  margin-bottom: calc(8px * 2);
  border-radius: 8px;
}

.text-box [class*='prev-img'] > div img,
.text-wrap [class*='prev-img'] > div img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
}

.text-box [class*='prev-img'].prev-img__left,
.text-wrap [class*='prev-img'].prev-img__left {
  float: left;
  margin-right: calc(8px * 2);
}

.text-box [class*='prev-img'].prev-img__right,
.text-wrap [class*='prev-img'].prev-img__right {
  float: right;
  margin-left: calc(8px * 2);
}

.text-box .quote,
.text-wrap .quote,
.text-box .quote_c,
.text-wrap .quote_c,
.text-box blockquote,
.text-wrap blockquote {
  position: relative;
  margin-bottom: calc(8px * 4);
  font-size: 16px;
  display: inline-block;
  width: 100%;
  background: #F0F2F6;
  border-radius: 8px;
  padding: calc(8px * 2) calc(8px * 2) calc(8px * 2) 76px;
  color: #2E383E;
}

.text-box .quote_c,
.text-wrap .quote_c {
  text-align: center;
  padding-left: 20px !important;
}

.text-box .quote::before,
.text-wrap .quote::before,
.text-box blockquote::before,
.text-wrap blockquote::before {
  content: "";
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  background: #DBE1E5;
  border-radius: 8px 0 0 8px;
}

.text-box .quote::after,
.text-wrap .quote::after,
.text-box blockquote::after,
.text-wrap blockquote::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.34375 24.6263H4.75C4.05729 24.6263 3.48828 24.4037 3.04297 23.9584C2.59766 23.5131 2.375 22.9441 2.375 22.2513C2.375 21.5586 2.59766 20.9896 3.04297 20.5443C3.48828 20.099 4.05729 19.8763 4.75 19.8763H5.34375C6.53125 19.8269 7.52083 19.431 8.3125 18.6888C9.05469 17.8972 9.45052 16.9076 9.5 15.7201V15.1263H4.75C3.41406 15.0769 2.30078 14.6068 1.41016 13.7162C0.519531 12.8256 0.0494792 11.7123 0 10.3763V5.62634C0.0494792 4.29041 0.519531 3.17712 1.41016 2.2865C2.30078 1.39587 3.41406 0.925822 4.75 0.876343H9.5C10.8359 0.925822 11.9492 1.39587 12.8398 2.2865C13.7305 3.17712 14.2005 4.29041 14.25 5.62634V15.7201C14.2005 18.2435 13.3346 20.3464 11.6523 22.0287C9.97005 23.711 7.86719 24.5769 5.34375 24.6263Z" fill="rgb(181, 186, 199)"/><path d="M24.5938 24.6263H24C23.3073 24.6263 22.7383 24.4037 22.293 23.9584C21.8477 23.5131 21.625 22.9441 21.625 22.2513C21.625 21.5586 21.8477 20.9896 22.293 20.5443C22.7383 20.099 23.3073 19.8763 24 19.8763H24.5938C25.7812 19.8269 26.7708 19.431 27.5625 18.6888C28.3047 17.8972 28.7005 16.9076 28.75 15.7201V15.1263H24C22.6641 15.0769 21.5508 14.6068 20.6602 13.7162C19.7695 12.8256 19.2995 11.7123 19.25 10.3763V5.62634C19.2995 4.29041 19.7695 3.17712 20.6602 2.2865C21.5508 1.39587 22.6641 0.925822 24 0.876343H28.75C30.0859 0.925822 31.1992 1.39587 32.0898 2.2865C32.9805 3.17712 33.4505 4.29041 33.5 5.62634V15.7201C33.4505 18.2435 32.5846 20.3464 30.9023 22.0287C29.2201 23.711 27.1172 24.5769 24.5938 24.6263Z" fill="rgb(181, 186, 199)"/></svg>');
  width: 34px;
  height: 25px;
  position: absolute;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: 20px;
  left: 12px;
  z-index: 2;
  display: block;
}

.text-box ul:not(.todo-list):not(.numeric-list):not(.slick-dots),
.text-wrap ul:not(.todo-list):not(.numeric-list):not(.slick-dots) {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto calc(8px * 4) auto;
}

.text-box ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li,
.text-wrap ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li {
  position: relative;
  list-style: none;
  min-height: 25px;
  padding-left: 18px;
}

.text-box ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li:not(:last-of-type),
.text-wrap ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li:not(:last-of-type) {
  margin-bottom: calc(8px * 2);
}

.text-box ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li:before,
.text-wrap ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li:before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 2px;
  top: 7px;
  z-index: 3;
  background: #159df1;
  border-radius: 100%;
}

.text-box ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li > *,
.text-wrap ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li > * {
  margin: 10px auto 5px;
}

.text-box ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li > * > li:before,
.text-wrap ul:not(.todo-list):not(.numeric-list):not(.slick-dots) > li > * > li:before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 2px;
  top: 7px;
  z-index: 3;
  background: #9CA3AE;
  border-radius: 100%;
}

.text-box ul.todo-list,
.text-wrap ul.todo-list {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto calc(8px * 4) auto;
}

.text-box ul.todo-list > li,
.text-wrap ul.todo-list > li {
  position: relative;
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
  padding-left: 18px;
}

.text-box ul.todo-list > li label,
.text-wrap ul.todo-list > li label {
  position: absolute;
  left: 0;
  top: -2px;
  margin-right: 10px;
}

.text-box ol,
.text-wrap ol {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto calc(8px * 4) auto;
}

.text-box ol > li,
.text-wrap ol > li {
  position: relative;
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
  padding-left: 30px;
}

.text-box ol > li::before,
.text-wrap ol > li::before {
  content: counter(num) ' ' !important;
  counter-increment: num !important;
  color: #fff;
  display: flex;
  background: #159DF1;
  width: 23px;
  height: 23px;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  line-height: 17px;
  left: 0;
  top: 0;
  font: 700 12px / 100% 'Roboto', Helvetica, Arial, sans-serif;
  text-align: center;
  border-radius: 8px;
}

.text-box .action-color,
.text-wrap .action-color {
  color: #159DF1;
}

.text-box .anchors a,
.text-wrap .anchors a {
  text-decoration: none;
  color: #B5BAC7;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}

.text-box .anchors a:hover,
.text-wrap .anchors a:hover {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.8);
}

.text-box .numeric-hn,
.text-wrap .numeric-hn {
  position: relative;
  display: inline-block;
  margin-bottom: calc(8px * 2);
  padding-left: 60px;
}

.text-box .numeric-hn > span,
.text-wrap .numeric-hn > span {
  display: flex;
  align-self: stretch;
  min-height: 100%;
  align-content: center;
  align-items: center;
  font: 600 32px/120% 'Roboto', Helvetica, Arial, sans-serif;
}

.text-box .numeric-hn::before,
.text-wrap .numeric-hn::before {
  content: attr(data-numeric);
  text-align: left;
  margin-right: calc(8px * 2);
  color: #29b1ff;
  display: flex;
  flex: 0 0 46px;
  width: 46px;
  min-width: 46px;
  height: 46px;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  line-height: 17px;
  left: -4px;
  top: -10px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 8px;
}

.text-box .numeric-sub,
.text-wrap .numeric-sub {
  position: relative;
  display: block;
  margin-bottom: calc(8px * 2);
  padding-left: 80px;
}

.text-box .numeric-sub::before,
.text-wrap .numeric-sub::before {
  content: attr(data-numeric);
  text-align: left;
  margin-right: calc(8px * 2);
  color: #2E383E;
  display: flex;
  flex: 0 0 46px;
  width: 62px;
  min-width: 46px;
  height: 46px;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  line-height: 17px;
  left: 5px;
  top: -10px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px;
}

.text-box .numeric-list > li,
.text-wrap .numeric-list > li {
  padding-left: 65px;
  margin-bottom: calc(8px * 4);
  position: relative;
}

.text-box .numeric-list > li > ul.numeric-list > li,
.text-wrap .numeric-list > li > ul.numeric-list > li {
  font: 400 15px/125% 'Roboto', Helvetica, Arial, sans-serif !important;
  color: #9CA3AE;
}

.text-box .numeric-list > li > ul.numeric-list > li .numeric-list__number,
.text-wrap .numeric-list > li > ul.numeric-list > li .numeric-list__number {
  font-size: 14px !important;
}

.text-box .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li,
.text-wrap .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li {
  padding-left: 90px !important;
}

.text-box .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li .numeric-list__number,
.text-wrap .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li .numeric-list__number {
  font-size: 13px !important;
}

.text-box .numeric-list__number,
.text-wrap .numeric-list__number {
  margin-right: calc(8px * 2);
  background: #2E383E;
  display: inline-flex;
  width: auto;
  height: auto;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 8px 11px 6px 13px;
  left: 0;
  top: -3px;
  color: #9CA3AE;
  font-size: 16px;
  line-height: 16px !important;
  font-weight: 600;
  border-radius: 8px;
}

.text-box .table-scroll,
.text-wrap .table-scroll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.text-box .table-theme-1,
.text-wrap .table-theme-1 {
  width: 100%;
  box-shadow: 0 5px 6px -2px rgba(0, 0, 0, 0.2);
}

.text-box .table-theme-1 thead,
.text-wrap .table-theme-1 thead {
  background: #159DF1;
}

.text-box .table-theme-1 thead th,
.text-wrap .table-theme-1 thead th {
  color: #fff;
  text-align: left;
}

.text-box .table-theme-1 thead th:last-child,
.text-wrap .table-theme-1 thead th:last-child {
  border-radius: 0 8px 0 0;
}

.text-box .table-theme-1 thead th:first-child,
.text-wrap .table-theme-1 thead th:first-child {
  border-radius: 8px 0 0 0;
}

.text-box .table-theme-1 tbody tr td,
.text-wrap .table-theme-1 tbody tr td {
  text-align: left;
  border-right: 1px solid #DBE1E5;
  border-bottom: 1px solid #DBE1E5;
}

.text-box .table-theme-1 tbody tr td:first-child,
.text-wrap .table-theme-1 tbody tr td:first-child {
  border-left: 1px solid #DBE1E5;
}

.text-box .table-theme-1 tbody tr:last-of-type td:last-of-type,
.text-wrap .table-theme-1 tbody tr:last-of-type td:last-of-type {
  border-radius: 0 0 8px 0;
}

.text-box .table-theme-1 tbody tr:last-of-type td:first-child,
.text-wrap .table-theme-1 tbody tr:last-of-type td:first-child {
  border-radius: 0 0 0 8px;
}

.text-box .table-theme-1 th,
.text-wrap .table-theme-1 th,
.text-box .table-theme-1 td,
.text-wrap .table-theme-1 td {
  padding: 8px 10px;
}

.text-box a,
.text-wrap a {
  display: inline-block;
  max-width: 100%;
  word-break: break-all;
  /* Дозволяє розривати слово (посилання) */
  overflow-wrap: break-word;
  /* Переносить слово, якщо воно не вміщується */
}

[class*='list_v'] {
  margin: 0 auto 20px;
}

[class*='list_v'] li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 25px;
}

.list_v1 li:before {
  content: '\53';
  width: 20px;
  height: 20px;
  position: absolute;
  left: -3px;
  top: 0;
  font-family: "dripicons-v2" !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 17px;
  text-rendering: auto;
  font-style: normal !important;
  color: #29b1ff;
}

.list_v2 li:before {
  content: "\39";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 4px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  text-rendering: auto;
  color: #D84C3A !important;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list_v3 {
  list-style-type: none;
  counter-reset: num;
  margin-bottom: 40px;
}

.list_v3 > li {
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
}

.list_v3 > li::before {
  content: counter(num) ' ' !important;
  counter-increment: num !important;
  color: #fff;
  background: #159DF1;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  line-height: 17px;
  left: -8px;
  top: 0;
  font-size: 13px;
  font-weight: 600;
  border-radius: 8px;
}

.list_v4 li {
  padding: 0 0 14px 0;
  border-bottom: 1px solid #DBE1E5;
}

.list_v5 > li {
  margin-bottom: 10px;
}

.list_v5 > li::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 6px;
  top: 8px;
  border-radius: 100%;
  z-index: 3;
  background: #29b1ff;
}

.list_v5 > li > ul {
  margin: 13px auto 0;
}

.list_v5 > li > ul > li {
  width: 100%;
}

.list_v5 > li > ul > li::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 6px;
  top: 8px;
  border-radius: 100%;
  z-index: 3;
  background: #DBE1E5;
}

.list_v6 > li {
  margin-bottom: 8px;
  padding-left: 13px;
}

.list_v6 > li::before {
  content: '\e01c';
  width: 10px;
  height: 10px;
  position: absolute;
  left: -3px;
  top: 3px;
  font-size: 6px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #159DF1;
}

.list_v6 > li > ul {
  margin: 13px auto 0;
}

.list_v6 > li > ul > li {
  width: 100%;
}

.list_v6 > li > ul > li::before {
  content: '\e01c';
  background-position: 50% -360px;
  width: 10px;
  height: 10px;
  position: absolute;
  left: -3px;
  top: 1px;
  font-size: 9px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #DBE1E5;
}

.accordion {
  display: flex;
  flex-direction: column;
}

.accordion .accordion__item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.accordion .accordion__item .accordion__item__title {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: left;
  position: relative;
  outline: none;
}

.accordion .accordion__item .accordion__item__title.fixed {
  position: fixed;
  z-index: 500;
  background: #fff;
  border-radius: 0;
  border-width: 1px 1px 0 1px;
  border-color: #159DF1;
  border-style: solid;
  box-shadow: 0 5px 20px -5px rgba(46, 56, 62, 0.1);
  transform: translateX(-1px);
}

.accordion .accordion__item .accordion__item__title:hover {
  cursor: pointer;
}

.accordion .accordion__item .accordion__item__title::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 12C18.4583 12.4583 18.2083 12.7083 17.75 12.75H12.75V17.75C12.7083 18.2083 12.4583 18.4583 12 18.5C11.5417 18.4583 11.2917 18.2083 11.25 17.75V12.75H6.25C5.79167 12.7083 5.54167 12.4583 5.5 12C5.54167 11.5417 5.79167 11.2917 6.25 11.25H11.25V6.25C11.2917 5.79167 11.5417 5.54167 12 5.5C12.4583 5.54167 12.7083 5.79167 12.75 6.25V11.25H17.75C18.2083 11.2917 18.4583 11.5417 18.5 12Z" fill="rgba(46, 56, 62, 1)"/></svg>');
  position: absolute;
  right: 10px;
  top: 10px;
  color: #159DF1;
}

.accordion .accordion__item.active > .accordion__item__title::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 12.8711C18.4583 13.3294 18.2083 13.5794 17.75 13.6211H6.25C5.79167 13.5794 5.54167 13.3294 5.5 12.8711C5.54167 12.4128 5.79167 12.1628 6.25 12.1211H17.75C18.2083 12.1628 18.4583 12.4128 18.5 12.8711Z" fill="rgb(46, 56, 62)"/></svg>');
}

.accordion__theme-one {
  margin-bottom: calc(8px*2);
}

.accordion__theme-one .accordion__item {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid #DBE1E5;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s linear;
}

.accordion__theme-one .accordion__item.active {
  border-color: #159DF1;
}

.accordion__theme-one .accordion__item .accordion__item__title {
  width: 100%;
  min-height: 48px;
  padding: calc(8px*2) 40px calc(8px*2) calc(8px*3);
}

.accordion__theme-one .accordion__item .accordion__item__title > * {
  font: 600 18px/120% 'Roboto', Helvetica, Arial, sans-serif !important;
  margin-bottom: 0 !important;
}

.accordion__theme-one .accordion__item .accordion__item__title::after {
  right: calc(8px*2);
  top: calc(8px*2);
  opacity: 0.4;
}

.accordion__theme-one .accordion__item .accordion__item__content {
  display: none;
  padding: 0 calc(8px*3) calc(8px*3) calc(8px*3);
}

.accordion__theme-one .accordion__item .accordion__item__content > * {
  font: 400 16px/130% 'Roboto', Helvetica, Arial, sans-serif !important;
}

.accordion__theme-one .accordion__item .accordion__item__content > *:not(:last-child) {
  margin-bottom: 8px;
}

.accordion__theme-one .accordion__item .accordion__item__content > *:only-child {
  margin-bottom: 0;
  /* Скидаємо відступ для єдиного елемента */
}

.accordion__theme-one .accordion__item.active > .accordion__item__title::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 12.8711C18.4583 13.3294 18.2083 13.5794 17.75 13.6211H6.25C5.79167 13.5794 5.54167 13.3294 5.5 12.8711C5.54167 12.4128 5.79167 12.1628 6.25 12.1211H17.75C18.2083 12.1628 18.4583 12.4128 18.5 12.8711Z" fill="rgba(46, 56, 62, 1)"/></svg>');
}

.dropdown-simple__head {
  padding-right: 32px;
  position: relative;
  cursor: pointer;
}

.dropdown-simple__head::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.7148 9.94922L12.4922 14.9531C12.3099 15.099 12.1458 15.1719 12 15.1719C11.8359 15.1719 11.681 15.1081 11.5352 14.9805L6.28516 9.94922C6.15755 9.82161 6.09375 9.66667 6.09375 9.48438C6.09375 9.30208 6.15755 9.14714 6.28516 9.01953C6.57682 8.76432 6.88672 8.76432 7.21484 9.01953L12 13.6133L16.7852 9.01953C17.1133 8.76432 17.4232 8.77344 17.7148 9.04688C17.8424 9.15625 17.9062 9.30208 17.9062 9.48438C17.9062 9.66667 17.8424 9.82161 17.7148 9.94922Z" fill="rgb(156, 163, 174)"/></svg>');
}

.dropdown-simple__body {
  max-height: 1px;
  transform: scale(1, 0);
  transform-origin: 0 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute !important;
  transition: all 0.05s linear;
}

.dropdown-simple.active .dropdown-simple__head::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.7148 14.0508L12.4922 9.04688C12.3099 8.90104 12.1458 8.82812 12 8.82812C11.8359 8.82812 11.681 8.89193 11.5352 9.01953L6.28516 14.0508C6.15755 14.1784 6.09375 14.3333 6.09375 14.5156C6.09375 14.6979 6.15755 14.8529 6.28516 14.9805C6.57682 15.2357 6.88672 15.2357 7.21484 14.9805L12 10.3867L16.7852 14.9805C17.1133 15.2357 17.4232 15.2266 17.7148 14.9531C17.8424 14.8438 17.9062 14.6979 17.9062 14.5156C17.9062 14.3333 17.8424 14.1784 17.7148 14.0508Z" fill="rgb(21, 157, 241)"/></svg>');
}

.dropdown-simple.active .dropdown-simple__body {
  max-height: 400px;
  transform: scale(1, 1);
  position: relative !important;
  opacity: 1;
  pointer-events: all;
  transition: all 0.2s linear;
}

.slider-comments-type {
  position: relative;
  padding-bottom: calc(8px*4);
}

.slider-comments-type .slides-wrapper {
  position: relative;
  min-height: 260px;
}

.slider-comments-type__item {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 50%;
  transition: all 0.5s ease-in-out;
}

.slider-comments-type__item.active-slide {
  z-index: 8;
  opacity: 1;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.slider-comments-type__item.prev-slide {
  z-index: 7;
  opacity: 0.6;
  left: 0;
  transform: translate(-10%, -50%) scale(0.9);
}

.slider-comments-type__item.next-slide {
  right: 0;
  z-index: 7;
  opacity: 0.6;
  transform: translate(10%, -50%) scale(0.9);
}

.slider-comments-type__item:not(.prev-slide):not(.next-slide):not(.active-slide) {
  opacity: 0;
  z-index: 6;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
}

.slider-comments-type .slider-pages {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  min-height: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 8px;
}

.slider-comments-type .slider-pages > *:nth-child(2) {
  opacity: 0.5;
}

.slider-comments-type .slide-prev-btn,
.slider-comments-type .slide-next-btn {
  position: absolute;
  display: flex;
  top: auto;
  bottom: 0;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: #b5bac7 !important;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  backdrop-filter: blur(4px);
  border: none;
  outline: none;
}

.slider-comments-type .slide-prev-btn svg,
.slider-comments-type .slide-next-btn svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

.slider-comments-type .slide-prev-btn {
  left: calc(50% - 90px);
}

.slider-comments-type .slide-prev-btn::before {
  content: '';
  left: -1px;
}

.slider-comments-type .slide-next-btn {
  right: calc(50% - 90px);
}

.slider-comments-type .slide-next-btn::before {
  content: '';
  top: 1px;
  right: -1px;
}

@keyframes active-to-next {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    opacity: 0.9;
    transform: translate(20%, -50%) scale(1.1);
  }

  100% {
    opacity: 0.8;
    transform: translate(0, -50%) scale(0.9);
  }
}

@keyframes active-to-prev {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    opacity: 0.9;
    transform: translate(-120%, -50%) scale(1.1);
  }

  100% {
    opacity: 0.8;
    transform: translate(-100%, -50%) scale(0.9);
  }
}

@keyframes prev-to-active {
  0% {
    opacity: 0.8;
    transform: translate(-100%, -50%) scale(0.9);
  }

  50% {
    opacity: 0.9;
    transform: translate(-120%, -50%) scale(1.1);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes next-to-active {
  0% {
    opacity: 0.8;
    transform: translate(0, -50%) scale(0.9);
  }

  50% {
    opacity: 0.9;
    transform: translate(20%, -50%) scale(1.1);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.btn-param {
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  outline: none;
  white-space: nowrap;
}

.btn-param:active {
  transform: translateY(1px);
}

.btn-view {
  border-radius: 8px;
}

.btn-big {
  min-height: 50px;
  font: 400 16px/113% 'Roboto', Helvetica, Arial, sans-serif;
  min-width: 120px;
  letter-spacing: 0.09em;
  padding: 0 calc(8px*4);
  gap: 8px;
}

.btn-big svg {
  width: 22px;
  height: 22px;
  transition: all 0.2s linear;
}

.btn-small {
  min-width: 60px;
  min-height: 38px;
  font: 400 14px/113% 'Roboto', Helvetica, Arial, sans-serif;
  padding: 0 calc(8px*3);
  gap: 8px;
}

.btn-small svg {
  width: 24px;
  height: 24px;
  fill: #159DF1;
  transition: all 0.2s linear;
}

.btn__theme-1 {
  border: none;
  color: #fff;
  background: #159DF1;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  outline: none;
  white-space: nowrap;
  border-radius: 8px;
}

.btn__theme-1:active {
  transform: translateY(1px);
}

.btn__theme-1 svg {
  fill: #fff;
}

.btn__theme-2 {
  border: 1px solid #159DF1;
  color: #159DF1;
  background: #fff;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  outline: none;
  white-space: nowrap;
  border-radius: 8px;
}

.btn__theme-2:active {
  transform: translateY(1px);
}

.btn__theme-2 svg {
  fill: #159DF1;
}

.btn__theme-2.red-btn {
  border: 1px solid #D84C3A;
  color: #D84C3A;
}

.btn__theme-2.red-btn svg {
  fill: #D84C3A;
}

.btn__theme-3 {
  border: 0 solid #159DF1;
  color: #159DF1;
  background: #fff;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  outline: none;
  white-space: nowrap;
  border-radius: 8px;
}

.btn__theme-3:active {
  transform: translateY(1px);
}

.btn__theme-3 svg {
  fill: #159DF1;
}

.btn__more {
  display: inline-flex;
  border: 1px solid #159DF1;
  color: #159DF1;
  background: #fff;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  outline: none;
  white-space: nowrap;
  border-radius: 8px;
}

.btn__more:active {
  transform: translateY(1px);
}

.btn__more:focus,
.btn__more:active {
  color: #159DF1;
  background: #29b1ff;
}

.btn__more:focus::after,
.btn__more:active::after {
  color: #159DF1;
}

.btn__more:active {
  transform: translateY(2px);
}

.btn__more::after {
  content: 'V';
  padding-top: 2px;
  position: relative;
  top: 2px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 10px;
}

#go-top {
  position: fixed;
  bottom: 64px;
  left: 10px;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 2;
}

#go-top > * {
  text-align: center;
  cursor: pointer;
  width: inherit;
  height: inherit;
  background: rgba(21, 157, 241, 0.4);
  border: 1px solid rgba(21, 157, 241, 0);
  border-collapse: rgba(21, 157, 241, 0);
  border-radius: 100%;
  transform: scale(0.92);
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#go-top > *::after {
  content: '';
  width: 13px;
  height: 13px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(-45deg);
  position: relative;
  top: 3px;
}

#go-top > *:active {
  transform: scale(0.92);
}

input:not[type=checkbox] {
  -webkitw: none;
  -webkit-box-appearance: none;
  box-shado-shadow: none;
  border-radius: 0 !important;
  -webkit-appearance: none;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

*:focus {
  outline: none;
}

textarea {
  resize: none;
  color: #2E383E;
}

button::-moz-focus-inner {
  border: 0;
}

a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button:active,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}

a.active.focus button:active,
a.active:focus button:active,
a.focus button:active,
a:active.focus button:active,
a:active:focus button:active,
a:focus button:active,
button:active button:active,
button.active.focus button:active,
button.active:focus button:active,
button.focus button:active,
button:active.focus button:active,
button:active:focus button:active,
button:focus button:active,
.btn.active.focus button:active,
.btn.active:focus button:active,
.btn.focus button:active,
.btn:active.focus button:active,
.btn:active:focus button:active,
.btn:focus button:active,
a.active.focus button:focus,
a.active:focus button:focus,
a.focus button:focus,
a:active.focus button:focus,
a:active:focus button:focus,
a:focus button:focus,
button:active button:focus,
button.active.focus button:focus,
button.active:focus button:focus,
button.focus button:focus,
button:active.focus button:focus,
button:active:focus button:focus,
button:focus button:focus,
.btn.active.focus button:focus,
.btn.active:focus button:focus,
.btn.focus button:focus,
.btn:active.focus button:focus,
.btn:active:focus button:focus,
.btn:focus button:focus {
  outline: none !important;
}

*:focus,
*:active,
*::-moz-focus-inner {
  outline: none !important;
  border: 0;
  outline: 0 !important;
  outline-offset: 0 !important;
}

.form-border-style {
  border-width: 1px;
  border-color: #b5bac7;
  border-style: solid;
}

.border-focus-style {
  border-width: 1px;
  border-color: #159df1;
  border-style: solid;
}

.border-error-style {
  border-width: 1px;
  border-color: #d84c3a;
  border-style: solid;
}

form.form-theme {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  gap: calc(8px*2);
  padding-bottom: calc(8px*2);
}

form.form-theme .input-item {
  position: relative;
  max-width: 448px;
}

.fields-parameters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: #9CA3AE;
  border-radius: 8px;
  border-width: 1px;
  border-color: #b5bac7;
  border-style: solid;
  background: #ffffff;
  backdrop-filter: blur(0px);
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
}

.form-theme input[type='text'],
.form-theme input[type='search'],
.form-theme input[type='url'],
.form-theme input[type='email'],
.form-theme input[type='tel'],
.form-theme input[type='number'],
.form-theme input[type='password'],
.form-theme input[type='date'],
.form-theme input[type='datetime-local'],
.form-theme input[type='time'],
.form-theme input[type='week'],
.form-theme input[type='month'] {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: #9CA3AE;
  border-width: 1px;
  border-color: #b5bac7;
  border-style: solid;
  background: #ffffff;
  backdrop-filter: blur(0px);
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
  min-height: 50px;
  max-height: 50px;
  padding: 16px 20px 0 calc(8px*2);
  border-radius: 8px;
}

.form-theme input[type='text']:focus,
.form-theme input[type='search']:focus,
.form-theme input[type='url']:focus,
.form-theme input[type='email']:focus,
.form-theme input[type='tel']:focus,
.form-theme input[type='number']:focus,
.form-theme input[type='password']:focus,
.form-theme input[type='date']:focus,
.form-theme input[type='datetime-local']:focus,
.form-theme input[type='time']:focus,
.form-theme input[type='week']:focus,
.form-theme input[type='month']:focus {
  border-width: 1px;
  border-color: #159df1;
  border-style: solid;
}

.form-theme input[type='text']:focus:placeholder-shown,
.form-theme input[type='search']:focus:placeholder-shown,
.form-theme input[type='url']:focus:placeholder-shown,
.form-theme input[type='email']:focus:placeholder-shown,
.form-theme input[type='tel']:focus:placeholder-shown,
.form-theme input[type='number']:focus:placeholder-shown,
.form-theme input[type='password']:focus:placeholder-shown,
.form-theme input[type='date']:focus:placeholder-shown,
.form-theme input[type='datetime-local']:focus:placeholder-shown,
.form-theme input[type='time']:focus:placeholder-shown,
.form-theme input[type='week']:focus:placeholder-shown,
.form-theme input[type='month']:focus:placeholder-shown,
.form-theme input[type='text']:not(:placeholder-shown),
.form-theme input[type='search']:not(:placeholder-shown),
.form-theme input[type='url']:not(:placeholder-shown),
.form-theme input[type='email']:not(:placeholder-shown),
.form-theme input[type='tel']:not(:placeholder-shown),
.form-theme input[type='number']:not(:placeholder-shown),
.form-theme input[type='password']:not(:placeholder-shown),
.form-theme input[type='date']:not(:placeholder-shown),
.form-theme input[type='datetime-local']:not(:placeholder-shown),
.form-theme input[type='time']:not(:placeholder-shown),
.form-theme input[type='week']:not(:placeholder-shown),
.form-theme input[type='month']:not(:placeholder-shown) {
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif;
  color: #2E383E;
  min-height: 50px;
  max-height: 50px;
}

.form-theme input[type='text'].error,
.form-theme input[type='search'].error,
.form-theme input[type='url'].error,
.form-theme input[type='email'].error,
.form-theme input[type='tel'].error,
.form-theme input[type='number'].error,
.form-theme input[type='password'].error,
.form-theme input[type='date'].error,
.form-theme input[type='datetime-local'].error,
.form-theme input[type='time'].error,
.form-theme input[type='week'].error,
.form-theme input[type='month'].error {
  border-width: 1px;
  border-color: #d84c3a;
  border-style: solid;
}

.form-theme input[type='number'] {
  padding-top: 0 !important;
}

.form-theme textarea,
.form-theme select {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: #9CA3AE;
  border-radius: 8px;
  border-width: 1px;
  border-color: #b5bac7;
  border-style: solid;
  background: #ffffff;
  backdrop-filter: blur(0px);
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
  padding: 8px calc(8px*2);
}

.form-theme textarea:focus,
.form-theme select:focus {
  border-width: 1px;
  border-color: #159df1;
  border-style: solid;
  padding-top: 24px;
}

.form-theme textarea:focus:placeholder-shown,
.form-theme select:focus:placeholder-shown,
.form-theme textarea:not(:placeholder-shown),
.form-theme select:not(:placeholder-shown) {
  padding-top: 24px;
  color: #2E383E;
}

.form-theme textarea.error,
.form-theme select.error {
  border-width: 1px;
  border-color: #d84c3a;
  border-style: solid;
}

.form-theme textarea {
  min-height: 100px;
}

.form-theme select {
  min-height: 50px;
}

.form-theme .select-container {
  background: #ffffff;
  position: relative;
  border-width: 1px;
  border-color: #b5bac7;
  border-style: solid;
  min-height: 50px;
  display: block;
  width: 100%;
  top: 0;
  border-radius: 8px;
  z-index: 1;
}

.form-theme .select-container select {
  border: none !important;
  background: none;
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0 40px 2px calc(8px*2);
  color: #9CA3AE;
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif;
}

.form-theme .select-container select::-ms-expand {
  display: none !important;
}

.form-theme .select-container select:focus {
  color: #2E383E !important;
}

.form-theme .select-container select:not(:focus) {
  color: #9CA3AE !important;
}

.form-theme .select-container::before {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  content: '\54';
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: -1;
  font-size: 20px;
  right: 3px;
  top: 50%;
  transform: translateY(-50%) scale(1);
  color: #9CA3AE;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-theme .select-container.active::before {
  color: #29b1ff;
  transform: translateY(-50%) scale(-1);
}

.select-container option {
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
}

.placeholder_style_inputs {
  color: #9CA3AE !important;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: normal;
}

.placeholder_style_textarea {
  color: #9CA3AE;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: 47px !important;
}

.placeholder_focus {
  opacity: 0;
  transition: all 0.2s linear;
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif;
}

input::-webkit-input-placeholder {
  color: #9CA3AE !important;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: normal;
}

textarea::-webkit-input-placeholder {
  color: #9CA3AE;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: 47px !important;
}

input::-moz-placeholder {
  color: #9CA3AE !important;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: normal;
  line-height: 48px;
}

textarea::-moz-placeholder {
  color: #9CA3AE;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: 47px !important;
  line-height: 48px;
}

input:-moz-placeholder {
  color: #9CA3AE !important;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: normal;
}

textarea:-moz-placeholder {
  color: #9CA3AE;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: 47px !important;
}

input:-ms-input-placeholder {
  color: #9CA3AE !important;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: normal;
}

textarea:-ms-input-placeholder {
  color: #9CA3AE;
  opacity: 0;
  transition: all 0.2s linear;
  line-height: 47px !important;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: all 0.2s linear;
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: all 0.2s linear;
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  opacity: 0;
  transition: all 0.2s linear;
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: all 0.2s linear;
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif;
}

.input-number-wrapper {
  position: relative;
}

.input-number-wrapper input[type='number']::-webkit-outer-spin-button,
.input-number-wrapper input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  /* Для Firefox */
  appearance: none;
}

.input-number-wrapper .up,
.input-number-wrapper .down {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: 50%;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #fff;
  transform: translateY(-50%);
  z-index: 3;
}

.input-number-wrapper .up {
  right: 4px;
}

.input-number-wrapper .down {
  right: calc(32px + 8px);
}

.input-password-wrapper {
  position: relative;
}

.input-password-wrapper .vis-pass {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: 50%;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #fff;
  transform: translateY(-50%);
  z-index: 3;
  right: 4px;
}

.input-password-wrapper .vis-pass:hover {
  cursor: pointer;
}

.form-theme .form_err input:not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]),
.form-theme .form_err textarea,
.form-theme .form_err select,
.form-theme .form_err .file_upload > div,
.form-theme .form_err .type-color-box,
.form-theme .form_err .inputfile_6 + label {
  border-color: #D84C3A;
  background: rgba(231, 76, 60, 0.05) !important;
}

.input-mask-wrap {
  position: relative;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}

.input-mask-wrap .placeholder-mask {
  position: absolute !important;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 4;
  min-height: 50px;
  top: 0;
  left: 0;
  color: #9CA3AE;
  padding-left: calc(8px*2) !important;
  opacity: 1;
  font: 400 16px/140% 'Roboto', Helvetica, Arial, sans-serif;
  transition: all 0.2s linear;
  pointer-events: none;
}

.input-mask-wrap.search-wrap::before {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  z-index: 2;
  left: 14px;
  content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.242457 14.7188C-0.0700434 15.0729 -0.0804594 15.4271 0.211207 15.7812C0.35704 15.9271 0.534124 16 0.742457 16C0.929957 16 1.11746 15.9271 1.30496 15.7812L5.49246 11.5938C6.61746 12.5104 7.96121 12.9792 9.52371 13C11.357 12.9583 12.8779 12.3229 14.0862 11.0938C15.3154 9.86458 15.9508 8.33333 15.9925 6.5C15.9508 4.66667 15.3258 3.13542 14.1175 1.90625C12.8883 0.677083 11.357 0.0416667 9.52371 0C7.69037 0.0416667 6.15912 0.677083 4.92996 1.90625C3.70079 3.13542 3.06537 4.66667 3.02371 6.5C3.04454 8.04167 3.51329 9.38542 4.42996 10.5312L0.242457 14.7188ZM14.4925 6.5C14.4508 7.91667 13.9612 9.09375 13.0237 10.0312C12.0862 10.9688 10.9091 11.4583 9.49246 11.5C8.07579 11.4583 6.89871 10.9688 5.96121 10.0312C5.02371 9.09375 4.53412 7.91667 4.49246 6.5C4.53412 5.08333 5.02371 3.90625 5.96121 2.96875C6.89871 2.03125 8.07579 1.54167 9.49246 1.5C10.9091 1.54167 12.0862 2.03125 13.0237 2.96875C13.9612 3.90625 14.4508 5.08333 14.4925 6.5Z" fill="rgb(156, 163, 174)"/></svg>');
  font-size: 18px;
  color: #159DF1;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-mask-wrap.search-wrap > input[type="search"],
.input-mask-wrap.search-wrap > input[type="text"] {
  flex: 1 1 100%;
  padding-top: 16px;
  box-sizing: border-box;
  padding-left: calc(8px*5) !important;
  position: relative;
  padding-right: auto !important;
}

.input-mask-wrap.search-wrap .placeholder-mask {
  padding-left: calc(8px*5) !important;
}

.input-mask-wrap.search-wrap > button,
.input-mask-wrap.search-wrap > input[type='button'],
.input-mask-wrap.search-wrap > input[type='submit'] {
  width: auto !important;
  height: 46px !important;
  position: absolute;
  top: 50%;
  max-height: 46px;
  min-width: 90px !important;
  transform: translateY(-50%);
  right: 0;
  z-index: 2;
  background: #159DF1 !important;
  color: #2E383E;
  padding: 0 !important;
  border: none !important;
}

.input-mask-wrap.search-wrap input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 0;
  cursor: pointer;
  background: url('/img/icn_close.svg') 50% 50% no-repeat;
  background-size: 10px auto !important;
  opacity: 0.6;
}

.input-mask-wrap.search-wrap input[type="search"]::-webkit-search-cancel-button:hover {
  opacity: 1;
}

.input-mask-wrap.search-wrap input[type="search"]::-webkit-search-decoration,
.input-mask-wrap.search-wrap input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input:focus:placeholder-shown ~ .placeholder-mask,
textarea:focus:placeholder-shown ~ .placeholder-mask,
select:focus:placeholder-shown ~ .placeholder-mask,
input:not(:placeholder-shown) ~ .placeholder-mask,
textarea:not(:placeholder-shown) ~ .placeholder-mask,
select:not(:placeholder-shown) ~ .placeholder-mask {
  top: 2px;
  min-height: 24px;
  font-size: 12px;
}

span.error {
  color: #D84C3A;
  font: 400 12px/110% 'Roboto', Helvetica, Arial, sans-serif;
  position: absolute;
  left: 0;
  text-align: left;
  bottom: -20px;
  width: 100%;
  padding: 0 13px 5px 0;
  border-top: none;
  z-index: 7;
  background: none;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #DBE1E5;
}

input[type=range]:focus::-ms-fill-lower {
  background: #159DF1;
}

input[type=range]:focus::-ms-fill-upper {
  background: #29b1ff;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #DBE1E5;
  border-radius: 8px;
  box-shadow: none;
  border: 0;
}

input[type=range]::-webkit-slider-thumb {
  transition: all 0.2s linear;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: calc(4px * 5);
  width: calc(4px * 5);
  border-radius: calc(4px / 2);
  background: #159DF1;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type=range]::-webkit-slider-thumb:hover,
input[type=range]::-webkit-slider-thumb:active {
  background: #29b1ff;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #DBE1E5;
  border-radius: 8px;
  box-shadow: none;
  border: 0;
}

input[type=range]::-moz-range-thumb {
  transition: all 0.2s linear;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: calc(4px * 5);
  width: calc(4px * 5);
  border-radius: calc(4px / 2);
  background: #159DF1;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb:hover,
input[type=range]::-moz-range-thumb:active {
  background: #29b1ff;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower,
input[type=range]::-ms-fill-upper {
  background: #DBE1E5;
  border-radius: 8px;
  box-shadow: none;
  border: 0;
}

input[type=range]::-ms-thumb {
  transition: all 0.2s linear;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: calc(4px * 5);
  width: calc(4px * 5);
  border-radius: calc(4px / 2);
  background: #159DF1;
  cursor: pointer;
}

input[type=range]::-ms-thumb:hover,
input[type=range]::-ms-thumb:active {
  background: #29b1ff;
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: #159DF1;
  /* Основний колір */
}

.checkbox-container,
.radio-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding-bottom: 8px;
}

.checkbox-container label,
.radio-container label {
  display: flex;
  min-height: 18px;
  cursor: pointer;
  padding-top: 2px;
  order: 2;
}

.checkbox,
.radio {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.checkbox *,
.radio * {
  cursor: default;
  user-select: none !important;
  cursor: pointer !important;
  width: calc(18px + 2px);
  height: calc(18px + 2px);
}

.checkbox::selection,
.radio::selection {
  background: transparent;
}

.checkbox input ~ div.mask,
.radio input ~ div.mask {
  background: #fff;
  border: 1px solid #B5BAC7 !important;
  pointer-events: none;
  content: "";
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.checkbox input:not(:checked) ~ div.mask::after,
.radio input:not(:checked) ~ div.mask::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.2s linear;
}

.checkbox input:not[disabled]:active ~ div.mask,
.radio:not[disabled] input:active ~ div.mask {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:focus ~ div.mask,
.radio input:focus ~ div.mask {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:checked ~ div.mask::after,
.radio input:checked ~ div.mask::after {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox input,
.radio input {
  position: absolute;
  opacity: 0;
}

.checkbox input ~ div.mask {
  border-radius: 3px;
  pointer-events: none;
}

.checkbox input ~ div.mask::after {
  content: '\53';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #159DF1;
  font-size: 16px;
  width: calc(18px - 4px);
  height: calc(18px - 4px);
}

.radio input ~ div.mask {
  border-radius: 100%;
}

.radio input ~ div.mask::after {
  content: '';
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(18px / 2);
  height: calc(18px / 2);
  transform: translate(-50%, -50%) scale(0);
}

.radio input:checked ~ div.mask::after {
  background: #29b1ff;
}

.form-theme input[type="checkbox"] ~ .form_err_def {
  display: block;
  background: #2E383E;
  border: 1px solid #59616D;
  color: #DBE1E5;
  padding: 10px 13px;
  border-radius: 8px !important;
  position: absolute;
  height: auto !important;
  bottom: -35px !important;
  top: auto !important;
  left: -4px !important;
}

.form-theme input[type="checkbox"] ~ .form_err_def:before {
  content: "";
  display: block;
  transform: rotate(-45deg);
  width: 10px;
  height: 10px;
  position: absolute !important;
  top: -6px;
  left: 7px;
  background: #fff;
  border-top: 1px solid #9EC850;
  border-right: 1px solid #9EC850;
  z-index: -1;
}

.tab-radio {
  display: flex;
  border-width: 1px;
  border-color: #b5bac7;
  border-style: solid;
  border-radius: 8px;
  min-height: 50px;
  max-height: 50px;
  padding: 4px;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  gap: 8px;
}

.tab-radio__item {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  flex: 1 1 50%;
  position: relative;
}

.tab-radio__item input {
  pointer-events: none;
  position: absolute;
  visibility: hidden;
}

.tab-radio__item label {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-top: 1px;
  border-radius: 4px;
  transition: all 0.2s linear;
}

.tab-radio__item input:checked ~ label {
  background: #159DF1;
  color: #fff;
}

.form-theme button:not(.btn_search):not(.icn_btn):not(.btn_search2):not(.slick-arrow),
.form-theme input[type="button"]:not(.btn_search):not(.btn_search2),
.form-theme input[type="submit"]:not(.btn_search):not(.btn_search2),
.form-theme input[type="reset"]:not(.btn_search):not(.btn_search2) {
  transition: all 0.2s linear;
}

[disabled] {
  opacity: 0.5;
}

.form-theme .hide {
  display: none !important;
}

.field-help {
  display: flex;
  position: absolute;
  right: 8px;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  align-content: center;
}

.field-help svg {
  fill: #9CA3AE;
  width: 24px;
  height: 24px;
  transition: all 0.2s linear;
}

body::after {
  content: '';
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(46, 56, 62, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.2s linear;
  opacity: 0;
  visibility: hidden;
}

body.popup__opened::after {
  opacity: 1;
  visibility: visible;
}

body.popup__opened .main-container {
  filter: blur(1px);
}

.popup {
  position: fixed;
  transition: all 0.2s linear;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(46, 56, 62, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(6px);
}

.popup__container {
  width: 100%;
  min-height: 100%;
  padding: 30px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__container__window {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: calc(8px * 3);
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
  transition: all 0.2s linear;
  transform: perspective(600) translateY(40%) rotateX(10deg);
}

.popup__container__window .popup__close {
  text-decoration: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 0.2s linear;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 15;
  background: #159DF1;
  border-radius: 8px;
  box-shadow: 0 3px 20px -1px rgba(0, 0, 0, 0.05);
}

.popup__container__window .popup__close:active {
  transform: translateY(1px);
}

.popup__container__window .popup__close::before,
.popup__container__window .popup__close::after {
  content: '';
  display: flex;
  top: 50%;
  left: 50%;
  background: #fff;
  width: 3px;
  height: 50%;
  position: absolute;
  transform-origin: 50% 50%;
}

.popup__container__window .popup__close::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.popup__container__window .popup__close::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.popup.popup__open {
  opacity: 1;
  visibility: visible;
}

.popup.popup__open .popup__container__window {
  transform: perspective(600) translateY(0) rotateX(0);
}

.popup-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  gap: calc(8px * 2);
}

.popup-content__title {
  font: 600 28px/120% 'Roboto', Helvetica, Arial, sans-serif;
}

.popup-content__item {
  display: block;
  width: 100%;
  margin-bottom: calc(8px * 2);
}

.popup-content__item p {
  font-size: 16px;
}

.popup-content__image {
  overflow: hidden;
  margin-bottom: calc(8px * 2);
}

.popup-content__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.popup-content__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  width: 100%;
  gap: calc(8px * 2);
}

.in-video-wrap {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  padding-bottom: 56%;
  background-color: #fff;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  margin-bottom: calc(8px*4);
}

.in-video-wrap .video__media {
  position: absolute;
  top: -1%;
  left: 0;
  object-fit: cover;
  width: 102%;
  height: 102%;
  border: none;
}

.in-video-wrap .video__button {
  position: absolute;
  top: 50%;
  left: 50%;
  border: none;
  transform: translate(-50%, -50%);
  padding: 0;
  background: transparent;
  align-items: center;
  align-content: center;
  justify-content: center;
  outline: none;
  opacity: 0.5;
}

.in-video-wrap .video__button svg {
  width: 100px;
  height: 100px;
}

.in-video-wrap .video__button .video__button-icon {
  fill: #fff;
}

.in-video-wrap:hover .video__button,
.in-video-wrap .video__button:focus .video__button-shape {
  opacity: 1;
}

.in-video-wrap.video--enabled .video__button {
  display: flex;
}

.in-video-wrap.video--enabled {
  cursor: pointer;
}

.cookie-banner {
  position: fixed;
  bottom: calc(8px*2);
  left: calc(8px*2);
  width: calc(100% - 114px);
  max-width: 320px;
  transform: translate(-120%, 0);
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(3px);
  color: #2E383E;
  padding: calc(8px*3);
  text-align: center;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
  transition: all 0.2s linear;
  border: 1px solid #DBE1E5;
}

.cookie-banner.show {
  transform: translate(0, 0);
  opacity: 1;
}

.cookie-banner header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: calc(8px*2);
}

.cookie-banner header svg {
  width: 18px;
  fill: #159DF1;
  margin-right: 8px;
}

.cookie-banner header h4 {
  font-size: 22px;
}

.cookie-banner header .cookie-banner p {
  font-size: 16px;
  margin: 0;
  width: 100%;
  display: inline-block;
}

.cookie-banner header .cookie-banner a {
  color: #159DF1;
  text-decoration: underline;
}

.cookie-banner__buttons {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  gap: calc(8px*2);
  flex-wrap: wrap;
}

.close-cookie-popup-btn {
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  outline: none;
  border: 0 !important;
}

.close-cookie-popup-btn svg {
  width: 16px;
  height: 16px;
  fill: #159DF1 !important;
}

.cookie-banner__buttons {
  margin-top: 16px;
}

.cookie-banner__buttons button {
  width: 100%;
  min-width: 110px;
  font-size: 15px;
}

.desktop-menu-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 10;
  width: calc(100% + 2px);
  max-width: 4200px;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(calc(-50% - 1px));
  background: none;
}

.desktop-menu-component .menu-burger {
  display: none;
}

.desktop-menu-component .resolution-wrap {
  display: flex;
  max-width: none;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  position: relative;
  transition: all 0.2s linear;
}

.desktop-menu-component__main {
  height: 80px;
  max-height: 80px;
}

.desktop-menu-component__main .logo {
  transition: all 0.2s linear;
  position: relative;
  margin-right: calc(8px * 2);
}

.desktop-menu-component__main .logo a {
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s linear;
}

.desktop-menu-component__main .logo a svg,
.desktop-menu-component__main .logo a img {
  width: 131px;
  height: calc(131px / 2.729);
  transition: all 0.2s linear;
}

.desktop-menu-component__main-tel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-right: calc(8px * 2);
  position: relative;
}

.desktop-menu-component__main-tel a {
  text-decoration: none;
  color: #2E383E;
}

.desktop-menu-component__main-tel .more-icon {
  margin-left: 4px;
  position: relative;
  z-index: 2;
}

.desktop-menu-component__main-tel .more-icon svg {
  width: 16px;
  height: 16px;
  fill: #9CA3AE;
  transition: all 0.2s linear;
}

.desktop-menu-component__main-soc-networks {
  display: flex;
  gap: 4px;
}

.desktop-menu-component__main-soc-networks a {
  text-decoration: none;
  display: flex;
}

.desktop-menu-component__main-soc-networks a svg {
  width: 24px;
  height: 24px;
  fill: #9CA3AE;
  transition: all 0.2s linear;
}

.desktop-menu-component__main__nav {
  display: flex;
  margin-left: auto;
  gap: calc(8px * 3);
}

.desktop-menu-component__main__nav .menu-list {
  display: flex;
  gap: calc(8px * 3);
}

.desktop-menu-component__main__nav .menu-list__item {
  position: relative;
  color: #2E383E;
  text-decoration: none;
  font: 400 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
  text-transform: unset;
  display: flex;
  padding: calc(8px * 2) 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 80px !important;
}

.desktop-menu-component__main__nav .menu-list__item::after {
  content: '';
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  transition: all 0.2s linear;
  top: 0;
  width: 1%;
  height: 3px;
  background: #159DF1;
  opacity: 0;
}

.desktop-menu-component__main__nav .menu-list__item.active::after {
  content: '';
  width: 100%;
  opacity: 1;
}

.desktop-menu-component__main__nav .other-tools {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  align-content: center;
  order: 99;
}

.desktop-menu-component__main__nav .other-tools svg {
  transition: all 0.2s linear;
}

.desktop-menu-component__main__nav .other-tools .language {
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 24px;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.desktop-menu-component__main__nav .other-tools .language__button {
  display: inline-flex;
}

.desktop-menu-component__main__nav .other-tools .language__button svg {
  transition: all 0.2s linear;
  width: 24px;
  height: 24px;
  fill: #59616D;
}

.desktop-menu-component__main__nav .other-tools .language .lang-hint {
  display: none;
}

.desktop-menu-component__main__nav .other-tools .user-block {
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 24px;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.desktop-menu-component__main__nav .other-tools .user-block__button {
  display: inline-flex;
}

.desktop-menu-component__main__nav .other-tools .user-block__button svg {
  transition: all 0.2s linear;
  width: 24px;
  height: 24px;
  fill: #59616D;
}

.desktop-menu-component.header__small {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  border-bottom: 1px solid #DBE1E5;
}

.desktop-menu-component.header__small .logo a svg,
.desktop-menu-component.header__small .logo a img {
  width: 110px;
  height: calc(110px / 2.729);
  transition: all 0.2s linear;
}

.desktop-menu-component.header__small .header__main {
  height: 60px;
}

.desktop-menu-component.header__small .header__main__nav .menu-list {
  display: flex;
}

.desktop-menu-component.header__small .header__main__nav .menu-list__item {
  font: 400 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
  min-height: 60px !important;
}

.desktop-menu-component .dropdown-menu__box {
  position: absolute;
  display: flex !important;
  top: 32px;
  right: -8px;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  opacity: 0;
  pointer-events: none;
  transform: scaleY(0.8);
  transition: all 0.1s ease-in-out;
}

.desktop-menu-component .dropdown-menu__box::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 1px;
  transform: rotate(-45deg);
  border-top: 1px solid #F0F2F6;
  border-right: 1px solid #F0F2F6;
  pointer-events: none;
  background: #fff;
  position: absolute;
  top: -4px;
  right: 16px;
}

.desktop-menu-component .dropdown-menu__box__wrap {
  display: flex;
  border: 1px solid #F0F2F6;
  background: #fff;
  flex-direction: column;
  border-radius: 8px;
  padding: calc(8px / 2);
}

.desktop-menu-component .dropdown-menu__box__wrap > * {
  padding: 8px 16px;
  color: #2E383E;
  text-decoration: none;
  white-space: nowrap;
}

.desktop-menu-component .dropdown-menu__box__wrap > *.active,
.desktop-menu-component .dropdown-menu__box__wrap > *.profile-name {
  font-weight: 700 !important;
}

.desktop-menu-component .dropdown-menu__box__wrap > *:not(:last-child) {
  border-bottom: 1px solid #F0F2F6;
}

.desktop-menu-component .dropdown-menu.open .dropdown-menu__btn svg {
  fill: #159DF1;
}

.desktop-menu-component .dropdown-menu.open .dropdown-menu__box {
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
}

.mobile-menu-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 10;
  width: calc(100% + 2px);
  max-width: 4200px;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(calc(-50% - 1px));
  background: none;
}

.mobile-menu-component .menu-burger {
  display: flex;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 3;
  transform: translateY(calc(-50% - 2px));
}

.mobile-menu-component .menu-burger::before,
.mobile-menu-component .menu-burger i,
.mobile-menu-component .menu-burger::after {
  content: '';
  display: block;
  width: 60%;
  height: 2px;
  background-color: #59616D;
  position: absolute;
  transform-origin: 0 50%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s linear;
}

.mobile-menu-component .menu-burger::before {
  top: 30%;
}

.mobile-menu-component .menu-burger i {
  top: 50%;
}

.mobile-menu-component .menu-burger::after {
  top: 70%;
}

.mobile-menu-component .resolution-wrap {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  position: relative;
  max-width: none;
  transition: all 0.2s linear;
  padding-left: calc(8px*2);
  padding-right: calc(8px*2);
}

.mobile-menu-component__main {
  height: 60px;
  max-height: 60px;
}

.mobile-menu-component__main .logo {
  transition: all 0.2s linear;
  position: relative;
  margin-right: calc(8px * 2);
}

.mobile-menu-component__main .logo a {
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s linear;
}

.mobile-menu-component__main .logo a svg,
.mobile-menu-component__main .logo a img {
  width: 120px;
  height: calc(120px / 3.12);
  transition: all 0.2s linear;
}

.mobile-menu-component__main-tel {
  display: none;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-right: calc(8px * 2);
}

.mobile-menu-component__main-tel a {
  text-decoration: none;
  color: #2E383E;
}

.mobile-menu-component__main-tel .more-icon {
  margin-left: 4px;
}

.mobile-menu-component__main-tel .more-icon svg {
  width: 16px;
  height: 16px;
  fill: #9CA3AE;
}

.mobile-menu-component__main-soc-networks {
  display: flex;
  gap: 4px;
}

.mobile-menu-component__main-soc-networks a {
  text-decoration: none;
  display: flex;
}

.mobile-menu-component__main-soc-networks a svg {
  width: 24px;
  height: 24px;
  fill: #9CA3AE;
  transition: all 0.2s linear;
}

.mobile-menu-component__main__nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  min-width: 300px;
  margin-left: auto;
  position: absolute;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  background: #ffffff;
  backdrop-filter: blur(0);
  top: 0;
  right: 0;
  height: var(--app-height);
  transition: all 0.2s linear;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
  padding-top: 60px;
  overflow: hidden;
  overflow-y: auto;
}

.mobile-menu-component__main__nav .menu-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.mobile-menu-component__main__nav .menu-list__item,
.mobile-menu-component__main__nav .menu-list a {
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #2E383E;
  text-decoration: none;
  font: 400 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
  display: flex;
  padding: calc(8px * 2);
  text-align: center;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.03);
}

.mobile-menu-component__main__nav .menu-list__item::after,
.mobile-menu-component__main__nav .menu-list a::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transition: all 0.2s linear;
  width: 6px;
  height: 100%;
  background: #159DF1;
  border-radius: 0 4px 4px 0;
  opacity: 0;
}

.mobile-menu-component__main__nav .menu-list__item.active::after,
.mobile-menu-component__main__nav .menu-list a.active::after {
  content: '';
  scale: 1;
  opacity: 1;
}

.mobile-menu-component__main__nav .other-tools {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 calc(8px * 2);
  margin-bottom: calc(8px * 3);
  flex-wrap: wrap;
  margin-top: auto;
  order: 99;
}

.mobile-menu-component__main__nav .other-tools svg {
  display: none;
  transition: all 0.2s linear;
}

.mobile-menu-component__main__nav .other-tools .language {
  order: 99;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.mobile-menu-component__main__nav .other-tools .language__box {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.mobile-menu-component__main__nav .other-tools .language__box__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 0 24px;
}

.mobile-menu-component__main__nav .other-tools .language__box__wrap > * {
  flex: 0 0 auto;
  display: flex;
  padding: 8px 0;
  text-decoration: none;
  color: #2E383E;
}

.mobile-menu-component__main__nav .other-tools .language__box__wrap > *.lang-hint {
  color: #9CA3AE;
}

.mobile-menu-component__main__nav .other-tools .language__box__wrap > *.active {
  font-weight: 700;
}

.mobile-menu-component__main__nav .other-tools .user-block {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #F0F2F6;
  border-radius: 8px;
}

.mobile-menu-component__main__nav .other-tools .user-block__box {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.mobile-menu-component__main__nav .other-tools .user-block__box__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 0 24px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.mobile-menu-component__main__nav .other-tools .user-block__box__wrap > *:not(a) {
  display: flex;
  padding: 8px 0;
  color: #2E383E;
}

.mobile-menu-component__main__nav .other-tools .user-block__box__wrap > *:not(a).profile-name {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-width: 100%;
  margin-left: auto;
  flex: 1 1 auto;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.mobile-menu-component.menu-active .menu-burger::before {
  top: 50%;
  left: 50%;
  transform: rotate(45deg) translate(-50%, calc(-50% + 1px));
}

.mobile-menu-component.menu-active .menu-burger i {
  left: 70%;
  opacity: 0;
}

.mobile-menu-component.menu-active .menu-burger::after {
  top: 50%;
  left: 50%;
  transform: rotate(-45deg) translate(-50%, calc(50% - 1px));
}

.mobile-menu-component.menu-active .menu-burger::before,
.mobile-menu-component.menu-active .menu-burger i,
.mobile-menu-component.menu-active .menu-burger::after {
  width: 70% !important;
}

.mobile-menu-component.menu-active .header__main__nav {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.mobile-menu-component.header__small {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  border-bottom: 1px solid #DBE1E5;
}

.mobile-menu-component.header__small .logo a svg,
.mobile-menu-component.header__small .logo a img {
  width: 110px;
  height: calc(110px / 2.729);
  transition: all 0.2s linear;
}

.mobile-menu-component.header__small .header__main {
  height: 60px;
}

.header .logo {
  position: relative;
}

.header .logo .signature-under-logo {
  display: flex;
  position: absolute;
  pointer-events: none;
  font: 700 10px/100% 'Roboto', Helvetica, Arial, sans-serif;
  color: #59616D;
  z-index: 0;
  bottom: 7px;
  left: 40%;
  text-decoration: none;
}

.header .logo svg > * {
  fill: #159DF1;
}

.menu-list.second-list {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  background: rgba(240, 242, 246, 0.8);
  border-radius: 8px;
}

.menu-list.second-list a {
  font-size: 14px !important;
  color: rgba(46, 56, 62, 0.8) !important;
}

.menu-list.second-list a:last-child {
  border-bottom: none !important;
}

.header__main.with-search .header-search,
header.header.with-search .header-search {
  display: block !important;
}

.footer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer__content {
  display: flex;
  flex-direction: column;
  background: #2E383E;
  color: #fff;
  padding-top: calc(8px*4);
  padding-bottom: calc(8px*4);
  position: relative;
  overflow: hidden;
}

.footer__content .resolution-wrap {
  position: relative;
}

.footer__content .resolution-wrap .logo-bg-decor {
  position: absolute;
  pointer-events: none;
  width: 520px;
  height: 520px;
  top: 0;
  right: 0;
  transform: translate(20%, -10%);
  opacity: 0.07;
  z-index: 1;
}

.footer__content .resolution-wrap .logo-bg-decor svg {
  width: inherit;
  height: inherit;
}

.footer__content__nav {
  position: relative;
  z-index: 2;
}

.footer__content__more {
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: calc(8px*2);
}

.footer__content__more > * {
  display: flex;
  align-items: center;
  align-content: center;
  gap: calc(8px*2);
  flex: 1 1 auto;
}

.footer__content__more .logo-soc {
  justify-content: flex-start;
}

.footer__content__more .logo-soc .logo {
  display: inline-flex;
}

.footer__content__more .logo-soc .logo svg {
  width: 127px;
  height: calc(127px / 2.729);
  fill: #159DF1;
}

.footer__content__more .logo-soc .soc-networks {
  display: flex;
  gap: 4px;
  padding-top: 2px;
}

.footer__content__more .logo-soc .soc-networks a {
  text-decoration: none;
  display: flex;
}

.footer__content__more .logo-soc .soc-networks a svg {
  width: 24px;
  height: 24px;
  fill: #9CA3AE;
}

.footer__content__more .download-app-btns {
  justify-content: flex-end;
}

.footer__content__more .download-app-btns p {
  padding-top: 2px;
  font: 600 14px/105% 'Roboto', Helvetica, Arial, sans-serif;
  color: #B5BAC7;
  text-align: right;
}

.footer__content__more .download-app-btns__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: calc(8px*2);
}

.footer__content__more .download-app-btns__wrap a {
  display: inline-flex;
}

.footer__content__more .download-app-btns__wrap a img {
  width: 120px;
  height: 40px;
  transition: all 0.2s linear;
}

.footer__copyright {
  background: #101a20;
  display: flex;
  min-height: 48px;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 5;
}

.footer__copyright .resolution-wrap {
  display: flex;
  flex-direction: row;
  gap: calc(8px*2);
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.footer__copyright__col-copy-text {
  color: #59616D;
  font-size: 12px;
}

.footer__copyright__col-logos {
  display: flex;
  gap: 8px;
}

.footer__copyright__col-logos a {
  display: inline-flex;
  opacity: 0.5;
}

.footer__copyright__col-logos a img {
  height: 30px;
}

.footer__copyright__col-menu {
  margin-left: auto;
  display: flex;
  gap: calc(8px*2);
}

.footer__copyright__col-menu a {
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  opacity: 0.7;
}

.footer-nav {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding-bottom: calc(8px*2);
  margin-bottom: calc(8px*2);
  border-bottom: 1px solid #59616D;
  gap: calc(8px*4) calc(8px*2);
  flex-wrap: wrap;
}

.footer-nav__column {
  width: calc(25% - 8px*2);
}

.footer-nav__column__head-name {
  border-bottom: 1px solid #59616D;
  padding-bottom: calc(8px*2);
  margin-bottom: 8px;
  position: relative;
}

.footer-nav__column__head-name h5 {
  font: 700 18px/100% 'Roboto', Helvetica, Arial, sans-serif;
  color: #9CA3AE;
}

.footer-nav__column__head-name::after {
  content: '';
  display: block;
  width: 40px;
  height: 3px;
  background: #59616D;
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer-nav__column__links-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
}

.footer-nav__column__links-list a {
  display: flex;
  width: 100%;
  min-height: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-decoration: none;
  color: #fff;
  opacity: 0.7;
  font-size: 14px;
}

.section-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding: calc(8px*3) calc(8px*2) !important;
}

.section-404 > * {
  position: relative;
  z-index: 3;
}

.section-404__number {
  font-size: 120px;
  color: #159DF1;
  font-weight: 900 !important;
  line-height: 150%;
  letter-spacing: 3px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.section-404__number img {
  width: 100%;
  max-width: 560px;
}

.section-404__details {
  text-align: center;
  max-width: 680px;
  margin-bottom: calc(8px*4);
  margin-left: auto;
  margin-right: auto;
}

.section-404__details h1 {
  font: 600 28px/120% 'Roboto', Helvetica, Arial, sans-serif;
  letter-spacing: 0.05em;
}

.section-404__details p {
  font: 400 16px/120% 'Roboto', Helvetica, Arial, sans-serif;
}

body .preloader {
  display: block !important;
  z-index: 998 !important;
  position: fixed !important;
  pointer-events: none;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  visibility: visible !important;
  opacity: 1 !important;
}

body .preloader > div {
  opacity: 1;
  position: fixed;
  pointer-events: none;
  display: block;
  width: 40px;
  height: 40px;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
  animation: loader 4s infinite linear;
}

body .preloader > div svg {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}

@keyframes loader {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.5);
  }
}

.mw-text {
  max-width: 680px;
}

.mw-form {
  max-width: 420px;
}

.align-r {
  justify-self: flex-end;
}

.align-l {
  justify-self: flex-start;
}

.align-c {
  justify-self: center;
}

.align-stretch {
  justify-self: stretch;
}

.block-style-css {
  background: #fff;
  border: 1px solid #DBE1E5;
  border-radius: 8px;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
}

.crumbs {
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 8px;
  scrollbar-width: none;
  /* Firefox */
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.crumbs::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Edge */
}

.crumbs > a:not(:first-child) {
  border-bottom: 1px dashed #9CA3AE;
}

.crumbs > * {
  display: inline-flex;
  flex-wrap: nowrap;
  line-height: 100%;
  position: relative;
  color: #59616D;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
}

.crumbs > *:not(:first-child) {
  top: 1px;
}

.crumbs > *:first-child {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.crumbs > *:first-child svg {
  width: 16px;
  height: 16px;
  fill: #9CA3AE;
}

.crumbs > *:not(:last-child):not(:only-child)::after {
  content: '';
  width: 1px;
  height: 16px;
  transform-origin: 50% 50%;
  background: #9CA3AE;
  transform: rotate(20deg) translateY(-52%);
  position: absolute;
  right: -9.6px;
  top: 50%;
  display: block;
}

.pre-title {
  font-size: 12px;
  text-transform: uppercase;
}

.pre-title > * {
  letter-spacing: 0.08rem;
}

.page-header {
  background: #F0F2F6;
  overflow: hidden;
  padding-top: calc(8px * 2);
  padding-bottom: calc(8px * 2);
}

.page-header .resolution-wrap {
  position: relative;
}

.page-header .resolution-wrap > *:not(.page-header__logo-decor) {
  position: relative;
  z-index: 2;
}

.page-header__title {
  margin-bottom: 8px;
}

.page-header__title > * {
  font: 700 50px/100% 'Roboto', Helvetica, Arial, sans-serif;
}

.page-header__short-description {
  display: flex;
  max-width: 680px;
}

.page-header__short-description > * {
  font: 400 14px/130% 'Roboto', Helvetica, Arial, sans-serif;
}

.page-header__logo-decor {
  display: inline-flex;
  position: absolute;
  width: 268px;
  height: 268px;
  left: 0;
  top: 50%;
  z-index: 1;
  transform: translate(-5%, -50%);
}

.page-header__logo-decor svg {
  width: inherit;
  height: inherit;
}

.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: calc(8px * 2);
  max-width: 680px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.section-title > * {
  font: 700 38px/105% 'Roboto', Helvetica, Arial, sans-serif;
}

.section-sub-title {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: calc(8px * 2);
  max-width: 500px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.section-sub-title > * {
  font: 400 16px/125% 'Roboto', Helvetica, Arial, sans-serif;
}

.nowrap {
  white-space: nowrap;
}

.content-with-sidebar {
  padding-top: 32px;
  padding-bottom: 64px;
  position: relative;
  border-bottom: 1px solid #F0F2F6;
}

.content-with-sidebar .resolution-wrap {
  flex: 1 1 767.9px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 64px;
}

.content-with-sidebar .resolution-wrap > *:not(.content-with-sidebar__sidebar) {
  flex: 0 0 calc(100% - 240px - 8px * 3);
  min-width: calc(100% - 240px - 8px * 3);
  margin-right: auto;
}

.content-with-sidebar .resolution-wrap > *:not(.content-with-sidebar__sidebar) .section-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding-bottom: calc(8px);
  max-width: 680px;
  text-align: left;
}

.content-with-sidebar .resolution-wrap > *:not(.content-with-sidebar__sidebar) .section-title > * {
  font: 700 28px/105% 'Roboto', Helvetica, Arial, sans-serif;
}

.content-with-sidebar .resolution-wrap .content-with-sidebar__sidebar {
  max-width: 240px;
  min-width: 240px;
  margin-left: auto;
  flex: 0 0 240px;
  /* Фіксована ширина сайдбара */
  position: sticky;
  align-items: start;
  top: 80px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 24px;
  align-self: flex-start;
}

.content-with-sidebar .resolution-wrap .content-with-sidebar__sidebar__box {
  padding-bottom: 24px;
}

.content-with-sidebar .resolution-wrap .content-with-sidebar__sidebar__box .sidebar-title {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #DBE1E5;
}

.content-with-sidebar .resolution-wrap .content-with-sidebar__sidebar__box .sidebar-title > * {
  font-size: 22px;
  font-weight: 600;
}

.contacts-container {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  gap: 16px;
}

.contacts-container__contact-info {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 16px;
}

.contacts-container__contact-info > * > * {
  position: relative;
  display: flex;
  padding-top: 14px;
  gap: 8px;
}

.contacts-container__contact-info > * > * .label {
  position: absolute;
  top: -6px;
  color: #9CA3AE;
  font-size: 12px;
}

.contacts-container__contact-info > * > * .info {
  display: inline-flex;
  min-height: 24px;
  padding-top: 4px;
  font-size: 14px;
}

.contacts-container__contact-info > * > * .info a.phone-number {
  display: inline-flex;
  gap: 8px;
  text-decoration: none;
  color: #2E383E;
}

.contacts-container__contact-info > * > * .info a.phone-number svg {
  position: relative;
  top: -2px;
  width: 24px;
  height: 24px;
  fill: #9CA3AE;
  transition: all 0.2s linear;
}

.contacts-container__contact-info .info.close {
  font-weight: 600;
  color: #d84c3a;
}

.contacts-container__contact-info .info.open {
  font-weight: 600;
  color: rgba(158, 200, 80, 0.8);
}

.contacts-container__contact-info__work-time .wort-time-list {
  display: table;
  padding-top: 0;
}

.contacts-container__contact-info__work-time .wort-time-list > * {
  display: table-row;
  text-align: left;
  font-size: 12px;
}

.contacts-container__contact-info__work-time .wort-time-list > *.active span {
  font-weight: 700 !important;
}

.contacts-container__contact-info__work-time .wort-time-list > * .day {
  display: table-cell;
  padding-right: 16px;
}

.contacts-container__contact-info__work-time .wort-time-list > * .open,
.contacts-container__contact-info__work-time .wort-time-list > * .close {
  display: table-cell;
  white-space: nowrap;
}

.contacts-container__contact-info__work-time .wort-time-list > * .close {
  color: #2e383e;
}

.contacts-container__contact-info__services-provided .services-list {
  display: flex;
  gap: calc(8px / 2);
  flex-wrap: wrap;
  align-items: start;
}

.contacts-container__contact-info__services-provided .services-list > * {
  width: auto;
  flex: 0 1 auto;
  display: inline-flex;
  padding: 8px;
  align-items: start;
  border-radius: 8px;
  border: 1px solid #DBE1E5;
  font-size: 12px;
  line-height: 105%;
}

.contacts-container__contact-info .ca_dmv_services_provided {
  font-size: 12px;
}

.copy-to-buffer {
  display: inline-flex;
  border: none;
  background: none;
}

.copy-to-buffer svg {
  width: 24px;
  height: 24px;
  fill: #9CA3AE;
  transition: all 0.2s linear;
}

.copy-to-buffer:active svg {
  transform: translateY(1px);
}

.small-alerts {
  display: flex;
  border: 1px solid #9EC850;
  background: rgba(158, 200, 80, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  top: 10px;
  right: 10px;
  color: #2E383E;
  padding: 16px 24px;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  transition: all 0.2s linear;
  transform: translateX(50%);
  z-index: 90;
}

.small-alerts.active {
  opacity: 1;
  transform: translateX(0);
}

.reviews {
  display: flex;
  gap: 8px;
  margin-bottom: calc(8px * 4);
  flex-wrap: wrap;
}

.reviews__item {
  padding: calc(8px * 2);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  gap: 8px;
  background: #fff;
  border: 1px solid #DBE1E5;
  border-radius: 8px;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
}

.reviews__item__head {
  display: flex;
  gap: 8px;
}

.reviews__item__head .author {
  padding-top: 2px;
  font: 600 16px/120% 'Roboto', Helvetica, Arial, sans-serif;
  white-space: nowrap;
  /* Забороняє перенос тексту */
  overflow: hidden;
  /* Ховає текст, що виходить за межі контейнера */
  text-overflow: ellipsis;
  /* Додає три крапки (...) в кінці тексту */
  /* Ширина контейнера (за потреби змініть) */
  max-width: 120px;
  display: inline-block;
  /* Або inline-block для рядкових елементів */
}

.reviews__item__head .vis-rating {
  margin-left: auto;
}

.reviews__item__head time {
  width: auto;
  font-size: 12px;
  color: #59616D;
  display: inline-flex;
  padding-top: 3px;
  white-space: nowrap;
  gap: calc(8px / 2);
  top: 2px;
}

.reviews__item__head time::before {
  position: relative;
  top: 1px;
  content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5625 3.5H9.9375V2.5625C9.96875 2.21875 10.1562 2.03125 10.5 2C10.8438 2.03125 11.0312 2.21875 11.0625 2.5625V3.5H12C12.4219 3.51562 12.7734 3.66406 13.0547 3.94531C13.3359 4.22656 13.4844 4.57812 13.5 5V12.5C13.4844 12.9219 13.3359 13.2734 13.0547 13.5547C12.7734 13.8359 12.4219 13.9844 12 14H4.5C4.07812 13.9844 3.72656 13.8359 3.44531 13.5547C3.16406 13.2734 3.01562 12.9219 3 12.5V5C3.01562 4.57812 3.16406 4.22656 3.44531 3.94531C3.72656 3.66406 4.07812 3.51562 4.5 3.5H5.4375V2.5625C5.46875 2.21875 5.65625 2.03125 6 2C6.34375 2.03125 6.53125 2.21875 6.5625 2.5625V3.5ZM4.125 12.5C4.14062 12.7344 4.26562 12.8594 4.5 12.875H12C12.2344 12.8594 12.3594 12.7344 12.375 12.5V6.5H4.125V12.5Z" fill="rgb(156, 163, 174)"/></svg>');
}

.reviews__item__body > * {
  font-size: 16px;
}

.news-preview {
  display: flex;
  flex-direction: column;
  row-gap: calc(8px * 2);
}

.news-preview__item {
  display: flex;
  flex-direction: column;
  margin-bottom: calc(8px * 4);
}

.news-preview__item a {
  text-decoration: none;
  color: #2E383E;
}

.news-preview__item h2 a {
  font-size: 18px;
  font-weight: 600;
}

.news-preview__item time {
  width: auto;
  font-size: 12px;
  color: #59616D;
  display: inline-flex;
  padding-top: 3px;
  white-space: nowrap;
  gap: calc(8px / 2);
  top: 2px;
}

.news-preview__item time::before {
  position: relative;
  top: 1px;
  content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5625 3.5H9.9375V2.5625C9.96875 2.21875 10.1562 2.03125 10.5 2C10.8438 2.03125 11.0312 2.21875 11.0625 2.5625V3.5H12C12.4219 3.51562 12.7734 3.66406 13.0547 3.94531C13.3359 4.22656 13.4844 4.57812 13.5 5V12.5C13.4844 12.9219 13.3359 13.2734 13.0547 13.5547C12.7734 13.8359 12.4219 13.9844 12 14H4.5C4.07812 13.9844 3.72656 13.8359 3.44531 13.5547C3.16406 13.2734 3.01562 12.9219 3 12.5V5C3.01562 4.57812 3.16406 4.22656 3.44531 3.94531C3.72656 3.66406 4.07812 3.51562 4.5 3.5H5.4375V2.5625C5.46875 2.21875 5.65625 2.03125 6 2C6.34375 2.03125 6.53125 2.21875 6.5625 2.5625V3.5ZM4.125 12.5C4.14062 12.7344 4.26562 12.8594 4.5 12.875H12C12.2344 12.8594 12.3594 12.7344 12.375 12.5V6.5H4.125V12.5Z" fill="rgb(156, 163, 174)"/></svg>');
}

.news-preview__item p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-height: 1.2;
  /* Висота рядків */
  max-height: calc(1.2em * 3);
  /* Забезпечення обмеження висоти */
}

.btn-load-more {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.vis-rating {
  display: inline-flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.vis-rating__stars {
  order: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.vis-rating__stars .stars-svg {
  width: 80px;
  min-width: 80px;
  height: calc(80px / 5.9);
  position: relative;
  top: -1px;
}

.vis-rating__stars .stars-svg .stars-mask {
  fill: blanchedalmond;
}

.vis-rating__stars .stars-svg .stars-scale {
  transform: translateX(-100%);
  fill: #F1AE1D;
}

.vis-rating__stars .stars-svg .stars-border {
  stroke: #F1AE1D;
}

.vis-rating__reviews-qty {
  color: #9CA3AE;
  order: 1;
  font-size: 12px;
}

.landing-main-banner {
  display: inline-flex;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #f0f2f6;
  background-image: url(" /img/landing-banners/bg-image.jpg"), url(" /parking/img/landing-banners/bg-image.jpg");
  background-size: cover !important;
  padding-bottom: calc(8px * 3);
  box-sizing: border-box;
  position: relative;
  z-index: 4;
}

.landing-main-banner .resolution-wrap {
  min-height: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: calc(8px * 4);
}

.landing-main-banner .welcome-slider {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 3;
  position: relative;
  overflow: visible;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s linear 0.2s;
}

.landing-main-banner .welcome-slider .slick-list {
  overflow: visible;
  max-width: 100% !important;
}

.landing-main-banner .welcome-slider .slick-list .slick-slide {
  transition: all 0.2s linear;
}

.landing-main-banner .welcome-slider .slick-list .slick-slide.active-slide {
  opacity: 1;
  position: relative;
}

.landing-main-banner .welcome-slider .landing-main-banner__content {
  min-height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.landing-main-banner .welcome-slider .landing-main-banner__content__image {
  position: absolute;
  z-index: 2;
  width: 700px;
  height: calc(700px / 1.4);
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
}

.landing-main-banner .welcome-slider.slick-initialized {
  opacity: 1;
  visibility: visible;
}

.landing-main-banner .content-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  flex: 1 1 auto;
  margin-right: auto;
  max-width: 57%;
  position: relative;
  z-index: 3;
}

.landing-main-banner.with-form .content-wrapper {
  max-width: none;
}

.landing-main-banner__content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  gap: calc(8px * 4);
  position: relative;
  flex: 1 1 auto;
  z-index: 4;
}

.landing-main-banner__content__image {
  position: absolute;
  z-index: 2;
  width: 700px;
  height: calc(700px / 1.4);
  top: 0;
  left: 100%;
  transform: translate(-50%, -13%);
}

.landing-main-banner__content__image .dmv-partners {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: 10%;
  left: 20px;
}

.landing-main-banner__content__image .dmv-partners__item {
  overflow: hidden;
  display: flex;
  border-radius: 50%;
  box-shadow: 13px 23px 60px -4px rgba(11, 5, 27, 0.4);
  background-size: cover !important;
}

.landing-main-banner__content__image .dmv-partners__item:hover {
  transform: translateY(-2px);
  filter: brightness(1.2);
  box-shadow: 18px 28px 60px -4px rgba(11, 5, 27, 0.3);
  z-index: 3;
}

.landing-main-banner__content__image .dmv-partners__item:nth-child(1) {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  background: #DBE1E5 url("/img/partner-dmv.webp") 50% 50% no-repeat;
}

.landing-main-banner__content__image .dmv-partners__item:nth-child(2) {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  background: #DBE1E5 url("/img/partner-bbb.webp") 50% 50% no-repeat;
}

.landing-main-banner__content__image img {
  width: 100%;
}

.landing-main-banner__content__title {
  margin-bottom: 8px;
}

.landing-main-banner__content__title > h1 {
  font: 900 50px/100% 'Roboto', Helvetica, Arial, sans-serif;
}

.landing-main-banner__content__description > p {
  font: 400 16px/140% 'Roboto', Helvetica, Arial, sans-serif;
}

.landing-main-banner__content__button {
  margin-top: calc(8px * 2);
  justify-content: flex-start;
}

.landing-main-banner__content__button > * {
  flex: 0 0 auto;
  display: inline-flex;
  margin-right: auto;
}

.landing-main-banner__content .select-state {
  display: flex;
  position: relative;
  width: 100%;
  margin-top: calc(8px * 4);
  border: 1px solid rgba(255, 255, 255, 0.5);
  gap: calc(8px * 4);
  z-index: 3;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  border-radius: calc(8px * 2);
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  padding: 24px 32px 24px 64px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.landing-main-banner__content .select-state__description {
  flex: 0 1 260px;
}

.landing-main-banner__content .select-state__description__title > * {
  font-size: 22px;
  font-weight: 700;
}

.landing-main-banner__content .select-state__description__text > * {
  font-size: 12px;
  color: #59616D;
  line-height: 120%;
}

.landing-main-banner__content .select-state form {
  flex: 1 1 auto;
  display: flex !important;
  flex-direction: row;
  padding-bottom: 0;
  flex-wrap: nowrap;
}

.landing-main-banner__content .select-state form .input-item:first-of-type {
  flex: 1 1 auto;
}

.landing-main-banner__content .select-state__icon {
  position: absolute;
  top: 0;
  left: 8px;
  transform: translateY(-50%);
  transform-origin: 0 100%;
  animation: jump 2s ease-out infinite;
}

.landing-main-banner__content .select-state__icon svg {
  width: 60px;
  height: 60px;
  fill: #159DF1;
}

.landing-main-banner__form {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 380px;
  padding: calc(8px * 3);
  background: #fff;
  border: 1px solid #DBE1E5;
  border-radius: 8px;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
}

.landing-main-banner__form form {
  padding-bottom: 0;
}

.landing-main-banner__form .verificate-btn {
  margin-left: auto;
}

.landing-main-banner .wave {
  position: absolute;
  bottom: -1px;
  z-index: 1;
  left: calc(50% - 1px);
  display: block;
  transform: translate(-50%, 0);
  width: calc(100% + 2px);
  min-width: 1024px;
  height: 25%;
  fill: #fff;
}

.landing-main-banner .wave path {
  width: 100%;
  height: 100%;
}

.main-services {
  position: relative;
  z-index: 5;
  display: flex;
  gap: calc(8px * 3);
}

.main-services__item {
  display: flex;
  padding: calc(8px * 3);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  background: #fff;
  border: 1px solid #F0F2F6;
  border-radius: 8px;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  flex: 1 0 calc(33.33% - calc(8px * 1.5));
}

.main-services__item__image {
  margin-bottom: 8px;
}

.main-services__item__image svg,
.main-services__item__image img {
  width: 154px;
  height: calc(154px / 1.45);
}

.main-services__item__description {
  text-align: center;
  font-size: 14px;
  line-height: 135%;
  width: 100%;
  min-height: 40px;
  order: 3;
}

.main-services__item__description h2 {
  display: none;
  width: 100%;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  tab-index: left;
}

.main-services__item__button {
  display: flex;
  width: 100%;
  margin-top: auto;
  order: 2;
  margin-bottom: calc(8px * 2);
}

.main-services__item__button > * {
  flex: 1 1 auto;
  min-height: 50px;
  font-weight: 700;
}

.main-services__item__button > * span {
  font-size: 16px;
  text-transform: uppercase;
}

.main-services__item__button > * svg {
  display: none;
}

@keyframes jump {
  0%, 100% {
    transform: translateY(-50%) scaleY(1);
  }

  45% {
    transform: translateY(-40%) scaleY(0.98);
  }

  50% {
    transform: translateY(-40%) scaleY(0.95);
  }

  56% {
    transform: translateY(-40%) scaleY(0.98);
  }
}

.snake-blocks {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #F0F2F6;
}

.snake-blocks .section-title {
  padding-top: calc(8px * 4);
}

.snake-blocks__section {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
}

.snake-blocks__section .resolution-wrap {
  max-width: 680px;
}

.snake-blocks__section__content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: calc(8px * 4);
}

.snake-blocks__section__content-image svg,
.snake-blocks__section__content-image img {
  width: 280px;
  min-width: 280px;
}

.snake-blocks__section__content-description .description-title {
  padding-bottom: 8px;
}

.snake-blocks__section__content-description .description-title > * {
  font: 600 28px/110% 'Roboto', Helvetica, Arial, sans-serif;
}

.snake-blocks__section__content-description .description-text {
  padding-bottom: calc(8px * 2);
}

.snake-blocks__section__content-description .description-text p {
  font: 400 16px/140% 'Roboto', Helvetica, Arial, sans-serif;
}

.snake-blocks__section__content-description .description-btn {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.snake-blocks__section__content-description .description-btn a {
  margin-right: auto;
}

.snake-blocks__section:not(.section-title):nth-child(2n+1):not(:only-child) {
  background: #F0F2F6;
}

.snake-blocks__section:not(.section-title):nth-child(2n+1):not(:only-child) .snake-blocks__section__content {
  flex-direction: row-reverse;
}

.additional-blocks {
  display: flex;
  min-width: 100%;
  flex: 1 1 auto;
  position: relative;
  padding-top: calc(8px * 8);
  gap: calc(8px * 4);
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 32px;
}

.additional-blocks__item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 180px;
  gap: 8px;
}

.additional-blocks__item:not(:last-child):not(:only-child)::after {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  right: calc(-8px * 2);
  top: 0;
  background: #DBE1E5;
}

.additional-blocks__item h3,
.additional-blocks__item h4,
.additional-blocks__item h5 {
  font-size: 14px;
  font-weight: 700;
  line-height: 110%;
}

.additional-blocks__item p {
  font-size: 12px;
  line-height: 110%;
  width: calc(100% - 50px);
}

.additional-blocks__item a {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #159DF1;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.additional-blocks__item a svg {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  fill: #fff;
}

.snake-blocks-2 {
  display: flex;
  flex-direction: column;
  padding-top: calc(8px * 4);
  border-bottom: 1px solid #F0F2F6;
  counter-reset: num;
}

.snake-blocks-2__section {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  position: relative;
}

.snake-blocks-2__section:nth-child(n+10) .resolution-wrap::before {
  content: counter(num);
}

.snake-blocks-2__section .resolution-wrap {
  max-width: 680px;
  position: relative;
}

.snake-blocks-2__section .resolution-wrap::before {
  position: absolute;
  content: '0' counter(num);
  counter-increment: num;
  width: 2ch;
  /* Забезпечує однакову ширину для всіх номерів */
  color: #159DF1;
  font-size: 234px;
  font-weight: 900;
  left: 24px;
  top: 40%;
  transform: translate(0, -100%);
  opacity: 0.2;
}

.snake-blocks-2__section__content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: calc(8px * 4);
  width: calc(100% - 180px);
  margin-left: auto;
}

.snake-blocks-2__section__content-description .description-title {
  padding-bottom: 8px;
  position: relative;
  padding-top: 8px;
}

.snake-blocks-2__section__content-description .description-title::before {
  content: '';
  width: 70px;
  height: 3px;
  background: #159DF1;
  position: absolute;
  top: -3px;
}

.snake-blocks-2__section__content-description .description-title h3 {
  font: 600 28px/110% 'Roboto', Helvetica, Arial, sans-serif;
}

.snake-blocks-2__section__content-description .description-text {
  padding-bottom: calc(8px * 2);
}

.snake-blocks-2__section__content-description .description-text p {
  font: 400 16px/140% 'Roboto', Helvetica, Arial, sans-serif;
}

.snake-blocks-2__section__content-description .description-btn {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.snake-blocks-2__section__content-description .description-btn a {
  margin-right: auto;
}

.snake-blocks-2__section:nth-child(2n + 1) .resolution-wrap::before {
  left: auto;
  right: 32px;
  transform: translate(0, -100%);
}

.snake-blocks-2__section:nth-child(2n + 1) .snake-blocks-2__section__content {
  margin-right: auto;
  margin-left: 0;
}

.snake-blocks-4 {
  display: flex;
  flex-direction: column;
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  border-bottom: 1px solid #DBE1E5;
  background: #2E383E;
  color: #fff;
}

.snake-blocks-4 .about-steps {
  display: flex;
  max-width: 1199.9px;
  margin-left: auto;
  margin-right: auto;
  gap: 24px;
  padding-top: 24px;
}

.snake-blocks-4 .about-steps__item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 300px;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  position: relative;
}

.snake-blocks-4 .about-steps__item:first-child::before,
.snake-blocks-4 .about-steps__item:last-child::before {
  content: '';
  display: block;
  width: calc(100% - 180px + 8px * 3);
  height: 1px;
  border-bottom: 2px dashed #59616D;
  position: absolute;
  z-index: 1;
  top: 90px;
}

.snake-blocks-4 .about-steps__item:first-child::before {
  left: 100%;
  transform: translateX(calc(-50% + 8px * 1.5));
}

.snake-blocks-4 .about-steps__item:last-child::before {
  right: 100%;
  transform: translateX(calc(50% - 8px * 1.5));
}

.snake-blocks-4 .about-steps__item__image {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 180px;
  height: 180px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
}

.snake-blocks-4 .about-steps__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.snake-blocks-4 .about-steps__item__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.snake-blocks-4 .about-steps__item__content .description-title h3 {
  font: 600 22px/110% 'Roboto', Helvetica, Arial, sans-serif;
}

.snake-blocks-4 .about-steps__item__content .description-text {
  padding-bottom: calc(8px * 2);
  opacity: 0.6;
}

.snake-blocks-4 .about-steps__item__content .description-text p {
  font: 400 16px/140% 'Roboto', Helvetica, Arial, sans-serif;
}

.snake-blocks-3 {
  display: flex;
  flex-direction: column;
  padding-top: calc(8px * 4);
  border-bottom: 1px solid #F0F2F6;
}

.snake-blocks-3__section {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
}

.snake-blocks-3__section .resolution-wrap {
  max-width: 860px;
}

.snake-blocks-3__section__content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: calc(8px * 4);
}

.snake-blocks-3__section__content-image svg,
.snake-blocks-3__section__content-image img {
  width: 380px;
  min-width: 380px;
}

.snake-blocks-3__section__content-description .description-title {
  padding-bottom: 8px;
}

.snake-blocks-3__section__content-description .description-title > * {
  font: 600 28px/110% 'Roboto', Helvetica, Arial, sans-serif;
}

.snake-blocks-3__section__content-description .description-text {
  padding-bottom: calc(8px * 2);
}

.snake-blocks-3__section__content-description .description-text p {
  font: 400 16px/140% 'Roboto', Helvetica, Arial, sans-serif;
  margin-bottom: 8px;
}

.snake-blocks-3__section__content-description .description-btn {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.snake-blocks-3__section__content-description .description-btn a {
  margin-right: auto;
}

.snake-blocks-3__section:nth-child(2n + 1):not(:only-child) {
  background: #F0F2F6;
}

.snake-blocks-3__section:nth-child(2n + 1):not(:only-child) .snake-blocks-3__section__content {
  flex-direction: row-reverse;
}

.section-theme-3 {
  background: #2E383E;
  background: -moz-linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  background: -webkit-linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  background: linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@{@color-b1}", endColorstr="@{@color-b2}", GradientType=1);
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  color: #fff;
}

.section-theme-3 .columns-wrap {
  display: flex;
  gap: calc(8px * 2);
  padding-top: calc(8px * 3);
  justify-content: center;
  align-items: center;
  align-content: center;
}

.section-theme-3 .columns-wrap__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: calc(8px * 2);
  max-width: 320px;
}

.section-theme-3 .columns-wrap__item__icon {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #fff;
  border-radius: 100%;
}

.section-theme-3 .columns-wrap__item__icon svg {
  width: 48px;
  height: 48px;
  fill: #59616D;
}

.section-theme-3 .columns-wrap__item__text {
  text-align: center;
}

.section-theme-9 {
  background: #fff;
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
}

.section-theme-9 .columns-wrap {
  display: flex;
  gap: calc(8px * 2);
  padding-top: calc(8px * 3);
  justify-content: center;
  align-items: center;
  align-content: center;
}

.section-theme-9 .columns-wrap__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: calc(8px * 2);
  max-width: 320px;
}

.section-theme-9 .columns-wrap__item__icon {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #fff;
  border-radius: 100%;
}

.section-theme-9 .columns-wrap__item__icon svg {
  width: 60px;
  height: auto;
}

.section-theme-9 .columns-wrap__item__text {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.section-theme-4 {
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  background: #F0F2F6;
}

.section-theme-4 .slider-comments-type__item {
  min-width: 280px;
  width: calc(100% - 64px);
  max-width: 420px;
}

.section-theme-4 .slider-comments-type__item .slide-wrap {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: calc(8px * 4) calc(8px * 3) calc(8px * 3);
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
  min-height: 180px;
}

.section-theme-4 .slider-comments-type__item .slide-wrap .slide-head {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 8px;
}

.section-theme-4 .slider-comments-type__item .slide-wrap .slide-head .author-name {
  display: flex;
  white-space: nowrap;
  font-size: 22px;
  font-weight: 600;
}

.section-theme-4 .slider-comments-type__item .slide-wrap .slide-head time {
  text-align: right;
  white-space: nowrap;
  font-size: 12px;
  color: #9CA3AE;
  font-weight: 400;
  margin-left: auto;
  position: relative;
  top: 2px;
}

.section-theme-4 .slider-comments-type__item .slide-wrap .comment-text {
  font: 400 14px/140% 'Roboto', Helvetica, Arial, sans-serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
  line-height: 1.5;
  /* Висота рядків */
  max-height: calc(1.5em * 4);
  /* Забезпечення обмеження висоти */
}

.section-theme-5 {
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  background: #2E383E;
}

.section-theme-5 .section-title {
  color: #fff;
  display: none;
}

.section-theme-5 .prices-preview {
  display: flex;
  gap: calc(8px * 3);
  overflow: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  /* Плавна прокрутка */
  scroll-snap-type: x mandatory;
  /* Прив'язка до елементів при свайпі */
  /* Приховуємо скролбар */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.section-theme-5 .prices-preview::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Edge */
}

.section-theme-5 .prices-preview__item {
  border-radius: 24px;
  background: #fff;
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: calc(8px * 3);
  flex: 1 0 240px;
  user-select: none;
}

.section-theme-5 .prices-preview__item__title > * {
  font-size: 22px;
  font-weight: 600;
}

.section-theme-5 .prices-preview__item__description {
  margin-bottom: 8px;
  padding-bottom: calc(8px * 2);
  position: relative;
}

.section-theme-5 .prices-preview__item__description::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  background: #159DF1;
  display: block;
  width: 70px;
  height: 2px;
}

.section-theme-5 .prices-preview__item__description > * {
  font-size: 16px;
  font-weight: 400;
  color: #59616D;
}

.section-theme-5 .prices-preview__item__price {
  margin-top: auto;
  text-align: left;
}

.section-theme-5 .prices-preview__item__price > span {
  font-size: 16px;
}

.section-theme-5 .prices-preview__item__price-number {
  margin-bottom: 8px;
  display: flex;
  min-height: 50px;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}

.section-theme-5 .prices-preview__item__price-number span {
  position: relative;
  font-size: 44px;
  color: #59616D;
  font-weight: 700;
}

.section-theme-5 .prices-preview__item__price-number b {
  font-size: 60px;
  line-height: 110%;
  font-weight: 700;
  color: #2E383E;
}

.section-theme-fees {
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  background: #fff;
}

.section-theme-fees .resolution-wrap {
  max-width: 1248px;
}

.section-theme-fees .prices-preview {
  display: flex;
  flex-wrap: wrap;
  padding-top: 32px;
  /* Приховуємо скролбар */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  margin-bottom: 32px;
}

.section-theme-fees .prices-preview__item {
  gap: 24px;
  padding: 24px 16px 24px 16px;
  display: flex;
  background: #fff;
  width: calc(50% - calc(8px * 3));
}

.section-theme-fees .prices-preview__item:nth-child(odd):not(:last-child) {
  border-right: 1px solid #DBE1E5;
}

.section-theme-fees .prices-preview__item:not(:nth-last-child(-n+2)) {
  border-bottom: 1px solid #DBE1E5;
}

.section-theme-fees .prices-preview__item__image {
  top: -24px;
  position: relative;
  min-width: 180px;
  width: 180px;
  height: 180px;
}

.section-theme-fees .prices-preview__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.section-theme-fees .prices-preview__item__description {
  margin-bottom: 8px;
  padding-bottom: calc(8px * 2);
  position: relative;
  /*
        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          background: @color-primary;
          display: block;
          width: 70px;
          height: 2px;

        }
*/
}

.section-theme-fees .prices-preview__item__description > * {
  font-size: 16px;
  font-weight: 400;
}

.section-theme-fees .prices-preview__item__description > h3 {
  font-size: 22px !important;
  font-weight: 600;
  margin-bottom: 16px;
}

.section-theme-fees .prices-preview__item__description ul {
  margin-bottom: 20px !important;
}

.section-theme-fees .prices-preview__item__description ul li {
  min-width: 100%;
  margin-bottom: 4px !important;
}

.section-theme-fees .prices-preview__item__description ul li strong {
  font-size: 18px;
}

.section-theme-6 {
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  background: #F0F2F6;
}

.section-theme-6.bg-w {
  background: #fff;
}

.section-theme-6 .view-more-btn {
  padding-top: calc(8px * 4);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.section-theme-7 {
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  background: #fff;
  border-bottom: 1px solid #F0F2F6;
}

.section-theme-7 .accordion {
  padding-top: calc(8px * 2);
}

.section-theme-8 {
  background: #2E383E;
  background: -moz-linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  background: -webkit-linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  background: linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@{@color-b1}", endColorstr="@{@color-b2}", GradientType=1);
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  color: #fff;
  overflow: hidden;
  position: relative;
}

.section-theme-8 .resolution-wrap {
  max-width: 500px;
  position: relative;
  z-index: 3;
}

.section-theme-8 .pre-title {
  opacity: 0.5;
  text-align: center;
  position: relative;
  top: 3px;
}

.section-theme-8 .input-item-group {
  display: flex;
  gap: 0;
}

.section-theme-8 .input-item-group > .input-item input {
  border-radius: 8px 0 0 8px !important;
}

.section-theme-8 .input-item-group > .input-item .btn-big {
  border-radius: 0 8px 8px 0 !important;
}

.section-theme-8 .input-item-group > .input-item:first-child {
  width: 100%;
}

.section-theme-8 .logo-bg-decor {
  position: absolute;
  pointer-events: none;
  width: 520px;
  height: 520px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.07;
  z-index: 1;
}

.section-theme-8 .logo-bg-decor svg {
  width: inherit;
  height: inherit;
}

.section-theme-8 .app-links {
  display: flex;
  gap: calc(8px * 2);
  justify-content: center;
  align-items: center;
  align-content: center;
}

.section-theme-8 .app-links a {
  display: flex;
  width: 120px;
  height: 40px;
}

.section-theme-8 .app-links a img {
  width: inherit;
  height: inherit;
}

.section-theme-10 {
  background: #2E383E;
  background: -moz-linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  background: -webkit-linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  background: linear-gradient(31deg, #2E383E 0%, #59616D 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@{@color-b1}", endColorstr="@{@color-b2}", GradientType=1);
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  color: #fff;
  overflow: hidden;
  position: relative;
}

.section-theme-10 .resolution-wrap {
  max-width: 860px;
  position: relative;
  z-index: 3;
}

.section-theme-10 .columns-wrap {
  display: flex;
  gap: 32px;
}

.section-theme-10 .columns-wrap__item__number {
  font-size: 70px;
  line-height: 120%;
  font-weight: 900;
  position: relative;
  margin-bottom: 16px;
}

.section-theme-10 .columns-wrap__item__number::after {
  content: '';
  width: 60px;
  height: 4px;
  background: #159DF1;
  position: absolute;
  left: 0;
  bottom: 0;
}

.section-theme-10 .columns-wrap__item__text {
  font: 400 14px/120% 'Roboto', Helvetica, Arial, sans-serif;
  color: #9CA3AE;
}

.section-theme-10 .logo-bg-decor {
  position: absolute;
  pointer-events: none;
  width: 520px;
  height: 520px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0.07;
  z-index: 1;
}

.section-theme-10 .logo-bg-decor svg {
  width: inherit;
  height: inherit;
}

.locations-list {
  padding-top: calc(8px * 2);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: calc(8px * 1.5);
}

.locations-list__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  padding: calc(8px * 2) calc(8px / 2) calc(8px * 2) calc(8px * 1.5);
  background: #fff;
  border-radius: 8px;
  border: 1px solid #F0F2F6;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  flex: 0 1 calc(33.33% - 8px);
  max-width: calc(33.33% - 8px);
  text-decoration: none;
  transition: all 0.2s linear;
}

.locations-list__item__name {
  position: relative;
  top: 6px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-height: 1.5;
  /* Висота рядків */
  max-height: calc(1.5em * 1);
  /* Забезпечення обмеження висоти */
}

.locations-list__item__name > * {
  font: 600 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
  color: #2E383E;
  white-space: nowrap;
  /* Забороняє перенос тексту */
  overflow: hidden;
  /* Приховує те, що виходить за межі */
  text-overflow: ellipsis;
  /* Додає три крапки, якщо текст не поміщається */
  max-width: 100%;
  /* Не виходить за межі контейнера */
}

.locations-list__item__icon,
.locations-list__item__arrow {
  position: relative;
  top: 3px;
}

.locations-list__item__icon svg,
.locations-list__item__arrow svg {
  width: 24px;
  height: 24px;
}

.locations-list__item__icon svg {
  fill: #159DF1;
}

.locations-list__item__arrow {
  top: 2px;
  margin-left: auto;
}

.locations-list__item__arrow svg {
  fill: #B5BAC7;
}

.content-with-sidebar__sidebar .locations-list {
  flex-direction: column;
  row-gap: 4px;
  padding-top: 0 !important;
}

.content-with-sidebar__sidebar .locations-list__item {
  width: 100%;
  flex: 1 1 100% !important;
  padding: 8px calc(8px / 2) 8px 8px;
  max-width: none;
}

.content-with-sidebar__sidebar .locations-list__item__name {
  width: 100%;
  top: 8px !important;
  display: flex;
}

.content-with-sidebar__sidebar .locations-list__item__name > * {
  font: 400 14px/110% 'Roboto', Helvetica, Arial, sans-serif;
  color: #2E383E;
  display: flex;
}

.form-example {
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
}

.location-map-container {
  background: #F0F2F6;
  margin-bottom: 32px;
  border-radius: 16px;
  overflow: hidden;
}

.location-map-container .location-on-google-map {
  width: 100%;
  min-height: 370px;
}

.simple-slider {
  position: relative;
  margin-bottom: calc(8px * 4);
}

.simple-slider .slick-slide {
  overflow: hidden;
  border-radius: 8px;
}

.simple-slider .slick-slide img {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.sidebar-services {
  display: flex;
  width: 100%;
  text-decoration: none;
  background: #fff;
  border: 1px solid #DBE1E5;
  border-radius: 8px;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: calc(8px * 2) 8px;
  gap: 8px;
  margin-bottom: 8px;
  transition: all 0.2s linear;
}

.sidebar-services__image svg,
.sidebar-services__image img {
  width: 50px;
  height: calc(50px / 1.45);
}

.sidebar-services__description {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  font-size: 14px;
  flex: 1 1 auto;
}

.sidebar-services__description h2 {
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  line-height: 100%;
  text-align: left;
  color: #2E383E;
}

.sidebar-services__icon {
  min-width: auto !important;
  width: 32px !important;
  max-width: 32px;
  height: 32px !important;
  min-height: 32px;
  border-radius: 25px;
  padding: 0 !important;
  flex: 0 0 auto;
  background: #F0F2F6;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 0.2s linear;
}

.sidebar-services__icon svg {
  width: 24px;
  height: 24px;
  position: relative;
  fill: #9CA3AE;
  transition: all 0.2s linear;
}

.blog-list-section {
  padding-bottom: 48px;
}

.blog-list-section__filters form {
  flex-direction: row;
}

.partnership {
  display: flex;
  flex-wrap: wrap;
}

.partnership__item {
  flex: 1 0 420px;
  width: 50%;
  padding-top: calc(8px * 4);
  padding-bottom: calc(8px * 4);
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}

.partnership__item__content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-self: center;
  max-width: 420px;
  padding-left: calc(8px * 3);
  padding-right: calc(8px * 3);
}

.partnership__item__content .pre-header {
  color: #9CA3AE;
}

.partnership__item.left-side {
  background: #fff;
  color: #2E383E;
}

.partnership__item.right-side {
  background: #2E383E;
  background: linear-gradient(277deg, #2E383E 0%, #59616D 100%);
  color: #fff;
}

.partnership__item.right-side a {
  color: #DBE1E5;
}

.partnership__item.right-side a.link-with-icon {
  max-width: 100%;
}

.partnership__item.right-side a.link-with-icon span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  /* Фіксована ширина */
}

.help-icon-btn,
.copy-icon-btn {
  display: inline-flex;
  position: relative;
  top: 6px;
  width: 24px;
  height: 24px;
  z-index: 2;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.help-icon-btn svg,
.copy-icon-btn svg {
  fill: #B5BAC7;
  width: 24px;
  height: 24px;
  transition: all 0.2s linear;
}

.link-with-icon {
  display: inline-flex;
}

.link-with-icon svg {
  fill: #B5BAC7;
  width: 24px;
  height: 24px;
  transition: all 0.2s linear;
}

.section-contact {
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  background: #F0F2F6;
  overflow: hidden;
}

.section-contact .resolution-wrap {
  position: relative;
}

.section-contact .resolution-wrap .logo-bg-decor {
  position: absolute;
  pointer-events: none;
  width: 380px;
  height: 380px;
  bottom: calc(-8px * 8);
  left: 0;
  transform: translate(-20%, 0);
  opacity: 0.07;
  z-index: 1;
}

.section-contact .resolution-wrap .logo-bg-decor svg {
  width: inherit;
  height: inherit;
}

.section-contact .resolution-wrap > * {
  position: relative;
  z-index: 2;
}

.section-contact .contact-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 32px;
}

.section-contact .contact-section .section-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.section-contact .contact-section .section-title > * {
  text-align: left;
}

.section-contact .contact-section .section-sub-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.section-contact .contact-section .section-sub-title > * {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}

.section-contact .contact-section__info .contacts-block {
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
}

.section-contact .contact-section__info .contacts-block a {
  padding: 8px 0;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
  color: #2E383E;
  min-height: 24px;
}

.section-contact .contact-section__info .contacts-block a svg {
  width: 24px;
  height: 24px;
  fill: #159DF1;
  position: relative;
  top: -2px;
}

.section-contact .contact-section__info__text {
  margin-bottom: 8px;
}

.section-contact .contact-section__form {
  flex: 1 0 466px;
}

.hr-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  width: 100%;
  min-height: 96px;
  gap: 16px;
}

.hr-with-text::after,
.hr-with-text::before {
  content: '';
  flex: 1 1 auto;
  height: 1px;
  background: #DBE1E5;
}

.section-profile-form {
  padding-top: 64px;
  padding-bottom: 64px;
}

.section-profile-form__content {
  display: flex;
  gap: 32px;
  position: relative;
}

.section-profile-form__content__image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  z-index: 1;
}

.section-profile-form__content__image div.img-wrap {
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 120.5%;
  transform: translateY(-7%);
}

.section-profile-form__content__image div.img-wrap img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.section-profile-form__content__form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  z-index: 2;
}

.section-profile-form__content__form .section-title,
.section-profile-form__content__form .section-sub-title {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
  text-align: left;
}

.section-profile-form__content__form .section-title > *,
.section-profile-form__content__form .section-sub-title > * {
  width: 100%;
}

.section-profile-form__content__form a {
  color: #2E383E;
}

.section-profile-form__content__form .inputs-row > * {
  flex: 1 1 auto;
  font-size: 12px;
}

.section-profile-form__content__form .inputs-row .link-btn {
  justify-content: flex-end;
  text-align: right;
}

.section-profile-form__content__form .inputs-row .link-btn a {
  margin-left: auto;
  display: inline-flex;
  min-height: 24px;
  padding-top: 2px;
  margin-bottom: 8px;
}

.section-profile-form__content .down-info {
  font-size: 12px;
}

.inputs-row {
  display: flex;
  width: 100%;
  column-gap: 16px;
}

.header-search {
  max-height: 38px;
  width: 100%;
  max-width: 320px;
  position: relative;
  z-index: 0;
  display: none;
}

.header-search .form-theme {
  width: calc(100% - 40px);
}

.header-search .form-theme .input-item {
  position: relative;
}

.header-search .form-theme .input-item .input-mask-wrap,
.header-search .form-theme .input-item .placeholder-mask {
  min-height: 38px;
  max-height: 38px;
}

.header-search .form-theme .input-item .placeholder-mask {
  font-size: 12px !important;
}

.header-search .form-theme .input-item input {
  position: relative;
  border: 1px solid #F0F2F6;
  background: #F0F2F6;
  min-height: 38px;
  max-height: 38px;
  padding-right: 42px;
  z-index: 1;
  padding-top: 0;
  font-size: 14px;
}

.header-search .form-theme .input-item input:focus:placeholder-shown,
.header-search .form-theme .input-item input:not(:placeholder-shown) {
  font: 400 16px/135% 'Roboto', Helvetica, Arial, sans-serif;
  color: #2E383E;
  min-height: 38px;
  max-height: 38px;
  font-size: 14px !important;
  line-height: 100% !important;
  border-color: #9CA3AE;
}

.header-search .form-theme .input-item input:focus:placeholder-shown ~ .placeholder-mask,
.header-search .form-theme .input-item input:not(:placeholder-shown) ~ .placeholder-mask {
  opacity: 0;
}

.header-search .form-theme .input-item button {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: 0;
  outline: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.header-search .form-theme .input-item button svg {
  width: 24px;
  height: 24px;
  fill: #B5BAC7;
  transition: all 0.2s linear;
}

.header-search .form-theme .input-item button.btn_search {
  right: 0;
}

.header-search .form-theme .input-item button.btn_neighborhoods {
  right: 4px;
}

.header-search .form-theme .input-item button.btn_neighborhoods:hover::before {
  color: #159DF1;
}

.header-search .form-theme .input-item button.btn_neighborhoods::before {
  color: #B5BAC7;
  display: none !important;
}

.header-search.neighborhoods-active .neighborhoods_find_list {
  opacity: 1;
  pointer-events: all;
}

.header-search.search-active .addresses_find_list {
  opacity: 1;
  pointer-events: all;
}

.header-search .neighborhoods_find_list,
.header-search .addresses_find_list {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  top: 40px;
  background: #fff;
  border: 1px solid #DBE1E5;
  border-radius: 8px;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  padding: 4px 4px 4px 0;
  transition: all 0.2s linear;
  opacity: 0;
  pointer-events: none;
}

.header-search .neighborhoods_find_list ul,
.header-search .addresses_find_list ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  height: auto;
  max-height: 420px;
  overflow: hidden;
  overflow-y: auto;
}

.header-search .neighborhoods_find_list ul li,
.header-search .addresses_find_list ul li {
  width: 100%;
  display: block;
}

.header-search .neighborhoods_find_list ul li:not(:last-child),
.header-search .addresses_find_list ul li:not(:last-child) {
  border-bottom: 1px solid #F0F2F6;
}

.header-search .neighborhoods_find_list ul li a,
.header-search .addresses_find_list ul li a {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: calc(8px + 2px) 16px 8px;
  text-decoration: none;
  color: #2E383E;
  font-size: 14px;
}

.map-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--app-height);
  background: #fff;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.map-wrapper .map-container,
.map-wrapper .map_container {
  background: #59616D;
  display: block;
  width: 100%;
  height: 100%;
}

.street-cleaning-block__open-close {
  display: none;
  left: 10px;
  z-index: 8;
}

.left-block {
  position: absolute;
  z-index: 3;
  left: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 280px;
  transition: all 0.14s linear;
  transform-origin: 0 0 !important;
}

.left-block__head {
  background: #F0F2F6;
  border-width: 1px 1px 0 1px;
  border-color: #DBE1E5;
  border-radius: 8px 8px 0 0;
  display: flex;
  border-style: solid;
  gap: 8px;
  padding: 13px 16px 12px;
  min-height: 50px;
}

.left-block__head > h1,
.left-block__head > h2 {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 2px;
}

.left-block__head__icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.left-block__head__icon > * {
  fill: #59616D;
}

.left-block__body {
  background: #fff;
  border-width: 0 1px 1px 1px;
  border-color: #DBE1E5;
  border-radius: 0 0 8px 8px;
  border-style: solid;
}

.left-block__body .info {
  padding: 8px;
  font-size: 12px;
  margin-bottom: 0 !important;
}

.geolocation-btn {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 6px;
  border-radius: 100%;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #159DF1;
  transition: all 0.2s linear;
}

.geolocation-btn svg {
  transition: all 0.2s linear;
  fill: #fff;
  width: 16px;
  height: 16px;
  z-index: 3;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabs__caption {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  padding-left: 8px;
  padding-right: 8px;
}

.tabs:not(.inactive-tabs) .tabs__caption::after,
.tabs:not(.inactive-tabs) .tabs__caption::before {
  content: '';
  display: flex;
  bottom: 0;
  position: absolute;
  width: 8px;
  height: 100%;
  border-bottom: 1px solid #DBE1E5;
}

.tabs:not(.inactive-tabs) .tabs__caption::after {
  right: 0;
}

.tabs:not(.inactive-tabs) .tabs__caption::before {
  left: 0;
}

.tabs:not(.inactive-tabs) .tabs__caption__item {
  flex: 1 1 auto;
  width: auto;
  display: flex;
  gap: 4px;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 8px 8px 0 0;
}

.tabs:not(.inactive-tabs) .tabs__caption__item span {
  font-size: 12px;
  display: inline-flex;
  min-height: 16px;
}

.tabs:not(.inactive-tabs) .tabs__caption__item:not(.active) {
  background: #F0F2F6;
  border-bottom: 1px solid #DBE1E5;
}

.tabs:not(.inactive-tabs) .tabs__caption__item:not(.active) span {
  color: #B5BAC7;
}

.tabs:not(.inactive-tabs) .tabs__caption__item:not(.active) svg {
  fill: #B5BAC7;
}

.tabs:not(.inactive-tabs) .tabs__caption__item.active {
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #DBE1E5;
}

.tabs:not(.inactive-tabs) .tabs__caption__item.active svg {
  fill: #159DF1;
}

.tabs:not(.inactive-tabs) .tabs__caption__item .tab__icon {
  min-width: 16px;
  width: 16px;
  height: 16px;
}

.tabs.inactive-tabs {
  padding-bottom: 8px;
}

.tabs.inactive-tabs .tabs__caption {
  gap: 2px;
}

.tabs.inactive-tabs .tabs__caption__item {
  flex: 1 1 auto;
  width: auto;
  display: flex;
  gap: 4px;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 8px;
}

.tabs.inactive-tabs .tabs__caption__item span {
  font-size: 12px;
  display: inline-flex;
  min-height: 16px;
}

.tabs.inactive-tabs .tabs__caption__item:not(.active) {
  background: #F0F2F6;
}

.tabs.inactive-tabs .tabs__caption__item:not(.active) span {
  color: #B5BAC7;
}

.tabs.inactive-tabs .tabs__caption__item:not(.active) svg {
  fill: #B5BAC7;
}

.tabs.inactive-tabs .tabs__caption__item.active {
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #DBE1E5;
}

.tabs.inactive-tabs .tabs__caption__item.active svg {
  fill: #159DF1;
}

.tabs.inactive-tabs .tabs__caption__item .tab__icon {
  min-width: 16px;
  width: 16px;
  height: 16px;
}

.tabs__content:not(.active) {
  display: none;
}

.tabs__content.active {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  height: var(--app-height);
}

.tabs__content.active::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #9CA3AE;
}

.tabs__content.active::-webkit-scrollbar-thumb {
  background: #59616D;
  border-radius: 8px;
}

.tabs__content.active::-webkit-scrollbar-thumb:hover {
  background: #159DF1;
}

.tabs__content.active::-webkit-scrollbar-thumb:active {
  background: #159DF1;
}

.street-cleaning-form {
  padding: 5.33333333px !important;
  background: #F0F2F6;
  border-radius: 8px;
}

.street-cleaning-form .inputs-row {
  gap: 8px !important;
}

.street-cleaning-form .inputs-row > * {
  flex: 1 1 50%;
}

.street-cleaning-form .input-mask-wrap {
  min-height: 38px;
}

.street-cleaning-form .input-mask-wrap.select-container::before {
  width: 38px;
  height: 38px;
  right: -4px;
}

.street-cleaning-form .input-mask-wrap select {
  padding-top: 2px;
  padding-left: 8px !important;
  padding-right: 18px;
  min-height: 38px;
  font-size: 12px !important;
}

.steps-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.steps-group__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  min-height: 32px;
  color: #59616D;
  line-height: 105%;
  gap: 8px;
  position: relative;
}

.steps-group__item.hidden {
  display: none !important;
}

.steps-group__item:not(:only-child):not(:last-of-type)::before {
  content: '';
  position: absolute;
  left: 15px;
  width: 2px;
  height: calc(100% + 8px);
  background: #DBE1E5;
  z-index: 1;
}

.steps-group__item__icon {
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  background: #fff;
}

.steps-group__item__icon[data-color="gray"] {
  border-color: #B5BAC7;
}

.steps-group__item__icon[data-color="gray"] svg > * {
  fill: #B5BAC7;
}

.steps-group__item__icon[data-color="green"] {
  border-color: #9EC850;
}

.steps-group__item__icon[data-color="green"] svg > * {
  fill: #9EC850;
}

.steps-group__item__icon[data-color="orange"] {
  border-color: #F1AE1D;
}

.steps-group__item__icon[data-color="orange"] svg > * {
  fill: #F1AE1D;
}

.steps-group__item__icon[data-color="red"] {
  border-color: #D84C3A;
}

.steps-group__item__icon[data-color="red"] svg > * {
  fill: #D84C3A;
}

.steps-group__item__icon svg {
  width: 24px;
  height: 24px;
}

.steps-group__item__content {
  width: 100%;
  display: inline-flex;
  min-height: 32px;
  position: relative;
  flex-direction: column;
}

.steps-group__item__content .text {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  min-height: 32px;
  padding: 0;
  gap: 4px;
  font-size: 12px;
  padding-right: 24px;
}

.steps-group__item__content .help-btn {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 2;
}

.steps-group__item__content .help-btn.active {
  z-index: 8;
}

.steps-group__item__content .help-btn.active .help-tooltip {
  transform: scaleY(1) translateY(0);
  opacity: 1;
  pointer-events: all;
}

.steps-group__item__content .help-btn svg {
  width: 24px;
  height: 24px;
  fill: #B5BAC7;
  transition: all 0.2s linear;
}

.steps-group__item__content .help-btn .help-tooltip {
  position: absolute;
  width: 200px;
  top: 32px;
  right: -5px;
  font-size: 12px;
  line-height: 130%;
  border-radius: 8px;
  padding: 8px;
  background: #fff;
  border: 1px solid #DBE1E5;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
  transform-origin: 0 0;
  transition: all 0.1s linear;
  transform: scaleY(0.5) translateY(-10px);
  opacity: 0;
  pointer-events: none;
}

.steps-group__item__content .help-btn .help-tooltip::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 4px 0 0 0;
  border-left: 1px solid #DBE1E5;
  border-top: 1px solid #DBE1E5;
  position: absolute;
  top: -6px;
  right: 10px;
  background: #fff;
  transform: rotate(45deg);
}

.steps-group__item__content .view-schedule-btn {
  display: flex;
  text-align: left;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 4px;
}

.steps-group__item__content .view-schedule-btn__close {
  display: none;
}

.steps-group__item__content .view-schedule-btn.active .view-schedule-btn__view {
  display: none;
}

.steps-group__item__content .view-schedule-btn.active .view-schedule-btn__close {
  display: block;
}

.steps-group__item__content .view-schedule-btn.active svg:last-child {
  transform: scaleY(-1);
}

.steps-group__item__content .view-schedule-btn span {
  color: #159DF1;
  font-size: 12px;
  line-height: 102%;
}

.steps-group__item__content .view-schedule-btn svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
  fill: #159DF1;
}

.sc-login {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 220px;
  text-align: center;
}

.sc-login > * {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sc-login > * .btn-small {
  min-width: 220px;
}

.setup-alert .alert_form {
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
}

.setup-alert .alert_form .user-data-label {
  font-size: 12px;
  white-space: nowrap;
  /* Забороняє перенесення тексту на новий рядок */
  overflow: hidden;
  /* Ховає текст, який виходить за межі контейнера */
  text-overflow: ellipsis;
  /* Додає "..." до кінця тексту */
  display: block;
  /* Підходить для блочних елементів */
  width: 100%;
  /* Ширина контейнера (можна змінити) */
  max-width: 220px;
}

.setup-alert .alert_form .input-mask-wrap {
  min-height: 38px;
}

.setup-alert .alert_form .input-mask-wrap.select-container::before {
  width: 38px;
  height: 38px;
  right: -4px;
}

.setup-alert .alert_form .input-mask-wrap select {
  padding-top: 2px;
  padding-left: 8px !important;
  padding-right: 18px;
  min-height: 38px;
  font-size: 12px !important;
}

.setup-alert .alert_form .sc-form-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 8px;
}

.setup-alert .alert_form .sc-form-footer .btn-small {
  max-width: 220px;
  min-width: 220px;
}

.streetcleaning_calendar {
  z-index: 3;
  display: none;
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  padding: 0;
  background: #fff;
  margin-top: 8px;
}

.streetcleaning_calendar .sc_calendar_head {
  height: 26px;
  border-radius: 8px;
  background: #F0F2F6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 8px;
  padding: 0 16px;
}

.streetcleaning_calendar .sc_calendar_head > * {
  display: inline-block;
  line-height: 102%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 11px;
}

.streetcleaning_calendar .sc_calendar_head .sc_date {
  text-align: right;
}

.streetcleaning_calendar .sc_calendar_body {
  display: table;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar_week_number {
  width: 22px;
  position: absolute;
  left: 0;
  border-right: 1px solid #DBE1E5;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar_week_number > * {
  width: 100%;
  text-align: center;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar {
  width: 100%;
  padding-left: 22px;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days,
.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers {
  display: flex;
  flex-wrap: wrap;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days > *,
.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers > * {
  display: inline-block;
  flex: 1 1 calc(100% / 7);
  height: 26px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days > * {
  font-size: 12px;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days > *:not(:last-child) {
  border_right: 1px solid #B5BAC7;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers > * {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers > *:nth-child(7n+7) {
  border-right: none;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers > * span {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 20px;
  height: 20px;
  line-height: 102%;
  border: 1px solid #B5BAC7;
  border-radius: 30px;
  font-size: 10px;
  text-align: center;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers .prev_month,
.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers .next_month {
  color: #59616D;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers .sc_today > span {
  background: #159DF1;
  color: #fff;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers .sc_date > sup {
  top: 0;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers .sc_yes > span {
  border-color: #D84C3A !important;
  cursor: pointer;
}

.streetcleaning_calendar .sc_calendar_body .sc_calendar .days_numbers .sc_yes.active span {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
}

.streetcleaning_calendar .sc_calendar_footer {
  height: 26px;
  border-radius: 8px;
  background: #F0F2F6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 8px;
  padding: 0 16px;
}

.streetcleaning_calendar .sc_calendar_footer > * {
  display: inline-block;
  line-height: 102%;
}

.streetcleaning_calendar .sc_calendar_footer .sc_calendar_legend {
  padding-left: 25px;
  position: relative;
  font-size: 12px;
}

.streetcleaning_calendar .sc_calendar_footer .sc_calendar_legend:before {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
  border: 1px solid #D84C3A;
  border-radius: 30px;
}

.view-schedule-btn.active ~ .streetcleaning_calendar {
  display: block;
}

.banner-on-map {
  position: absolute;
  display: block;
  right: 10px;
  z-index: 2;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s linear;
  opacity: 1;
  transform: translateX(0);
}

.banner-on-map.hide {
  opacity: 0;
  transform: translateX(100%);
}

.banner-on-map__close-btn {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 24px;
  height: 24px;
  background: #2E383E;
  top: -6px;
  right: -6px;
  border-radius: 100%;
  z-index: 4;
  transition: all 0.2s linear;
}

.banner-on-map__close-btn::after {
  display: block;
  width: 16px;
  height: 16px;
  content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0078 10.9453L17.7266 5.22656C18.0807 4.9349 18.4245 4.9349 18.7578 5.22656C19.0703 5.58073 19.0703 5.9349 18.7578 6.28906L13.0703 12.0078L18.7578 17.7266C19.0703 18.0807 19.0703 18.4245 18.7578 18.7578C18.4245 19.0703 18.0807 19.0703 17.7266 18.7578L12.0078 13.0703L6.28906 18.7578C5.9349 19.0703 5.58073 19.0703 5.22656 18.7578C4.9349 18.4245 4.9349 18.0807 5.22656 17.7266L10.9453 12.0078L5.22656 6.28906C4.9349 5.9349 4.9349 5.58073 5.22656 5.22656C5.58073 4.9349 5.9349 4.9349 6.28906 5.22656L12.0078 10.9453Z" fill="rgb(255,255,255)"/></svg>');
}

.banner-on-map a {
  display: flex;
  width: 220px;
  max-width: inherit;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
  height: calc(220px / 3.01);
  border-radius: 8px;
  border: 1px solid transparent;
}

.banner-on-map a img {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  transition: all 0.2s linear;
}

.search-on-map .input-item-group,
.sc-alert-subscribe .input-item-group {
  display: flex;
  gap: 0;
}

.search-on-map .input-item-group > .input-item input,
.sc-alert-subscribe .input-item-group > .input-item input {
  border-radius: 8px 0 0 8px !important;
}

.search-on-map .input-item-group > .input-item .btn-big,
.sc-alert-subscribe .input-item-group > .input-item .btn-big {
  border-radius: 0 8px 8px 0 !important;
}

.search-on-map .input-item-group > .input-item .btn-big svg,
.sc-alert-subscribe .input-item-group > .input-item .btn-big svg {
  display: none;
}

.search-on-map .input-item-group > .input-item:first-child,
.sc-alert-subscribe .input-item-group > .input-item:first-child {
  width: 100%;
}

.map-block-in-text-page {
  width: 100%;
  display: block;
  background: #F0F2F6;
  border-radius: 8px;
  min-height: 280px;
  overflow: hidden;
  margin-bottom: 32px;
}

.form_signup .input-item button,
.form_signup .input-item input[type=submit],
.form_signup .input-item input[type=button] {
  width: 100%;
  justify-self: stretch !important;
}

.section-user-preferences {
  text-align: center;
  padding: 64px 0;
}

.section-user-preferences .text-box {
  margin-left: auto;
  margin-right: auto;
}

.section-user-preferences__img {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.section-user-preferences .btns-group {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.user-info-container {
  padding-bottom: 24px;
}

.user-info-container__form {
  display: none;
}

.user-info-container__preview .user-info-table {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.user-info-container__preview .user-info-table__item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0;
}

.user-info-container__preview .user-info-table__item div:first-child {
  color: #9CA3AE;
  min-width: 120px;
  font-size: 14px;
}

.user-info-container__preview .user-info-table__item div:last-child {
  color: #2E383E;
  font-size: 20px;
}

.user-info-container.edit .user-info-container__form {
  display: block;
}

.user-info-container.edit .user-info-container__preview {
  display: none;
}

.btns-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.pdng-btn-3 {
  padding-bottom: 24px;
}

.faq-list-container .accordion {
  margin-bottom: 32px;
}

.user-alerts-table {
  display: table;
  width: 100%;
  list-style: none !important;
  padding: 16px;
  background: #fff;
  border: 1px solid #DBE1E5;
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  overflow-x: auto;
  border-radius: 8px;
}

.user-alerts-table li {
  display: table-row;
  margin: 0 !important;
  padding: 0 !important;
  min-height: auto !important;
}

.user-alerts-table li:nth-child(2) > * {
  padding-top: 24px;
}

.user-alerts-table li.user-alert-header > * {
  border-bottom: 1px solid #DBE1E5;
  padding-bottom: 16px;
  font-weight: 700;
}

.user-alerts-table li::before {
  content: '';
  display: none !important;
}

.user-alerts-table li > * {
  display: table-cell;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
  font-size: 14px;
}

.user-alerts-table li > *:not(:first-child):not(:last-child) {
  padding-left: 16px;
  padding-right: 16px;
}

.user-alerts-table li > *:first-child {
  padding-right: 16px;
}

.user-alerts-table li > *:last-child {
  padding-left: 16px;
}

.user-alerts-table li > * .help-text-select {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.user-alerts-table li > * form {
  min-width: 90px;
}

.user-alerts-table li > * .alert-del-btn {
  display: flex;
  background: none;
  outline: none;
  border: 0;
  min-width: 36px;
  margin: 0;
  padding: 0;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.user-alerts-table li > * .alert-del-btn::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.4688 7.4375C17.8698 7.47396 18.0885 7.69271 18.125 8.09375C18.0885 8.49479 17.8698 8.71354 17.4688 8.75H17.1406L16.4844 17.6367C16.4479 18.0924 16.2656 18.4753 15.9375 18.7852C15.6094 19.0768 15.2174 19.2318 14.7617 19.25H9.23828C8.78255 19.2318 8.39062 19.0768 8.0625 18.7852C7.73438 18.4753 7.55208 18.0924 7.51562 17.6367L6.85938 8.75H6.53125C6.13021 8.71354 5.91146 8.49479 5.875 8.09375C5.91146 7.69271 6.13021 7.47396 6.53125 7.4375H8.44531L9.45703 5.93359C9.76693 5.49609 10.1862 5.26823 10.7148 5.25H13.2852C13.8138 5.26823 14.2422 5.49609 14.5703 5.93359L15.5547 7.4375H17.4688ZM10.7148 6.5625C10.6237 6.5625 10.569 6.59896 10.5508 6.67188L10.0312 7.4375H13.9961L13.4766 6.67188C13.4219 6.59896 13.3581 6.5625 13.2852 6.5625H10.7148ZM15.8281 8.75H8.17188L8.82812 17.5273C8.86458 17.7826 9.0013 17.9193 9.23828 17.9375H14.7617C14.9987 17.9193 15.1445 17.7826 15.1992 17.5273L15.8281 8.75Z" fill="rgb(156 163 174)" /></svg>');
}

.please_zoom {
  position: absolute;
  z-index: 99;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid #DBE1E5;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 12px;
}

.sitemap {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sitemap li {
  list-style: none;
  /* Видаляємо маркери */
  padding: 8px 12px;
  position: relative;
}

.sitemap li a {
  text-decoration: none;
  display: inline-block;
  transition: color 0.3s ease;
}

.sitemap li ul {
  list-style: none;
  /* Видаляємо стандартні маркери */
  padding-left: 20px;
  margin: 5px 0 0;
  border-left: 1px solid #DBE1E5;
}

.sitemap li::before {
  content: "";
  /* Кастомний маркер */
  position: absolute;
  left: -12px;
  font-size: 16px;
}

.padding-top-x1 {
  padding-top: 8px;
}

.padding-bottom-x1 {
  padding-bottom: 8px;
}

.padding-top-x2 {
  padding-top: 16px;
}

.padding-bottom-x2 {
  padding-bottom: 16px;
}

.padding-top-x3 {
  padding-top: 24px;
}

.padding-bottom-x3 {
  padding-bottom: 24px;
}

.padding-top-x4 {
  padding-top: 32px;
}

.padding-bottom-x4 {
  padding-bottom: 32px;
}

.padding-top-x6 {
  padding-top: 48px;
}

.padding-bottom-x6 {
  padding-bottom: 48px;
}

body[data-status="loaded"] .preloader {
  visibility: hidden !important;
  opacity: 0 !important;
}

body[data-status="loaded"] .preloader > div {
  opacity: 0;
}

@media screen and (min-width: 768px) {
  .checkbox:hover input:not[disabled] ~ div.mask,
  .radio:hover input:not[disabled] ~ div.mask {
    box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
  }
}

@media only screen and (max-width: 1439.9px), only screen and (max-device-width: 1439.9px) {
  .footer__content .resolution-wrap .logo-bg-decor {
    top: auto;
    bottom: 0;
    right: 50%;
    transform: translate(50%, 31px);
  }

  .section-theme-8 .logo-bg-decor {
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 1240px), only screen and (max-device-width: 1240px) {
  .section-theme-fees .prices-preview__item__image {
    min-width: 120px;
    width: 120px;
    height: 120px;
  }
}

@media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
  .slick-lightbox .slick-lightbox-slick-item-inner img {
    max-width: 100% !important;
  }

  .section-theme-fees .prices-preview {
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .section-theme-fees .prices-preview__item {
    width: 100%;
    max-width: 650px;
    gap: 16px;
    padding: 0;
  }

  .section-theme-fees .prices-preview__item:not(:last-child) {
    padding: 0 0 24px;
  }

  .section-theme-fees .prices-preview__item:nth-child(odd):not(:last-child) {
    border-right: none;
  }

  .section-theme-fees .prices-preview__item:not(:nth-last-child(-n+2)) {
    border-bottom: none;
  }

  .section-theme-fees .prices-preview__item__image {
    min-width: 180px;
    width: 180px;
    height: 180px;
  }
}

@media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
  .snake-blocks-4 .about-steps {
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
  }

  .snake-blocks-4 .about-steps__item {
    flex-direction: row;
    max-width: 460px;
    text-align: left;
  }

  .snake-blocks-4 .about-steps__item:first-child::before,
  .snake-blocks-4 .about-steps__item:last-child::before {
    display: none;
  }

  .snake-blocks-4 .about-steps__item__image {
    width: 120px;
    min-width: 130px;
    height: 130px;
    position: relative;
    top: -10px;
    padding: 0;
    background: none;
  }

  .snake-blocks-4 .about-steps__item__content {
    gap: 4px;
  }
}

@media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
  .main-services {
    gap: calc(8px * 2);
  }

  .main-services__item {
    padding: calc(8px * 2);
    gap: calc(8px * 2);
  }

  .main-services__item__image svg,
  .main-services__item__image img {
    width: 120px;
    height: calc(120px / 1.45);
  }

  .snake-blocks-3__section__content-image svg,
  .snake-blocks-3__section__content-image img {
    width: 320px;
    min-width: 320px;
  }

  .section-contact .resolution-wrap {
    max-width: 420px;
  }

  .section-contact .contact-section .section-sub-title > * {
    font-size: 18px;
  }

  .section-contact .contact-section {
    flex-wrap: wrap;
  }

  .section-contact .contact-section__info .contacts-block {
    flex-wrap: wrap;
  }

  .section-contact .contact-section__info {
    flex: 1 0 288px;
  }

  .section-contact .contact-section__form {
    flex: 1 0 288px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 859.9px), only screen and (max-device-width: 859.9px) {
  .street-cleaning-block__open-close {
    display: flex;
    background: #fff;
    width: 40px;
    height: 40px;
    position: absolute;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 100%;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
    transition: all 0.14s linear;
    cursor: pointer;
  }

  .street-cleaning-block__open-close * {
    fill: #59616D !important;
    width: 24px;
    height: 24px;
  }

  .street-cleaning-block__open-close [class*='icon-open'] {
    display: block;
  }

  .street-cleaning-block__open-close [class*='icon-close'] {
    display: none;
  }

  .street-cleaning-block__open-close.active [class*='icon-open'] {
    display: none;
  }

  .street-cleaning-block__open-close.active [class*='icon-close'] {
    display: block;
  }

  .street-cleaning-block__open-close.active {
    background: #F0F2F6;
    box-shadow: none;
    left: 250px;
  }

  .street-cleaning-block__open-close.active ~ .left-block {
    max-width: 280px;
    max-height: none;
    border-radius: 8px;
    opacity: 1;
    pointer-events: all;
    transform: scaleY(1);
  }

  .left-block {
    max-width: 40px;
    max-height: 40px;
    border-radius: 25px;
    opacity: 0;
    pointer-events: none;
    transform: scaleY(0.1);
  }
}

@media only screen and (max-width: 859.9px) and (hover: hover) and (pointer: fine), only screen and (max-device-width: 859.9px) and (hover: hover) and (pointer: fine) {
  .street-cleaning-block__open-close:not(.active):hover {
    background: #29b1ff;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
  }

  .street-cleaning-block__open-close:not(.active):hover * {
    fill: #fff !important;
  }
}

@media only screen and (max-width: 848px), only screen and (max-device-width: 848px) {
  .footer-nav__column {
    width: calc(50% - 8px*2);
  }
}

@media only screen and (max-width: 840px), only screen and (max-device-width: 840px) {
  .btn-big svg {
    width: 18px;
    height: 18px;
  }

  .btn-big {
    font-size: 14px;
    padding: 2px calc(8px*2) 0;
  }

  .btn-big::before {
    transform: scale(0.85) translateY(1px);
  }

  .btn-small svg {
    width: 20px;
    height: 20px;
  }

  .section-theme-5 .prices-preview {
    width: calc(100% + 8px * 6);
    position: relative;
    left: -24px;
    padding-left: 24px;
    padding-right: 24px;
    /* Плавний ефект при утриманні курсора */
  }

  .section-theme-5 .prices-preview:active {
    cursor: grab !important;
  }

  .section-theme-5 .prices-preview:hover {
    cursor: grab !important;
  }

  .section-theme-5 .prices-preview:active {
    cursor: grabbing !important;
  }
}

@media only screen and (max-width: 767.9px), only screen and (max-device-width: 767.9px) {
  .text-box p,
  .text-wrap p {
    font-size: 14px;
  }

  .accordion__theme-one .accordion__item .accordion__item__title > * {
    font: 600 16px/120% 'Roboto', Helvetica, Arial, sans-serif !important;
  }

  .accordion__theme-one .accordion__item .accordion__item__content > * {
    font: 400 14px/130% 'Roboto', Helvetica, Arial, sans-serif !important;
  }

  .footer__content__more {
    flex-direction: column;
  }

  .footer__content__more > * {
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    align-content: center;
  }

  .footer__copyright .resolution-wrap {
    padding-left: 8px;
    padding-right: 8px;
  }

  .footer__copyright__col-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    min-width: 32px;
    min-height: 32px;
    position: absolute;
    bottom: 8px;
    right: 10px;
    padding-top: 0;
    padding-bottom: 0;
    transition: all 0.1s linear;
    gap: 0;
    border-radius: 8px;
    cursor: pointer;
  }

  .footer__copyright__col-menu::after {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    width: 32px;
    height: 24px;
    justify-content: center;
    align-items: center;
    align-content: center;
    content: url('data:image/svg+xml;charset=utf-8,<svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.875 7.625C5.91146 7.22396 6.13021 7.00521 6.53125 6.96875H17.4688C17.8698 7.00521 18.0885 7.22396 18.125 7.625C18.0885 8.02604 17.8698 8.24479 17.4688 8.28125H6.53125C6.13021 8.24479 5.91146 8.02604 5.875 7.625ZM5.875 12C5.91146 11.599 6.13021 11.3802 6.53125 11.3438H17.4688C17.8698 11.3802 18.0885 11.599 18.125 12C18.0885 12.401 17.8698 12.6198 17.4688 12.6562H6.53125C6.13021 12.6198 5.91146 12.401 5.875 12ZM17.4688 17.0312H6.53125C6.13021 16.9948 5.91146 16.776 5.875 16.375C5.91146 15.974 6.13021 15.7552 6.53125 15.7188H17.4688C17.8698 15.7552 18.0885 15.974 18.125 16.375C18.0885 16.776 17.8698 16.9948 17.4688 17.0312Z" fill="rgb(89 97 109)"/></svg>');
  }

  .footer__copyright__col-menu > a {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    text-align: right;
    padding: 8px 24px;
    min-height: 24px;
    display: none;
  }

  .footer__copyright__col-menu.active {
    padding-top: 10px;
    padding-bottom: 32px;
    background: #242e34;
  }

  .footer__copyright__col-menu.active > a {
    display: flex;
  }

  .footer__copyright__col-menu.active::after {
    right: 16px;
    content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0078 10.9453L17.7266 5.22656C18.0807 4.9349 18.4245 4.9349 18.7578 5.22656C19.0703 5.58073 19.0703 5.9349 18.7578 6.28906L13.0703 12.0078L18.7578 17.7266C19.0703 18.0807 19.0703 18.4245 18.7578 18.7578C18.4245 19.0703 18.0807 19.0703 17.7266 18.7578L12.0078 13.0703L6.28906 18.7578C5.9349 19.0703 5.58073 19.0703 5.22656 18.7578C4.9349 18.4245 4.9349 18.0807 5.22656 17.7266L10.9453 12.0078L5.22656 6.28906C4.9349 5.9349 4.9349 5.58073 5.22656 5.22656C5.58073 4.9349 5.9349 4.9349 6.28906 5.22656L12.0078 10.9453Z" fill="rgb(89 97 109)"/></svg>');
  }

  .page-header__title > * {
    font-size: 38px;
  }

  .section-title > * {
    font-size: 28px;
  }

  .section-sub-title > * {
    font-size: 14px;
  }

  .content-with-sidebar .resolution-wrap > *:not(.content-with-sidebar__sidebar) {
    flex: 1 1 auto;
    width: 100%;
  }

  .content-with-sidebar .resolution-wrap > *:not(.content-with-sidebar__sidebar) .section-title > * {
    font-size: 38px;
  }

  .content-with-sidebar .resolution-wrap .content-with-sidebar__sidebar {
    top: 0;
    flex: 1 1 auto;
    width: 100%;
    max-width: none;
    min-width: auto;
    position: relative;
  }

  .content-with-sidebar .resolution-wrap .content-with-sidebar__sidebar__box {
    width: 100%;
  }

  .content-with-sidebar .resolution-wrap .content-with-sidebar__sidebar__box .sidebar-title {
    margin-bottom: 16px;
    border-bottom: none;
  }

  .landing-main-banner .content-wrapper {
    max-width: 50% !important;
  }

  .landing-main-banner__content__image .dmv-partners__item:nth-child(1) {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
  }

  .landing-main-banner__content__image .dmv-partners__item:nth-child(2) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  .landing-main-banner__content__image {
    width: 620px !important;
    height: calc(620px / 1.4) !important;
    transform: translate(-50%, -16%);
  }

  .landing-main-banner__content__title > h1 {
    font-size: 38px;
  }

  .landing-main-banner__content .select-state__description {
    display: none;
  }

  .landing-main-banner .wave {
    height: 120px;
  }

  .snake-blocks {
    padding-top: calc(8px * 3);
  }

  .snake-blocks__section__content-image svg,
  .snake-blocks__section__content-image img {
    width: 260px;
    min-width: 260px;
  }

  .snake-blocks__section__content-description .description-title > * {
    font-size: 22px;
  }

  .snake-blocks__section__content-description .description-text p {
    font-size: 14px;
  }

  .snake-blocks__section__content-description .description-btn {
    width: 100%;
    justify-content: flex-end;
  }

  .snake-blocks__section__content-description .description-btn a {
    margin-right: 0;
  }

  .snake-blocks__section__content {
    flex-direction: column;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .snake-blocks__section:not(.section-title):nth-child(2n+1):not(:only-child) .snake-blocks__section__content {
    flex-direction: column;
  }

  .snake-blocks-2 {
    padding-top: calc(8px * 3);
  }

  .snake-blocks-2__section__content-description .description-title h3 {
    font-size: 22px;
  }

  .snake-blocks-2__section__content-description .description-text p {
    font-size: 14px;
  }

  .snake-blocks-4 .about-steps__item__content .description-title h3 {
    font-size: 18px;
  }

  .snake-blocks-4 .about-steps__item__content .description-text p {
    font-size: 14px;
  }

  .snake-blocks-3 {
    padding-top: calc(8px * 3);
  }

  .snake-blocks-3__section__content-image svg,
  .snake-blocks-3__section__content-image img {
    width: 260px;
    min-width: 260px;
  }

  .snake-blocks-3__section__content-description .description-title > * {
    font-size: 22px;
  }

  .snake-blocks-3__section__content-description .description-text p {
    font-size: 14px;
  }

  .snake-blocks-3__section__content-description .description-btn {
    width: 100%;
    justify-content: flex-end;
  }

  .snake-blocks-3__section__content-description .description-btn a {
    margin-right: 0;
  }

  .snake-blocks-3__section__content {
    flex-direction: column;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .snake-blocks-3__section:nth-child(2n + 1):not(:only-child) .snake-blocks-3__section__content {
    flex-direction: column;
  }

  .section-theme-9 {
    padding-top: calc(8px * 4);
    padding-bottom: calc(8px * 4);
  }

  .section-theme-9 .resolution-wrap {
    max-width: 380px;
  }

  .section-theme-9 .columns-wrap {
    flex-direction: column;
    gap: 48px;
  }

  .section-theme-9 .columns-wrap__item {
    justify-content: center;
    width: auto;
    display: inline-flex;
    text-align: center;
  }

  .section-theme-9 .columns-wrap__item__text {
    display: inline-flex;
  }

  .section-theme-4 {
    padding-top: calc(8px * 4);
    padding-bottom: calc(8px * 4);
  }

  .section-theme-5 {
    padding-top: calc(8px * 4);
    padding-bottom: calc(8px * 4);
  }

  .section-theme-fees {
    padding-top: calc(8px * 4);
    padding-bottom: calc(8px * 4);
  }

  .section-theme-fees .prices-preview__item__description > h3 {
    font-size: 18px !important;
  }

  .section-theme-fees .prices-preview__item__description ul li p {
    font-size: 14px;
  }

  .section-theme-fees .prices-preview__item__description ul li strong {
    font-size: 16px;
  }

  .section-theme-6 {
    padding-top: calc(8px * 4);
    padding-bottom: calc(8px * 4);
  }

  .section-theme-7 {
    padding-top: calc(8px * 4);
    padding-bottom: calc(8px * 4);
  }

  .section-theme-8 {
    padding-top: calc(8px * 4);
    padding-bottom: calc(8px * 4);
  }

  .section-theme-8 .resolution-wrap {
    max-width: 380px;
  }

  .section-theme-10 .columns-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  .section-theme-10 .columns-wrap__item {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .section-theme-10 .columns-wrap__item__number::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .locations-list {
    gap: 8px;
  }

  .locations-list__item {
    flex: 0 1 calc(33.33% - 8px);
  }

  .locations-list__item {
    flex: 0 1 100%;
    max-width: calc(50% - 8px);
  }

  .section-profile-form__content__image {
    position: absolute;
    opacity: 0;
  }

  .section-profile-form__content__form {
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-self: stretch;
    align-self: stretch;
  }

  .section-profile-form__content__form .mw-form {
    display: block;
    padding: 24px;
    background: #fff;
    border: 1px solid #F0F2F6;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1), 40px 30px 40px -4px rgba(0, 0, 0, 0.06);
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 767.9px) and (hover: hover) and (pointer: fine), only screen and (max-device-width: 767.9px) and (hover: hover) and (pointer: fine) {
  .footer__copyright__col-menu > a:hover {
    background: #2E383E;
  }
}

@media only screen and (max-width: 740px), only screen and (max-device-width: 740px) {
  .in-video-wrap .video__button {
    width: 60px;
    height: 60px;
    opacity: 1;
    box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
  }

  .in-video-wrap {
    border-radius: 8px;
  }
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
  .landing-main-banner .resolution-wrap {
    flex-direction: column;
  }

  .landing-main-banner.with-form .content-wrapper {
    text-align: center;
    max-width: none !important;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .main-services {
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }

  .main-services__item {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 8px calc(8px * 2);
  }

  .main-services__item__image svg,
  .main-services__item__image img {
    width: 80px;
    height: calc(80px / 1.45);
  }

  .main-services__item__description {
    text-align: left;
    margin-bottom: 0;
    flex: 1 1 auto;
  }

  .main-services__item__description h2 {
    display: flex;
  }

  .main-services__item__description p {
    display: none;
  }

  .main-services__item__button {
    width: auto;
    margin-top: 0;
    order: 5;
    margin-bottom: 0;
  }

  .main-services__item__button > * {
    min-width: auto !important;
    width: 50px !important;
    max-width: 50px;
    height: 50px !important;
    border-radius: 25px;
    padding: 0 !important;
    flex: 0 0 auto;
  }

  .main-services__item__button > * span {
    display: none;
  }

  .main-services__item__button > * svg {
    display: block;
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
  .snake-blocks-4 .about-steps__item {
    gap: 8px;
  }

  .section-theme-fees .prices-preview__item__image {
    min-width: 110px;
    width: 110px;
    height: 110px;
  }
}

@media only screen and (max-width: 599.9px), only screen and (max-device-width: 599.9px) {
  .text-box h1,
  .text-wrap h1 {
    font-size: 38px;
  }

  .text-box h2,
  .text-wrap h2 {
    font-size: 28px;
  }

  .text-box h3,
  .text-wrap h3 {
    font-size: 22px;
  }

  .text-box h4,
  .text-wrap h4 {
    font-size: 18px;
  }

  .text-box h5,
  .text-wrap h5 {
    font-size: 16px;
  }

  .popup-content__title {
    font-size: 22px;
  }

  .popup-content__item p {
    font-size: 14px;
  }

  .landing-main-banner .welcome-slider .landing-main-banner__content__image {
    width: 620px;
    height: calc(620px / 1.4);
    transform: translate(-40%, -50%);
  }

  .landing-main-banner .content-wrapper {
    max-width: 70% !important;
  }

  .landing-main-banner__content.image-car .landing-main-banner__content__image {
    width: 520px;
    height: calc(420px / 1.4);
    transform: translate(-40%, -16%);
  }

  .landing-main-banner__content__image .dmv-partners {
    flex-direction: row;
    top: auto;
    left: 24px;
    bottom: 60px;
  }

  .landing-main-banner__content__image .dmv-partners__item:nth-child(1) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  .landing-main-banner__content__image {
    width: 320px !important;
    height: calc(320px / 1.4) !important;
    transform: translate(-40%, -14%);
  }

  .landing-main-banner__content__title > h1 {
    font-size: 28px;
  }

  .landing-main-banner__content__description > p {
    font-size: 14px;
  }

  .landing-main-banner__content .select-state {
    padding: 16px 24px;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
  }

  .landing-main-banner__content .select-state form {
    flex-direction: column;
    gap: 8px;
  }

  .landing-main-banner__content .select-state form .input-item {
    width: 100%;
  }

  .landing-main-banner__content .select-state form .input-item > * {
    width: 100%;
  }

  .landing-main-banner__content .select-state__icon {
    left: calc(-8px * 3);
    top: 8px;
  }

  .snake-blocks-2__section .resolution-wrap {
    max-width: 420px;
  }

  .snake-blocks-2__section .resolution-wrap::before {
    font-size: 160px;
  }

  .snake-blocks-2__section__content {
    width: calc(100% - 72px);
  }

  .section-theme-3 {
    padding-top: calc(8px * 4);
    padding-bottom: calc(8px * 4);
  }

  .section-theme-3 .resolution-wrap {
    max-width: 380px;
  }

  .section-theme-3 .columns-wrap {
    flex-direction: column;
    gap: 48px;
  }

  .section-theme-3 .columns-wrap__item {
    flex-direction: row;
    justify-content: center;
    width: auto;
    display: inline-flex;
  }

  .section-theme-3 .columns-wrap__item:nth-child(2n+1) {
    margin-right: auto;
  }

  .section-theme-3 .columns-wrap__item:nth-child(2n+2) {
    margin-left: auto;
  }

  .section-theme-3 .columns-wrap__item:nth-child(2n+2) .columns-wrap__item__icon {
    order: 3;
  }

  .section-theme-3 .columns-wrap__item:nth-child(2n+2) .columns-wrap__item__text {
    text-align: right;
  }

  .section-theme-3 .columns-wrap__item__icon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
  }

  .section-theme-3 .columns-wrap__item__icon svg {
    width: 32px;
    height: 32px;
    fill: #59616D;
  }

  .section-theme-3 .columns-wrap__item__text {
    text-align: left;
    display: inline-flex;
  }

  .locations-list {
    row-gap: 2px;
  }

  .locations-list__item {
    max-width: 100%;
  }
}

@media only screen and (max-width: 596px), only screen and (max-device-width: 596px) {
  .section-theme-5 .prices-preview__item {
    flex: 1 0 320px;
  }
}

@media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
  .search-on-map .input-item-group > .input-item .btn-big,
  .sc-alert-subscribe .input-item-group > .input-item .btn-big {
    min-width: unset;
  }

  .search-on-map .input-item-group > .input-item .btn-big svg,
  .sc-alert-subscribe .input-item-group > .input-item .btn-big svg {
    display: flex;
    width: 32px;
    height: 32px;
  }

  .search-on-map .input-item-group > .input-item .btn-big span,
  .sc-alert-subscribe .input-item-group > .input-item .btn-big span {
    display: none;
  }
}

@media only screen and (max-width: 540px), only screen and (max-device-width: 540px) {
  .text-box .numeric-hn > span,
  .text-wrap .numeric-hn > span {
    font: 600 20px/120% 'Roboto', Helvetica, Arial, sans-serif !important;
  }

  .main-services__item__description h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 520px), only screen and (max-device-width: 520px) {
  .footer-nav__column {
    width: 100%;
  }
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  #go-top {
    bottom: 85px;
  }

  .cookie-banner {
    max-width: none;
    width: calc(100% - 8px*4);
    bottom: 114px;
  }

  .cookie-banner header h4 {
    font-size: 18px;
  }

  .reviews__item__head {
    flex-wrap: wrap;
    gap: 0;
  }

  .reviews__item__head time {
    width: 100%;
    order: 99;
  }

  .reviews__item__body > * {
    font-size: 14px;
  }

  .section-theme-fees .prices-preview__item__image {
    min-width: 90px;
    width: 90px;
    height: 90px;
  }
}

@media only screen and (max-width: 460px), only screen and (max-device-width: 460px) {
  .header-search .form-theme .input-item .input-mask-wrap.search-wrap::before {
    display: none;
  }

  .header-search .form-theme .input-item .input-mask-wrap.search-wrap .placeholder-mask {
    padding-left: 16px !important;
  }

  .header-search .form-theme .input-item .input-mask-wrap.search-wrap input {
    padding-left: 16px !important;
  }

  .header-search .form-theme .input-item button.btn_neighborhoods {
    right: 2px;
  }
}

@media only screen and (max-width: 440px), only screen and (max-device-width: 440px) {
  .section-theme-5 .prices-preview__item {
    flex: 1 0 280px;
  }
}

@media only screen and (max-width: 420px), only screen and (max-device-width: 420px) {
  .main-services__item__image svg,
  .main-services__item__image img {
    width: 60px;
    height: calc(60px / 1.45);
  }

  .main-services__item__description h2 {
    font-size: 16px;
  }

  .partnership__item {
    flex: 1 1 280px;
  }

  .section-profile-form__content__form .mw-form {
    padding: 0;
    border: none;
    box-shadow: none;
  }
}

@media only screen and (max-width: 380px), only screen and (max-device-width: 380px) {
  .section-theme-5 .prices-preview__item {
    flex: 1 0 240px;
  }
}

@media (min-width: 280px) and (max-width: 420px) {
  .mobile-menu-component__main-tel {
    display: none !important;
  }
}

@media (min-width: 280px) and (max-width: 520px) {
  .mobile-menu-component__main-soc-networks {
    display: none !important;
  }
}

@media (min-width: 960px) and (max-width: 4200px) {
  body[data-pages-type='parking-page'] .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 10;
    width: calc(100% + 2px);
    max-width: 4200px;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    background: none;
  }

  body[data-pages-type='parking-page'] .header .menu-burger {
    display: flex;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 3;
    transform: translateY(calc(-50% - 2px));
  }

  body[data-pages-type='parking-page'] .header .menu-burger::before,
  body[data-pages-type='parking-page'] .header .menu-burger i,
  body[data-pages-type='parking-page'] .header .menu-burger::after {
    content: '';
    display: block;
    width: 60%;
    height: 2px;
    background-color: #59616D;
    position: absolute;
    transform-origin: 0 50%;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header .menu-burger::before {
    top: 30%;
  }

  body[data-pages-type='parking-page'] .header .menu-burger i {
    top: 50%;
  }

  body[data-pages-type='parking-page'] .header .menu-burger::after {
    top: 70%;
  }

  body[data-pages-type='parking-page'] .header .resolution-wrap {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    max-width: none;
    transition: all 0.2s linear;
    padding-left: calc(8px*2);
    padding-right: calc(8px*2);
  }

  body[data-pages-type='parking-page'] .header__main {
    height: 60px;
    max-height: 60px;
  }

  body[data-pages-type='parking-page'] .header__main .logo {
    transition: all 0.2s linear;
    position: relative;
    margin-right: calc(8px * 2);
  }

  body[data-pages-type='parking-page'] .header__main .logo a {
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header__main .logo a svg,
  body[data-pages-type='parking-page'] .header__main .logo a img {
    width: 120px;
    height: calc(120px / 3.12);
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header__main-tel {
    display: none;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-right: calc(8px * 2);
  }

  body[data-pages-type='parking-page'] .header__main-tel a {
    text-decoration: none;
    color: #2E383E;
  }

  body[data-pages-type='parking-page'] .header__main-tel .more-icon {
    margin-left: 4px;
  }

  body[data-pages-type='parking-page'] .header__main-tel .more-icon svg {
    width: 16px;
    height: 16px;
    fill: #9CA3AE;
  }

  body[data-pages-type='parking-page'] .header__main-soc-networks {
    display: flex;
    gap: 4px;
  }

  body[data-pages-type='parking-page'] .header__main-soc-networks a {
    text-decoration: none;
    display: flex;
  }

  body[data-pages-type='parking-page'] .header__main-soc-networks a svg {
    width: 24px;
    height: 24px;
    fill: #9CA3AE;
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header__main__nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 420px;
    min-width: 300px;
    margin-left: auto;
    position: absolute;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
    backdrop-filter: blur(0);
    top: 0;
    right: 0;
    height: var(--app-height);
    transition: all 0.2s linear;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
    padding-top: 60px;
    overflow: hidden;
    overflow-y: auto;
  }

  body[data-pages-type='parking-page'] .header__main__nav .menu-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  body[data-pages-type='parking-page'] .header__main__nav .menu-list__item,
  body[data-pages-type='parking-page'] .header__main__nav .menu-list a {
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #2E383E;
    text-decoration: none;
    font: 400 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
    display: flex;
    padding: calc(8px * 2);
    text-align: center;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.03);
  }

  body[data-pages-type='parking-page'] .header__main__nav .menu-list__item::after,
  body[data-pages-type='parking-page'] .header__main__nav .menu-list a::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    transition: all 0.2s linear;
    width: 6px;
    height: 100%;
    background: #159DF1;
    border-radius: 0 4px 4px 0;
    opacity: 0;
  }

  body[data-pages-type='parking-page'] .header__main__nav .menu-list__item.active::after,
  body[data-pages-type='parking-page'] .header__main__nav .menu-list a.active::after {
    content: '';
    scale: 1;
    opacity: 1;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 calc(8px * 2);
    margin-bottom: calc(8px * 3);
    flex-wrap: wrap;
    margin-top: auto;
    order: 99;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools svg {
    display: none;
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language {
    order: 99;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 0 24px;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box__wrap > * {
    flex: 0 0 auto;
    display: flex;
    padding: 8px 0;
    text-decoration: none;
    color: #2E383E;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box__wrap > *.lang-hint {
    color: #9CA3AE;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box__wrap > *.active {
    font-weight: 700;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: #F0F2F6;
    border-radius: 8px;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block__box {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block__box__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 0 24px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block__box__wrap > *:not(a) {
    display: flex;
    padding: 8px 0;
    color: #2E383E;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block__box__wrap > *:not(a).profile-name {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-width: 100%;
    margin-left: auto;
    flex: 1 1 auto;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
  }

  body[data-pages-type='parking-page'] .header.menu-active .menu-burger::before {
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-50%, calc(-50% + 1px));
  }

  body[data-pages-type='parking-page'] .header.menu-active .menu-burger i {
    left: 70%;
    opacity: 0;
  }

  body[data-pages-type='parking-page'] .header.menu-active .menu-burger::after {
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translate(-50%, calc(50% - 1px));
  }

  body[data-pages-type='parking-page'] .header.menu-active .menu-burger::before,
  body[data-pages-type='parking-page'] .header.menu-active .menu-burger i,
  body[data-pages-type='parking-page'] .header.menu-active .menu-burger::after {
    width: 70% !important;
  }

  body[data-pages-type='parking-page'] .header.menu-active .header__main__nav {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  body[data-pages-type='parking-page'] .header.header__small {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    border-bottom: 1px solid #DBE1E5;
  }

  body[data-pages-type='parking-page'] .header.header__small .logo a svg,
  body[data-pages-type='parking-page'] .header.header__small .logo a img {
    width: 110px;
    height: calc(110px / 2.729);
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header.header__small .header__main {
    height: 60px;
  }

  body[data-pages-type='parking-page'] .header__main {
    height: 80px;
    max-height: 80px;
  }

  body[data-pages-type='parking-page'][data-page="main"] .header {
    background: none;
  }

  body[data-pages-type='parking-page'][data-page="main"] .header.header__small {
    background: rgba(255, 255, 255, 0.9);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 10;
    width: calc(100% + 2px);
    max-width: 4200px;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    background: none;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger {
    display: none;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .resolution-wrap {
    display: flex;
    max-width: none;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    position: relative;
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main {
    height: 80px;
    max-height: 80px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main .logo {
    transition: all 0.2s linear;
    position: relative;
    margin-right: calc(8px * 2);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main .logo a {
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main .logo a svg,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main .logo a img {
    width: 131px;
    height: calc(131px / 2.729);
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-right: calc(8px * 2);
    position: relative;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel a {
    text-decoration: none;
    color: #2E383E;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel .more-icon {
    margin-left: 4px;
    position: relative;
    z-index: 2;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel .more-icon svg {
    width: 16px;
    height: 16px;
    fill: #9CA3AE;
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks {
    display: flex;
    gap: 4px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks a {
    text-decoration: none;
    display: flex;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks a svg {
    width: 24px;
    height: 24px;
    fill: #9CA3AE;
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav {
    display: flex;
    margin-left: auto;
    gap: calc(8px * 3);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list {
    display: flex;
    gap: calc(8px * 3);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list__item {
    position: relative;
    color: #2E383E;
    text-decoration: none;
    font: 400 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
    text-transform: unset;
    display: flex;
    padding: calc(8px * 2) 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 80px !important;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list__item::after {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    transition: all 0.2s linear;
    top: 0;
    width: 1%;
    height: 3px;
    background: #159DF1;
    opacity: 0;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list__item.active::after {
    content: '';
    width: 100%;
    opacity: 1;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    align-content: center;
    order: 99;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools svg {
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language {
    display: flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language__button {
    display: inline-flex;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language__button svg {
    transition: all 0.2s linear;
    width: 24px;
    height: 24px;
    fill: #59616D;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language .lang-hint {
    display: none;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block {
    display: flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block__button {
    display: inline-flex;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block__button svg {
    transition: all 0.2s linear;
    width: 24px;
    height: 24px;
    fill: #59616D;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    border-bottom: 1px solid #DBE1E5;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small .logo a svg,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small .logo a img {
    width: 110px;
    height: calc(110px / 2.729);
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small .header__main {
    height: 60px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small .header__main__nav .menu-list {
    display: flex;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small .header__main__nav .menu-list__item {
    font: 400 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
    min-height: 60px !important;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu__box {
    position: absolute;
    display: flex !important;
    top: 32px;
    right: -8px;
    box-shadow: 2px 9px 23px -5px rgba(42, 64, 105, 0.05), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
    opacity: 0;
    pointer-events: none;
    transform: scaleY(0.8);
    transition: all 0.1s ease-in-out;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu__box::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 1px;
    transform: rotate(-45deg);
    border-top: 1px solid #F0F2F6;
    border-right: 1px solid #F0F2F6;
    pointer-events: none;
    background: #fff;
    position: absolute;
    top: -4px;
    right: 16px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu__box__wrap {
    display: flex;
    border: 1px solid #F0F2F6;
    background: #fff;
    flex-direction: column;
    border-radius: 8px;
    padding: calc(8px / 2);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu__box__wrap > * {
    padding: 8px 16px;
    color: #2E383E;
    text-decoration: none;
    white-space: nowrap;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu__box__wrap > *.active,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu__box__wrap > *.profile-name {
    font-weight: 700 !important;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu__box__wrap > *:not(:last-child) {
    border-bottom: 1px solid #F0F2F6;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu.open .dropdown-menu__btn svg {
    fill: #159DF1;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu.open .dropdown-menu__box {
    opacity: 1;
    pointer-events: all;
    transform: scaleY(1);
  }

  body[data-page='main'] .dynamic-padding,
  body[data-page='examples-page'] .dynamic-padding,
  body[data-page='parking'] .dynamic-padding,
  body[data-page='dmv-services'] .dynamic-padding {
    padding-top: 0;
  }

  body:not([data-page=main]):not([data-page=examples-page]):not([data-page=parking]):not([data-page=dmv-services]) .dynamic-padding {
    padding-top: 80px;
  }

  body .landing-main-banner {
    padding-top: calc(80px + calc(8px * 4));
  }

  .map-wrapper {
    padding-top: 80px;
    padding-bottom: 48px;
  }

  .street-cleaning-block__open-close {
    top: 90px;
  }

  .street-cleaning-block__open-close.active {
    top: 94px;
  }

  .left-block {
    top: 90px;
  }

  .geolocation-btn {
    bottom: 186px;
  }

  .tabs__content.active {
    max-height: calc(var(--app-height) - 282px);
  }

  .banner-on-map {
    top: calc(80px + 10px);
  }
}

@media (min-width: 960px) and (max-width: 4200px) and (hover: hover) and (pointer: fine) {
  body[data-pages-type='parking-page'] .header .menu-burger:hover {
    cursor: pointer;
  }

  body[data-pages-type='parking-page'] .header .menu-burger:hover::before,
  body[data-pages-type='parking-page'] .header .menu-burger:hover i,
  body[data-pages-type='parking-page'] .header .menu-burger:hover::after {
    background-color: #159DF1;
  }

  body[data-pages-type='parking-page'] .header__main-soc-networks a:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel .more-icon:hover {
    cursor: pointer;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel .more-icon:hover svg {
    transform: scale(1.2);
    fill: #29b1ff;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks a:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list__item:not(.active):hover {
    color: #29b1ff;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language__button:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
    cursor: pointer;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block__button:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
    cursor: pointer;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .dropdown-menu__box__wrap > *:not(.active):not(.profile-name):hover {
    color: #29b1ff;
    cursor: pointer;
  }
}

@media (min-width: 960px) and (max-width: 4200px) and (min-width: 280px) and (max-width: 420px) {
  body[data-pages-type='parking-page'] .header__main-tel {
    display: none !important;
  }
}

@media (min-width: 960px) and (max-width: 4200px) and (min-width: 280px) and (max-width: 520px) {
  body[data-pages-type='parking-page'] .header__main-soc-networks {
    display: none !important;
  }
}

@media (min-width: 280px) and (max-width: 959.98px) {
  body[data-pages-type='parking-page'] .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 10;
    width: calc(100% + 2px);
    max-width: 4200px;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    background: none;
  }

  body[data-pages-type='parking-page'] .header .menu-burger {
    display: flex;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 3;
    transform: translateY(calc(-50% - 2px));
  }

  body[data-pages-type='parking-page'] .header .menu-burger::before,
  body[data-pages-type='parking-page'] .header .menu-burger i,
  body[data-pages-type='parking-page'] .header .menu-burger::after {
    content: '';
    display: block;
    width: 60%;
    height: 2px;
    background-color: #59616D;
    position: absolute;
    transform-origin: 0 50%;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header .menu-burger::before {
    top: 30%;
  }

  body[data-pages-type='parking-page'] .header .menu-burger i {
    top: 50%;
  }

  body[data-pages-type='parking-page'] .header .menu-burger::after {
    top: 70%;
  }

  body[data-pages-type='parking-page'] .header .resolution-wrap {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    max-width: none;
    transition: all 0.2s linear;
    padding-left: calc(8px*2);
    padding-right: calc(8px*2);
  }

  body[data-pages-type='parking-page'] .header__main {
    height: 60px;
    max-height: 60px;
  }

  body[data-pages-type='parking-page'] .header__main .logo {
    transition: all 0.2s linear;
    position: relative;
    margin-right: calc(8px * 2);
  }

  body[data-pages-type='parking-page'] .header__main .logo a {
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header__main .logo a svg,
  body[data-pages-type='parking-page'] .header__main .logo a img {
    width: 120px;
    height: calc(120px / 3.12);
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header__main-tel {
    display: none;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-right: calc(8px * 2);
  }

  body[data-pages-type='parking-page'] .header__main-tel a {
    text-decoration: none;
    color: #2E383E;
  }

  body[data-pages-type='parking-page'] .header__main-tel .more-icon {
    margin-left: 4px;
  }

  body[data-pages-type='parking-page'] .header__main-tel .more-icon svg {
    width: 16px;
    height: 16px;
    fill: #9CA3AE;
  }

  body[data-pages-type='parking-page'] .header__main-soc-networks {
    display: flex;
    gap: 4px;
  }

  body[data-pages-type='parking-page'] .header__main-soc-networks a {
    text-decoration: none;
    display: flex;
  }

  body[data-pages-type='parking-page'] .header__main-soc-networks a svg {
    width: 24px;
    height: 24px;
    fill: #9CA3AE;
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header__main__nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 420px;
    min-width: 300px;
    margin-left: auto;
    position: absolute;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
    backdrop-filter: blur(0);
    top: 0;
    right: 0;
    height: var(--app-height);
    transition: all 0.2s linear;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
    padding-top: 60px;
    overflow: hidden;
    overflow-y: auto;
  }

  body[data-pages-type='parking-page'] .header__main__nav .menu-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  body[data-pages-type='parking-page'] .header__main__nav .menu-list__item,
  body[data-pages-type='parking-page'] .header__main__nav .menu-list a {
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #2E383E;
    text-decoration: none;
    font: 400 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
    display: flex;
    padding: calc(8px * 2);
    text-align: center;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.03);
  }

  body[data-pages-type='parking-page'] .header__main__nav .menu-list__item::after,
  body[data-pages-type='parking-page'] .header__main__nav .menu-list a::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    transition: all 0.2s linear;
    width: 6px;
    height: 100%;
    background: #159DF1;
    border-radius: 0 4px 4px 0;
    opacity: 0;
  }

  body[data-pages-type='parking-page'] .header__main__nav .menu-list__item.active::after,
  body[data-pages-type='parking-page'] .header__main__nav .menu-list a.active::after {
    content: '';
    scale: 1;
    opacity: 1;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 calc(8px * 2);
    margin-bottom: calc(8px * 3);
    flex-wrap: wrap;
    margin-top: auto;
    order: 99;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools svg {
    display: none;
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language {
    order: 99;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 0 24px;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box__wrap > * {
    flex: 0 0 auto;
    display: flex;
    padding: 8px 0;
    text-decoration: none;
    color: #2E383E;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box__wrap > *.lang-hint {
    color: #9CA3AE;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .language__box__wrap > *.active {
    font-weight: 700;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: #F0F2F6;
    border-radius: 8px;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block__box {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block__box__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 0 24px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block__box__wrap > *:not(a) {
    display: flex;
    padding: 8px 0;
    color: #2E383E;
  }

  body[data-pages-type='parking-page'] .header__main__nav .other-tools .user-block__box__wrap > *:not(a).profile-name {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-width: 100%;
    margin-left: auto;
    flex: 1 1 auto;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
  }

  body[data-pages-type='parking-page'] .header.menu-active .menu-burger::before {
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-50%, calc(-50% + 1px));
  }

  body[data-pages-type='parking-page'] .header.menu-active .menu-burger i {
    left: 70%;
    opacity: 0;
  }

  body[data-pages-type='parking-page'] .header.menu-active .menu-burger::after {
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translate(-50%, calc(50% - 1px));
  }

  body[data-pages-type='parking-page'] .header.menu-active .menu-burger::before,
  body[data-pages-type='parking-page'] .header.menu-active .menu-burger i,
  body[data-pages-type='parking-page'] .header.menu-active .menu-burger::after {
    width: 70% !important;
  }

  body[data-pages-type='parking-page'] .header.menu-active .header__main__nav {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  body[data-pages-type='parking-page'] .header.header__small {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    border-bottom: 1px solid #DBE1E5;
  }

  body[data-pages-type='parking-page'] .header.header__small .logo a svg,
  body[data-pages-type='parking-page'] .header.header__small .logo a img {
    width: 110px;
    height: calc(110px / 2.729);
    transition: all 0.2s linear;
  }

  body[data-pages-type='parking-page'] .header.header__small .header__main {
    height: 60px;
  }

  body[data-pages-type='parking-page'][data-page="main"] .header {
    background: none;
  }

  body[data-pages-type='parking-page'][data-page="main"] .header.header__small {
    background: rgba(255, 255, 255, 0.9);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 10;
    width: calc(100% + 2px);
    max-width: 4200px;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    background: none;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger {
    display: flex;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 3;
    transform: translateY(calc(-50% - 2px));
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger::before,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger i,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger::after {
    content: '';
    display: block;
    width: 60%;
    height: 2px;
    background-color: #59616D;
    position: absolute;
    transform-origin: 0 50%;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger::before {
    top: 30%;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger i {
    top: 50%;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger::after {
    top: 70%;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .resolution-wrap {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    max-width: none;
    transition: all 0.2s linear;
    padding-left: calc(8px*2);
    padding-right: calc(8px*2);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main {
    height: 60px;
    max-height: 60px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main .logo {
    transition: all 0.2s linear;
    position: relative;
    margin-right: calc(8px * 2);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main .logo a {
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main .logo a svg,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main .logo a img {
    width: 120px;
    height: calc(120px / 3.12);
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel {
    display: none;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-right: calc(8px * 2);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel a {
    text-decoration: none;
    color: #2E383E;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel .more-icon {
    margin-left: 4px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel .more-icon svg {
    width: 16px;
    height: 16px;
    fill: #9CA3AE;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks {
    display: flex;
    gap: 4px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks a {
    text-decoration: none;
    display: flex;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks a svg {
    width: 24px;
    height: 24px;
    fill: #9CA3AE;
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 420px;
    min-width: 300px;
    margin-left: auto;
    position: absolute;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
    backdrop-filter: blur(0);
    top: 0;
    right: 0;
    height: var(--app-height);
    transition: all 0.2s linear;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 2px 9px 23px -5px rgba(42, 64, 105, 0.1);
    padding-top: 60px;
    overflow: hidden;
    overflow-y: auto;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list__item,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list a {
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #2E383E;
    text-decoration: none;
    font: 400 16px/110% 'Roboto', Helvetica, Arial, sans-serif;
    display: flex;
    padding: calc(8px * 2);
    text-align: center;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.03);
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list__item::after,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list a::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    transition: all 0.2s linear;
    width: 6px;
    height: 100%;
    background: #159DF1;
    border-radius: 0 4px 4px 0;
    opacity: 0;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list__item.active::after,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .menu-list a.active::after {
    content: '';
    scale: 1;
    opacity: 1;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 calc(8px * 2);
    margin-bottom: calc(8px * 3);
    flex-wrap: wrap;
    margin-top: auto;
    order: 99;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools svg {
    display: none;
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language {
    order: 99;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language__box {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language__box__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 0 24px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language__box__wrap > * {
    flex: 0 0 auto;
    display: flex;
    padding: 8px 0;
    text-decoration: none;
    color: #2E383E;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language__box__wrap > *.lang-hint {
    color: #9CA3AE;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .language__box__wrap > *.active {
    font-weight: 700;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: #F0F2F6;
    border-radius: 8px;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block__box {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block__box__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 0 24px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block__box__wrap > *:not(a) {
    display: flex;
    padding: 8px 0;
    color: #2E383E;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main__nav .other-tools .user-block__box__wrap > *:not(a).profile-name {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-width: 100%;
    margin-left: auto;
    flex: 1 1 auto;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.menu-active .menu-burger::before {
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-50%, calc(-50% + 1px));
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.menu-active .menu-burger i {
    left: 70%;
    opacity: 0;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.menu-active .menu-burger::after {
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translate(-50%, calc(50% - 1px));
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.menu-active .menu-burger::before,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.menu-active .menu-burger i,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.menu-active .menu-burger::after {
    width: 70% !important;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.menu-active .header__main__nav {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    border-bottom: 1px solid #DBE1E5;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small .logo a svg,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small .logo a img {
    width: 110px;
    height: calc(110px / 2.729);
    transition: all 0.2s linear;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header.header__small .header__main {
    height: 60px;
  }

  body[data-page='main'] .dynamic-padding,
  body[data-page='examples-page'] .dynamic-padding,
  body[data-page='parking'] .dynamic-padding,
  body[data-page='dmv-services'] .dynamic-padding {
    padding-top: 0;
  }

  body:not([data-page=main]):not([data-page=examples-page]):not([data-page=parking]):not([data-page=dmv-services]) .dynamic-padding {
    padding-top: 60px;
  }

  body .landing-main-banner {
    padding-top: calc(60px + calc(8px * 4));
  }

  .map-wrapper {
    padding-top: 60px;
    padding-bottom: 48px;
  }

  .street-cleaning-block__open-close {
    top: 70px;
  }

  .street-cleaning-block__open-close.active {
    top: 74px;
  }

  .left-block {
    top: 70px;
  }

  .geolocation-btn {
    bottom: 186px;
  }

  .tabs__content.active {
    max-height: calc(var(--app-height) - 262px);
  }

  .banner-on-map {
    top: calc(60px + 10px);
  }
}

@media (min-width: 280px) and (max-width: 959.98px) and (hover: hover) and (pointer: fine) {
  body[data-pages-type='parking-page'] .header .menu-burger:hover {
    cursor: pointer;
  }

  body[data-pages-type='parking-page'] .header .menu-burger:hover::before,
  body[data-pages-type='parking-page'] .header .menu-burger:hover i,
  body[data-pages-type='parking-page'] .header .menu-burger:hover::after {
    background-color: #159DF1;
  }

  body[data-pages-type='parking-page'] .header__main-soc-networks a:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger:hover {
    cursor: pointer;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger:hover::before,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger:hover i,
  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header .menu-burger:hover::after {
    background-color: #159DF1;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks a:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
  }
}

@media (min-width: 280px) and (max-width: 959.98px) and (min-width: 280px) and (max-width: 420px) {
  body[data-pages-type='parking-page'] .header__main-tel {
    display: none !important;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-tel {
    display: none !important;
  }
}

@media (min-width: 280px) and (max-width: 959.98px) and (min-width: 280px) and (max-width: 520px) {
  body[data-pages-type='parking-page'] .header__main-soc-networks {
    display: none !important;
  }

  body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page']) .header__main-soc-networks {
    display: none !important;
  }
}

@media (hover: hover) and (pointer: fine) {
  .slick-slider:hover button.slick-arrow {
    opacity: 1;
  }

  .btn-slider-style:not(.slide-disabled):hover {
    background: #29b1ff;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
  }

  button.slick-arrow:not(.slide-disabled):hover {
    background: #29b1ff;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
  }

  button.slick-lightbox-close:not(.slide-disabled):hover {
    background: #29b1ff;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
  }

  .text-box a:hover,
  .text-wrap a:hover {
    color: #29b1ff;
  }

  .accordion__theme-one .accordion__item.active:hover {
    border-color: #29b1ff;
  }

  .accordion__theme-one .accordion__item.active:hover .accordion__item__title::after {
    opacity: 1;
  }

  .accordion__theme-one .accordion__item:not(.active):hover {
    border-color: #29b1ff;
  }

  .accordion__theme-one .accordion__item:not(.active):hover .accordion__item__title::after {
    opacity: 1;
  }

  .slider-comments-type .slide-prev-btn:hover,
  .slider-comments-type .slide-next-btn:hover {
    background: #29b1ff !important;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
  }

  .btn__theme-1:not([disabled]):hover {
    cursor: pointer !important;
    background: #29b1ff;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
    color: #fff;
  }

  .btn__theme-2.red-btn:not([disabled]):hover {
    border-color: #ec604e !important;
    color: #ec604e !important;
    box-shadow: 0 3px 26px -4px rgba(236, 96, 78, 0.3);
  }

  .btn__theme-2.red-btn:not([disabled]):hover svg {
    fill: #ec604e !important;
  }

  .btn__theme-2:not([disabled]):hover {
    cursor: pointer !important;
    border-color: #29b1ff !important;
    color: #29b1ff !important;
    box-shadow: 0 3px 26px -4px rgba(41, 177, 255, 0.3);
  }

  .btn__theme-2:not([disabled]):hover svg {
    fill: #29b1ff !important;
  }

  .btn__theme-3:not([disabled]):hover {
    cursor: pointer !important;
    color: #29b1ff !important;
    border-color: #29b1ff !important;
    box-shadow: 0 3px 26px -4px rgba(41, 177, 255, 0.3);
  }

  .btn__theme-3:not([disabled]):hover svg {
    fill: #29b1ff !important;
  }

  .btn__more:not([disabled]):hover {
    cursor: pointer;
    border-color: #29b1ff;
    background: #29b1ff;
  }

  .btn__more:not([disabled]):hover::after {
    color: white;
    margin-left: 13px;
  }

  #go-top:hover > * {
    background: #29b1ff;
    border-color: #29b1ff;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
  }

  .select-container option:hover {
    cursor: pointer;
  }

  .select-container option:hover::before {
    color: #29b1ff;
  }

  .input-number-wrapper .up:hover,
  .input-number-wrapper .down:hover {
    cursor: pointer;
  }

  .tab-radio__item input:not(:checked) ~ label:hover {
    cursor: pointer;
    background: #F0F2F6;
  }

  .field-help:hover svg {
    fill: #29b1ff;
  }

  .popup__container__window .popup__close:hover {
    background: #29b1ff;
    cursor: pointer;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
  }

  .desktop-menu-component__main-tel .more-icon:hover {
    cursor: pointer;
  }

  .desktop-menu-component__main-tel .more-icon:hover svg {
    transform: scale(1.2);
    fill: #29b1ff;
  }

  .desktop-menu-component__main-soc-networks a:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
  }

  .desktop-menu-component__main__nav .menu-list__item:not(.active):hover {
    color: #29b1ff;
  }

  .desktop-menu-component__main__nav .other-tools .language__button:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
    cursor: pointer;
  }

  .desktop-menu-component__main__nav .other-tools .user-block__button:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
    cursor: pointer;
  }

  .desktop-menu-component .dropdown-menu__box__wrap > *:not(.active):not(.profile-name):hover {
    color: #29b1ff;
    cursor: pointer;
  }

  .mobile-menu-component .menu-burger:hover {
    cursor: pointer;
  }

  .mobile-menu-component .menu-burger:hover::before,
  .mobile-menu-component .menu-burger:hover i,
  .mobile-menu-component .menu-burger:hover::after {
    background-color: #159DF1;
  }

  .mobile-menu-component__main-soc-networks a:hover svg {
    transform: scale(1.1);
    fill: #29b1ff;
  }

  .footer__content__more .download-app-btns__wrap a:hover img {
    transform: scale(1.2);
  }

  .footer__copyright__col-logos a:hover {
    opacity: 1;
  }

  .footer__copyright__col-menu a:hover {
    opacity: 1;
  }

  .footer-nav__column__links-list a:hover {
    opacity: 1;
  }

  .contacts-container__contact-info > * > * .info a.phone-number:hover svg {
    fill: #159DF1;
  }

  .copy-to-buffer:hover svg {
    fill: #159DF1;
  }

  .news-preview__item a:hover {
    color: #59616D;
  }

  .additional-blocks__item a:hover {
    background: #29b1ff;
  }

  .section-theme-8 .app-links a:hover {
    transform: scale(1.05);
  }

  .locations-list__item:hover {
    transform: scale(1.05);
    border-color: #159DF1;
    z-index: 2;
  }

  .sidebar-services:hover {
    transform: translateY(-1px) scale(1.05);
  }

  .sidebar-services:hover .sidebar-services__icon {
    background: #29b1ff;
  }

  .sidebar-services:hover .sidebar-services__icon svg {
    fill: #fff;
  }

  .partnership__item.right-side a:hover {
    color: #29b1ff;
  }

  .help-icon-btn:hover svg,
  .copy-icon-btn:hover svg {
    fill: #29b1ff;
  }

  .link-with-icon:hover svg {
    fill: #29b1ff;
  }

  .section-contact .contact-section__info .contacts-block a:hover {
    color: #29b1ff;
  }

  .section-profile-form__content__form a:hover {
    color: #29b1ff;
  }

  .header-search .form-theme .input-item button:hover svg {
    fill: #159DF1;
  }

  .header-search .neighborhoods_find_list ul li a:hover,
  .header-search .addresses_find_list ul li a:hover {
    color: #fff;
    background: #29b1ff;
  }

  .geolocation-btn:hover {
    background: #29b1ff;
    cursor: pointer;
    box-shadow: 1px 4px 13px -4px rgba(21, 157, 241, 0.8);
  }

  .tabs:not(.inactive-tabs) .tabs__caption__item:not(.active):hover {
    cursor: pointer;
  }

  .tabs:not(.inactive-tabs) .tabs__caption__item:not(.active):hover span {
    color: #9CA3AE;
  }

  .tabs:not(.inactive-tabs) .tabs__caption__item:not(.active):hover svg {
    fill: #9CA3AE;
  }

  .tabs.inactive-tabs .tabs__caption__item:not(.active):hover {
    cursor: pointer;
  }

  .tabs.inactive-tabs .tabs__caption__item:not(.active):hover span {
    color: #9CA3AE;
  }

  .tabs.inactive-tabs .tabs__caption__item:not(.active):hover svg {
    fill: #9CA3AE;
  }

  .steps-group__item__content .help-btn:hover {
    cursor: pointer;
  }

  .steps-group__item__content .help-btn:hover svg {
    fill: #29b1ff;
  }

  .steps-group__item__content .view-schedule-btn:hover {
    cursor: pointer;
  }

  .steps-group__item__content .view-schedule-btn:hover span {
    color: #29b1ff;
  }

  .steps-group__item__content .view-schedule-btn:hover svg {
    fill: #29b1ff;
  }

  .banner-on-map__close-btn:hover {
    background: #159DF1;
    cursor: pointer;
  }

  .banner-on-map a:hover {
    border: 1px solid #159DF1;
  }

  .banner-on-map a:hover img {
    transform: scale(1.05);
  }
}